import Vue from "vue";

import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import ApiService from "../services/Api";
import Auth from "../services/Auth";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    strictMode: true,
    key: "vuex",
    storage: window.localStorage
});

// global var
// Vue.prototype.$api_url = 'http://internal-projects.php-dev.in/digital-proposal/core/public/'
Vue.prototype.$api_url = 'https://be.proposal.neosofttech.in/'
// Vue.prototype.$api_url = 'http://localhost/digital-proposal/core/public/'

export default new Vuex.Store({
    strict: true,
    state: {
        // API_URL : 'http://internal-projects.php-dev.in/digital-proposal/core/public/',
        checkusercompany: "",
        checkuserchoise: 0,
        usercompanylist: {},
        user_firstname: "",
        proposalsShared: "",
        unassignedProposals: "",
        template_count: "",
        draftproposals: [],
        user_lastname: "",
        outstandingProposals: [],
        acceptedProposals: [],
        data: "",
        user_emailaddress: "",
        user_phoneno: "",
        acceptedProposalsCount: "",
        signedProposals: "",
        proposalsSent: "",
        signedProposalsCount: "",
        rejectedProposals: "",
        conversionRate: "",
        proposalsActivityBoard: [],
        companyActivityBoard: [],
        onclickProposalData:[],
        proposalId:0,
        proposalCompanyNmae: "",
        proposalCompanyCreatedAt: "",
        proposalIsMail: 0,
        proposalEmailOpen:0,
        proposalMailAt:"",
        proposal_id: "",
        company_name: "",
        company_id: "",
        proposalIsAcceptedAt:"",
        proposalIsAccepted: 0,
        proposalIsRejected:0,
        proposalIsRejectedDate:"",
        template_id: 0,
        company_report: [],
        template_data:[],
        template_constant_page:[],
        proposalWithUserDetails: [],
        from_date: "",
        to_date: "",
        save_template:"",
        quarterly_recurring_revenue: [],
        annual_recurring_revenue: [],
        todaysDate: "",
        totalProposalOfUser: 0,
        monthly_recurring_revenue: [],
        individual_performance_revenue:[],
        step2_is_working: 0,
        cover_id: 0,
        step3_is_working: 0,
        department: '',
        proposalbasicdetail:[],
        updating_basic_detail : 0,
        update_proposal_id:0,
        cover_detail:[],
        lastProposalSent:[],
        proposal_receipent:[],
        proposalProgressLabel:'',
        template_pages:[],
        months:[],
        years:[],
        current_month:"",
        quaterly_recurring_revenue:''
    },

    getters: {
        getisusercompany: state => state.checkusercompany,
        getusername: state => state.user_firstname + state.user_lastname,
        getuserfirstname: state => state.user_firstname
    },
    mutations: {
        updatecheckusercomp: (state, value) => {
            // console.log(value);
            state.checkusercompany = value;
        },
        setcompanylist: (state, value) => {
            // ApiService.post('getDashboardData')
            state.usercompanylist = value;
        },
        setuserchoise: (state, value) => {
            state.checkuserchoise = value;
        },
        setpage3data: (state, value) => {
            state.proposal_id = value.proposal.proposal_id;
            state.company_name = value.company.company_name;
            state.company_id = value.company.company_id;
            state.step2_is_working = 1;
        },
        setcompanyid:(state,value)=>{
            state.company_id = value;
        },
        setpage4data: (state, value) => {
            state.cover_id = value.cover.id;
            state.step3_is_working = 1;
        },
        // For Display Data On Displaydashboard
        displaydashboarddata: (state, value) => {
            state.template_pages = value.templates;
            state.user_firstname = value.user[0].user_firstname;
            state.department = value.user[0].department.department_name;
            state.user_lastname = value.user[0].user_lastname;
            state.user_emailaddress = value.user[0].user_emailaddress;
            state.user_phoneno = value.user[0].user_phoneno;
            state.proposalsShared = value.proposalsShared;
            state.unassignedProposals = value.unassignedProposals;
            state.template_count = value.template_count;

            state.draftproposals = value.draftproposals;
            state.outstandingProposals = value.outstandingProposals;
            state.acceptedProposals = value.acceptedProposals;
            state.rejectedProposals = value.rejectedProposals;


            state.acceptedProposalsCount = value.acceptedProposalsCount;
            state.signedProposals = value.signedProposals;
            state.proposalsSent = value.proposalsSent;
            state.signedProposalsCount = value.signedProposalsCount;


            state.conversionRate = value.conversionRate;
            state.proposalsActivityBoard = value.proposalsActivityBoard;
            state.companyActivityBoard = value.companyActivityBoard;
            state.todaysDate = value.todaysDate;
            state.totalProposalOfUser = value.totalProposalOfUser;
            state.lastProposalSent = value.last_proposal_sent;
        },
        displayDashboardRejectedProposal: (state, value) => {
            state.rejectedProposals = value.rejectedProposals;
        },
        setproposalid: (state, value) => {
            state.proposal_id = value;
            state.step2_is_working = 0;
            state.step3_is_working = 0;
            state.updating_basic_detail = 0;
        },
        displayDashboardDraftProposal: (state, value) => {
            state.draftproposals = value.draftproposals;
        },
        onPressOfBack:(state)=>{
            state.step3_is_working = 1;
            state.step2_is_working = 1;
        },
        displayDashboardOutstandingProposal:(state, value) => {
            state.outstandingProposals = value.outstandingProposals;
        },
        displayDashboardAcceptedProposal:(state, value) => {
            state.acceptedProposals = value.acceptedProposals;
        },
        toStartNewProposal:(state)=>{
            state.step2_is_working = 0;
            state.step3_is_working = 0;
            state.updating_basic_detail =0;
        },
        // For Proposal Details Page
        onClickProposal: (state, value) => {
            state.onclickProposalData = value.Proposals_data[0];
            state.proposalId =value.Proposals_data[0].proposal_id;
            state.proposalCompanyNmae =value.Proposals_data[0].proposal_with_company.company_name;
            state.proposal_receipent = value.proposal_receipent;
            state.proposalIsMail = value.Proposals_data[0].is_mail;
            state.proposalIsAccepted = value.Proposals_data[0].is_accepted;
        },
        settemplate: (state, value) => {
            state.template_id = value.template.id;
        },
        settemplateid: (state, value) => {
            state.template_id = value;
        },
        companyReport: (state, value) => {
            state.from_date = value.from_date;
            state.to_date = value.to_date;
            state.months = value.months;
            state.years = value.years;
            state.current_month= value.current_month;
            state.company_report = value.company_report;
            // console.log(state.company_report);
            state.quarterly_recurring_revenue = value.quarterly_recurring_revenue;
            state.annual_recurring_revenue = value.annual_recurring_revenue;
            state.monthly_recurring_revenue = value.monthly_recurring_revenue;
            state.individual_performance_revenue = value.individual_performance_revenue;
        },
        displayReportSaleOff: (state,value) => {
            state.from_date = value.from_date;
            state.to_date = value.to_date;
            state.company_report = value.company_report;
        },
        displayReportMonthlyRev: (state,value) => {
            state.from_date = value.from_date;
            state.to_date = value.to_date;
            state.monthly_recurring_revenue = value.monthly_recurring_revenue;
        },
        displayReportAnnualRev: (state,value) => {
            state.from_date = value.from_date;
            state.to_date = value.to_date;
            state.annual_recurring_revenue = value.annual_recurring_revenue;
        },
        displayReportQuaterlyRev: (state,value) => {
            state.from_date = value.from_date;
            state.to_date = value.to_date;
            state.quarterly_recurring_revenue = value.quarterly_recurring_revenue;
        },
        displayReportIndividualRev: (state,value) => {
            state.from_date = value.from_date;
            state.to_date = value.to_date;
            state.individual_performance_revenue = value.individual_performance_revenue;
        },
        proposalWithUserDetails: (state, value) => {
            state.proposalWithUserDetails = value;
        },
        proposalbasicdetail:(state,value)=>{
            state.proposalbasicdetail = value.proposal_data;
            state.update_proposal_id = value.proposal_data[0].proposal_id;
            state.proposal_id = value.proposal_data[0].proposal_id;
            state.updating_basic_detail = 1;
        },
        proposalcoverdetail:(state,value)=>{
            state.cover_detail = value.cover_data;
            if(value.template.length == 0){
                state.template_id = 0;
            }else{
                state.template_id = value.template[0].id;
            }
            state.update_proposal_id = value.proposal_id;
            state.updating_basic_detail = 1;
        },
        gettemplate:(state,value)=>{
            state.template_count = value.template_data.length;
        },
        proposalacceptedstatus:(state,value)=>{
            state.proposalIsAccepted =  value.Proposals_data[0].is_accepted;
            state.proposalEmailOpen =  value.Proposals_data[0].is_open;
            state.proposalMailAt = value.Proposals_data[0].mail_date;
            state.proposalIsMail =  value.Proposals_data[0].is_mail;
            state.proposalIsAcceptedAt = value.Proposals_data[0].accepted_date;
            state.proposalIsRejected = value.Proposals_data[0].is_rejected;
            state.proposalIsRejectedDate = value.Proposals_data[0].rejected_date;
            // state.proposalProgressLabel = "Accepted";
        },
        proposalmarkloststatus:(state,value)=>{
            state.proposalIsAccepted =  value.Proposals_data[0].is_accepted;
            state.proposalEmailOpen =  value.Proposals_data[0].is_open;
            state.proposalIsMail =  value.Proposals_data[0].is_mail;
            state.proposalIsRejected = value.Proposals_data[0].is_rejected;
            state.proposalIsRejectedDate = value.Proposals_data[0].rejected_date;
            // state.proposalProgressLabel = "Rejected";
        },
        settemplatelist: (state, value) => {
            // ApiService.post('getDashboardData')
            state.template_data = value.template_data;
            state.template_constant_page = value;
        },
        savetemplate: (state,value)=>{
            state.save_template = value.save_template;
        },

        RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION // this mutation *MUST* be named "RESTORE_MUTATION"
    },
    actions: {
        login: (context, logUserIn) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("login", logUserIn, 0).then(function(data) {
                    if (
                        typeof data.data.status != "undefined" &&
                        data.data.status == true
                    ) {
                        Auth.saveToken(data.data.token);
                        resolve(data.data);
                    } else {
                        Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        logout: context => {
            return new Promise(function(resolve, reject) {
                Auth.removeToken();
                resolve(1);
            });
        },
        check_company: context => {
            return new Promise(function(resolve, reject) {
                ApiService.get("check_user_company").then(function(data) {
                    if (data.data.status == true) {
                        if (data.data.company.length > 0) {
                            context.commit("updatecheckusercomp", 1);
                        } else {
                            context.commit("updatecheckusercomp", 0);
                        }
                        resolve(data.data);
                    }
                });
            });
        },
        get_company: context => {
            return new Promise(function(resolve, reject) {
                ApiService.get("check_user_company").then(function(data) {
                    if (data.data.status == true) {
                        context.commit("setcompanylist", data.data.company);
                        if (data.data.company.length > 0) {
                            context.commit("updatecheckusercomp", 1);
                        } else {
                            context.commit("updatecheckusercomp", 0);
                        }
                        resolve(data.data);
                    }
                });
            });
        },
        store_company: (context, Company) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("addcompany", Company, 0).then(function(data) {
                    if (data.data.status == true) {
                        let Data = {
                            proposal: data.data.proposal,
                            company: data.data.company
                        };
                        //  console.log(Data);
                        context.commit("setpage3data", Data);
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        store_coverpage: (context, Cover) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("addcover", Cover, 0).then(function(data) {
                    if (data.data.status == true) {
                        let Data = {
                            cover: data.data.cover
                        };
                        context.commit("setpage4data", Data);
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        store_template: (context, Cover) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("addtemplate", Cover, 0).then(function(data) {
                    // console.log(data.data);
                    if (data.data.status == true) {
                        context.commit("settemplate", data.data);
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        getpages: (context, Page) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("get_pages", Page, 0).then(function(data) {
                    // console.log(data);
                    if (data.data.status == true) {
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        store_page: (context, Page) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("addpage", Page, 0).then(function(data) {
                    if (data.data.status == true) {
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        store_const_page: (context, Page) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("addconstpage", Page, 0).then(function(data) {
                    if (data.data.status == true) {
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        update_page: (context, Page) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("updatepagename", Page, 0).then(function(data) {
                    if (data.data.status == true) {
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        update_const_page: (context, Page) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("updateconstpagename", Page, 0).then(function(data) {
                    if (data.data.status == true) {
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        delete_page: (context, Page) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("deletepage", Page, 0).then(function(data) {
                    // console.log(data);
                    if (data.data.status == true) {
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        delete_const_page: (context, Page) => {
            return new Promise(function(resolve, reject) {
                ApiService.post("deleteconstpage", Page, 0).then(function(data) {
                    // console.log(data);
                    if (data.data.status == true) {
                        resolve(data.data);
                    } else {
                        //  Auth.removeToken();
                        resolve({});
                    }
                });
            });
        },
        //  For Displaying Data on Dashbord
        get_dashboard_data(context) {
            return new Promise(function(resolve, reject) {
                ApiService.post("getDashboardData").then(responce => {
                    // console.log(responce);
                    if (responce.data.status) {
                        context.commit("displaydashboarddata", responce.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(responce.data);
                    }
                });
            });
        },
        get_rejected_proposal(context,page) {
            return new Promise(function(resolve, reject) {
                ApiService.get(`get_rejected_proposal?page=`+page).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayDashboardRejectedProposal", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_draft_proposal(context,page) {
            return new Promise(function(resolve, reject) {
                ApiService.get(`get_draft_proposal?page=`+page).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayDashboardDraftProposal", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_outstanding_proposal(context,page) {
            return new Promise(function(resolve, reject) {
                ApiService.get(`get_outstanding_proposal?page=`+page).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayDashboardOutstandingProposal", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_accepted_proposal(context,page) {
            return new Promise(function(resolve, reject) {
                ApiService.get(`get_accepted_proposal?page=`+page).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayDashboardAcceptedProposal", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        to_start_new_prposal(context){
            return new Promise(function(resolve, reject) {
                 context.commit("toStartNewProposal");
                 var data = [];
                data["status"] = "success";
                resolve(data);
            });
        },
        // on Proposal Page To view Proposal Details
        click_on_proposal(context, id) {
            return new Promise(function(resolve, reject) {
                ApiService.post("clickOnProposal/" + id).then(response => {
                    if (response.data.status) {
                        context.commit("onClickProposal", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        generateReport(context, generateReportDetails) {
            return new Promise(function(resolve, reject) {
                ApiService.post("generateReport", {
                    from_date: generateReportDetails.from_date,
                    to_date: generateReportDetails.to_date,
                    select_user: generateReportDetails.select_user
                }).then(response => {
                    if (response.data.status) {
                        // console.log(response.data);
                        context.commit("companyReport", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_sale_off(context,generateReportDetails) {
            // console.log(generateReportDetails);
            return new Promise(function(resolve, reject) {
                // ApiService.get(`get_sale_off?page=`+page).then(response => {
                    ApiService.get(`get_sale_off?page=`+generateReportDetails.page+`&from_date=`+generateReportDetails.from_date+`&to_date=`+generateReportDetails.to_date, {

                    }).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayReportSaleOff", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_monthly_rev(context,generateReportDetails) {
            // console.log(generateReportDetails);
            return new Promise(function(resolve, reject) {
                // ApiService.get(`get_sale_off?page=`+page).then(response => {
                    ApiService.get(`get_monthly_rev?page=`+generateReportDetails.page+`&selected=`+generateReportDetails.selected+`&from_date=`+generateReportDetails.from_date+`&to_date=`+generateReportDetails.to_date, {

                    }).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayReportMonthlyRev", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_quaterly_rev(context,generateReportDetails) {
            // console.log(generateReportDetails);
            return new Promise(function(resolve, reject) {
                // ApiService.get(`get_sale_off?page=`+page).then(response => {
                    ApiService.get(`get_quaterly_rev?page=`+generateReportDetails.page+`&selected=`+generateReportDetails.selected+`&from_date=`+generateReportDetails.from_date+`&to_date=`+generateReportDetails.to_date, {

                    }).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayReportQuaterlyRev", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_annual_rev(context,generateReportDetails) {
            // console.log(generateReportDetails);
            return new Promise(function(resolve, reject) {
                // ApiService.get(`get_sale_off?page=`+page).then(response => {
                    ApiService.get(`get_annual_rev?page=`+generateReportDetails.page+`&selected=`+generateReportDetails.selected+`&from_date=`+generateReportDetails.from_date+`&to_date=`+generateReportDetails.to_date, {

                    }).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayReportAnnualRev", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        get_individual_rev(context,generateReportDetails) {
            // console.log(generateReportDetails);
            return new Promise(function(resolve, reject) {
                // ApiService.get(`get_sale_off?page=`+page).then(response => {
                    ApiService.get(`get_individual_rev?page=`+generateReportDetails.page+`&selected=`+generateReportDetails.selected+`&from_date=`+generateReportDetails.from_date+`&to_date=`+generateReportDetails.to_date, {

                    }).then(response => {
                    // console.log(response.data);
                    if (response.data.status) {
                        context.commit("displayReportIndividualRev", response.data);
                        var data = [];
                        data["status"] = "success";
                        resolve(data);
                    }
                });
            });
        },
        getuserlist(context) {
            ApiService.get("getProposalUserList").then(response => {
                context.commit(
                    "proposalWithUserDetails",
                    response.data.proposalWithUserDetails
                );
            });
        },
        edit_proposal_basic_detail(context,id){
            return new Promise(function(resolve, reject) {
            ApiService.get("getproposalbasicdetail/" +id)
            .then(response=>{
                if(response.data.status){
                context.commit("proposalbasicdetail",response.data);
                var data = [];
                data["status"] = "success";
                resolve(data);
                }
            })
        });
        },
        manually_approve(context,id){
            return new Promise(function(resolve, reject) {
            ApiService.get("manually_approve/" +id)
            .then(response=>{
                if(response.data.status){
                // context.commit("proposalacceptedstatus",response.data);
                var data = [];
                data["status"] = "success";
                var response =response.data;
                resolve(response);
                }
            })
        });
        },
        mark_lost(context,id){
            return new Promise(function(resolve, reject) {
            ApiService.get("mark_lost/" +id)
            .then(response=>{
                if(response.data.status){
                // context.commit("proposalmarkloststatus",response.data);
                var data = [];
                data["status"] = "success";
                var response =response.data;
                resolve(response);
                }
            })
        });
        },
        delete_proposal(context,id){
            return new Promise(function(resolve, reject) {
            ApiService.get("delete_proposal/" +id)
            .then(response=>{
                if(response.data.status){
                // context.commit("proposalbasicdetail",response.data);
                var data = [];
                data["status"] = "success";
                resolve(data);
                }
            })
        });
        },
        delete_const_template(context,id){
            return new Promise(function(resolve, reject) {
            ApiService.get("delete_const_template/" +id)
            .then(response=>{
                if(response.data.status){
                resolve(response);
                }
            })
        });
        },
        save_template(context,id){
            return new Promise(function(resolve, reject) {
            ApiService.get("save_template/" +id)
            .then(response=>{
                if(response.data.status){
                context.commit("settemplatelist",response.data);
                var data = [];
                data["status"] = "success";
                resolve(data);
                }
            })
        });
        },
        generate_version(context,id){
            return new Promise(function(resolve, reject) {
            ApiService.get("generate_version/" +id)
            .then(response=>{
                if(response.data.status){
                // context.commit("settemplatelist",response.data);
                var data = [];
                data["status"] = "success";
                resolve(data);
                }
            })
        });
        },
        get_template: context => {
            return new Promise(function(resolve, reject) {
                ApiService.get("get_template").then(function(response) {
                    if (response.data.status == true) {
                        console.log(response.data);
                        context.commit("gettemplate", response.data);
                        // context.commit("settemplatelist", response.data);
                        var data = [];
                        data["status"] = "success";
                        data["data"] = response.data;
                        resolve(data);
                    }
                });
            });
        },
        get_data_of_cover(context,id){
            return new Promise(function(resolve, reject) {
                ApiService.get("getdataofcover/" + id)
                .then(response=>{
                    if(response.data.status){
                    context.commit("proposalcoverdetail",response.data);
                    var data = [];
                    data["status"] = "success";
                    resolve(data);
                    }
                })
            });
        },
    },

    modules: {},
    plugins: [vuexLocal.plugin]
});
