<template>
    <draggable :list="rearrange_pages" class="list-unstyled mb-0 rearrange-list" :group="{ name: 'g1' }" :move="checkMove" @start="drag=true" @end="getend">
        <li v-for="(page,index) in rearrange_pages">
            <a class="d-flex align-items-center" href="javascript:void(0)" ><i class="fa fa-bars" aria-hidden="true"></i> {{ page.name }}   </a>
            <!-- <nested-draggable style="margin: 0 0 0 1rem;" v-if="page.children" :tasks="page.children" @click="editor_data(page.id)" /> -->
            <nested-draggable class="children_list" :rearrange_pages="page.children" />
        </li>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  props: {
    rearrange_pages: {
    //   required: true,
      type: Array
    }
  },
  methods: {
    checkMove(evt){
        if(evt.relatedContext.list == null){
            // console.log('list is null');
            return false;
        } else if(evt.draggedContext.element.children.length > 0 && !evt.relatedContext.element){
                return false;
        }else if(evt.draggedContext.element.children.length > 0 && evt.relatedContext.element ){
            let relative_el_name = evt.relatedContext.element.name;
            let matched = false;
            this.rearrange_pages.forEach(function(data){
                // console.log(data.name,'ddddd',relative_el_name);
                if(data.name == relative_el_name){
                    // console.log('inside check');
                    matched = true;
                    return false;
                }
            });
            if(matched){
                // console.log('matche');
                return true;
            }
            // console.log('parent list inside other element');
            return false;
        }
      },
      getend(evt){
            // console.log(evt);
      },
  },
  components: {
    draggable
  },
  name: "nested-draggable"
};
</script>
<style scoped>
.children_list {
  padding-left: 50px;
}
</style>