<template>
    <main>
        <sidebar></sidebar>
<div class="dashboard">
				<div class="dashboard_top mb-4">
					<h1 class="dash-title">Your <span>Templates</span></h1>
					<a href="javscript:void(0)" @click="add_proposal()" class="add-proposal">+</a>
				</div>
				<div class="row">
					<div class="dashboard-left col-md-8">
						<div class="row">
							<div class="col-12">
								<div class="dash-inner">
									<div class="proposals-top d-flex align-items-center">
										<div class="proposals-top__left">
											<h2>Templates</h2>
											<p>Total {{template_count}} Templates in library.</p>
										</div>
										<div class="proposals-action">
											<div class="proposals-search position-static">
												<div class="input-group mb-0">
													<input type="text" class="form-control" placeholder="Search here" v-model="search_box" style="border-radius: 20px;">
												</div>
											</div>
										</div>
									</div>
									<div class="tab-content" id="pills-tabContent">
										<div class="tab-pane fade show active" id="pills-draft" role="tabpanel" aria-labelledby="pills-draft-tab">
											<div class="proposal-table">
												<div class="table-responsive">
													<table class="table table-striped">
														<thead>
															<tr>
																<th>Company name</th>
																<th class="text-left">Action</th>
															</tr>
														</thead>
														<tbody>
                                                            <tr>
															    <td colspan="3" class="text-center" v-if="!templates.length">No Data Found</td>
														    </tr>
															 <tr v-for="item in templates"  :key= "item.id">
																<td>
																	{{item.template_name}}
																</td>
																<td class="text-left">
																	<ul class="proposal-table__action-list list-unstyled mb-0">
																		<li>
																			<a :href="api_url+'Show-pages/'+item.id" class="link" title="" target="_blank">
																				<img src="@/assets/images/icons/preview-2.svg" alt="">
																				Preview
																			</a>
																		</li>
																		<li v-if="item.is_constant != 1">
																			<a href="javascript:void(0);" @click="edit_template(item.id)" class="link" title="">
																				<img src="@/assets/images/icons/writing.svg" alt="">
																				Edit
																			</a>
																		</li>
																		<li  v-if="item.is_constant != 1 && item.children.length<=0">
																			<a href="javascript:void(0);" @click="delete_template(item.id)" class="link" title="">
																				<img src="@/assets/images/icons/delete-package.svg" alt="">
																				Delete
																			</a>
																		</li>
																	</ul>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="inner-page__content-box h-auto pb-0">
							<h2 class="main-subtitle mb-2">Created Templates</h2>
							<h2 >{{template_count}}</h2>
							<div class="text-right">
								<img src="@/assets/images/created-templates.svg" class="img-fluid">
							</div>
						</div>
					</div>
				</div>
			</div>
        <loading :active.sync="isLoading"></loading>
</main>
</template>

<script>
	import Sidebar from '@/components/Sidebar.vue';
	import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: "Template",
    data() {
        return {
			isLoading: false,
			template_data: [],
			constant_template: [],
            template_count:0,
            search_box:'',
			api_url:this.$api_url,
        };
    },
    computed:{
            templates(){
                return this.template_data.filter((template) => {
                        return (
                                template.template_name.toLowerCase().includes(this.search_box.toLowerCase())
                        );
                });
                },
		},
    methods: {
		init(){
				this.isLoading = true;
				this.$store.dispatch('get_template')
				.then(response=>{
					if (response.status) {
						this.isLoading = false;
                        this.template_data = response.data.template_data;
                        this.template_count = parseInt(this.template_data.length);
						}
				})

		},
        edit_template(id){
            this.$store.commit('settemplateid',id);
            this.$router.push({ path: '/edit-template' });
        },
        add_proposal(){
            this.$store.commit('toStartNewProposal');
            this.$router.push({ path: '/proposal-step-1' });
        },
        delete_template(id){
            this.$swal({
                        icon: 'warning',
                        text: 'Are you sure you want Delete?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                        if(result.value) {
                            	this.isLoading = true;
							this.$store.dispatch('delete_const_template',id)
							.then(response=>{
								if (response.status) {
                                        this.template_data = response.data.template_data;
                                        this.$noty.success("Success : Template Deleted");
                                        this.isLoading = false;
									}
							})
                        }
                })
        }

    },

    mounted() {
          this.init();
    },
    components: {
        	Sidebar,
			Loading,
    }
};
</script>
