<template>
	<main>
		<sidebar></sidebar>
		<!-- proposal -->
		<div class="dashboard">
			<div class="dashboard_top mb-4">
				<h1 class="dash-title">Your <span>Proposals</span></h1>
				<a href="javascript:void(0)" @click="add_proposal" class="add-proposal">+</a>
			</div>
			<div class="row">
				<div class="dashboard-left col-md-8">
					<div class="row">
						<div class="col-12">
							<div class="dash-inner">
								<div class="proposals-top d-flex align-items-center">
									<div class="proposals-top__left">
										<h2>Proposals</h2>
										<p>You have overall created {{ totalProposalOfUser }} proposals so far</p>
									</div>
									<div class="proposals-action">
										<div class="proposals-action__tabs">
											<ul class="nav nav-pills" id="pills-tab" role="tablist">
												<li class="nav-item">
													<a @click="draft()" class="nav-link active" id="pills-draft-tab" data-toggle="pill" href="#pills-draft" role="tab" aria-selected="true">Draft</a>
												</li>
												<li class="nav-item">
													<a @click="outstanding()" class="nav-link" id="pills-outstanding-tab" data-toggle="pill" href="#pills-outstanding" role="tab" aria-selected="false">Outstanding</a>
												</li>
												<li class="nav-item">
													<a @click="accepted()" class="nav-link" id="pills-accepted-tab" data-toggle="pill" href="#pills-accepted" role="tab" aria-selected="false">Accepted</a>
												</li>
												<li class="nav-item">
													<a @click="rejected()" class="nav-link" id="pills-lost-tab" data-toggle="pill" href="#pills-lost" role="tab" aria-selected="false">Rejected</a>
												</li>
											</ul>
										</div>
										<div class="proposals-search">
											<div class="input-group mb-0">
												<input type="text" v-model="search_box" class="form-control" placeholder="Search here">
												<div class="input-group-append">
													<a href="javascript:void(0);" @click="closeSearch" class="close-search"><img src="@/assets/images/icons/cross.svg" class="img-fluid"></a>
												</div>
											</div>
										</div>
									</div>
									<div @click="openSearch" class="search-btn d-block ml-1">
										<a href="javascript:void(0);"><img src="@/assets/images/icons/search.svg" class="img-fluid"></a>
									</div>
								</div>
								<div class="tab-content" id="pills-tabContent">
									<div class="tab-pane fade show active" id="pills-draft" role="tabpanel" aria-labelledby="pills-draft-tab">
										<div class="proposal-table">
											<div class="table-responsive">
												<table class="table table-striped">
													<thead>
														<tr>
															<th>Company name</th>
															<th>Created Date</th>
															<th>Value</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td colspan="3" class="text-center" v-if=" !drafts.length"> No Data Found</td>
														</tr>
														<tr v-for="draft in drafts" :key="draft.proposal_id">
																<td>
																	<a class="d-flex" @click="click_on_proposal(draft.proposal_id)" href="javascript:void(0)">{{draft.proposal_with_company.company_name}}<span v-if="draft.original_id !=null"> ( Version No {{ draft.version_key }}) </span>
                                                                	<span v-if="draft.children.length >0 && draft.original_id ==null"> ( Version No 1) </span></a>
																</td>
																<td>{{draft.created_at | formatDate}}</td>
																<td>{{draft.prop_value}} {{draft.currency}} </td>
														</tr>

													</tbody>
												</table>
											</div>
											  <div class="proposal-table__pagination" id="DraftProposal" v-show="paginationDraft">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getDraftProposal(draftCurrentPage-1)"
															v-bind:style= "[totalDraftRecords < 11 || draftCurrentPage == 1 ? { 'pointer-events': 'none' } : null ]" >Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in draftRecordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === draftCurrentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getDraftProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getDraftProposal(draftCurrentPage+1)"
															 v-bind:style= "[ totalDraftRecords<11 || draftproposals_lastPage == draftCurrentPage ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
										</div>
									</div>
									<div class="tab-pane fade" id="pills-outstanding" role="tabpanel" aria-labelledby="pills-outstanding-tab">
										<div class="proposal-table">
											<div class="table-responsive">
												<table class="table table-striped">
													<thead>
														<tr>
															<th>Company name</th>
															<th>Created Date</th>
															<th>Value</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td colspan="3" class="text-center" v-if=" !outstandings.length"> No Data Found</td>
														</tr>
														<tr v-for="outstanding in outstandings" :key="outstanding.proposal_id">
															<td >
																<a @click="click_on_proposal(outstanding.proposal_id)" href="javascript:void(0)">{{ outstanding.proposal_with_company.company_name }}
																	<span v-if="outstanding.original_id !=null"> ( Version No {{ outstanding.version_key }}) </span>
                                                                	<span v-if="outstanding.children.length >0 && outstanding.original_id ==null"> ( Version No 1) </span>
																</a>
															</td>
															<td>{{ outstanding.created_at | formatDate }}</td>
															<td>{{ outstanding.prop_value}} {{outstanding.currency}}</td>
														</tr>
													</tbody>
												</table>
											</div>
											 <div class="proposal-table__pagination" id="OutstandingProposal" v-show="paginationOutstanding">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getOutstandingProposal(outstandingCurrentPage-1)"
															v-bind:style= "[totalOutstandingRecords < 11 || outstandingCurrentPage == 1 ? { 'pointer-events': 'none' } : null ]">Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in outstandingRecordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === outstandingCurrentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getOutstandingProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getOutstandingProposal(outstandingCurrentPage+1)"
															 v-bind:style= "[ totalOutstandingRecords<11 || outstandingproposals_lastPage == outstandingCurrentPage ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
										</div>
									</div>
									<div class="tab-pane fade" id="pills-accepted" role="tabpanel" aria-labelledby="pills-accepted-tab">
										<div class="proposal-table">
											<div class="table-responsive">
												<table class="table table-striped">
													<thead>
														<tr>
															<th>Company name</th>
															<th>Created Date</th>
															<th>Value</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td colspan="3" class="text-center" v-if="acceptedProposals.length == 0 || !accepteds.length"> No Data Found</td>
														</tr>
														<tr v-for="accepted in accepteds" :key="accepted.proposal_id">
															<td >
																<a @click="click_on_proposal(accepted.proposal_id)" href="javascript:void(0)"> {{accepted.proposal_with_company.company_name}}
																	<span v-if="accepted.original_id !=null"> ( Version No {{ accepted.version_key }}) </span>
                                                                	<span v-if="accepted.children.length >0 && accepted.original_id ==null"> ( Version No 1) </span>
																</a>
															</td>
															<td>{{accepted.created_at | formatDate}}</td>
															<td>{{accepted.prop_value}} {{accepted.currency}}</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="proposal-table__pagination" id="AcceptedProposal" v-show="paginationAccepted">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getAcceptedProposal(acceptedCurrentPage-1)"
															v-bind:style= "[totalAcceptedRecords < 11 || acceptedCurrentPage == 1 ? { 'pointer-events': 'none' } : null ]">Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in acceptedRecordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === acceptedCurrentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getAcceptedProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getAcceptedProposal(acceptedCurrentPage+1)"
																 v-bind:style= "[ totalAcceptedRecords<11 || acceptedProposals_lastPage == acceptedCurrentPage  ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
										</div>
									</div>
									<div class="tab-pane fade" id="pills-lost" role="tabpanel" aria-labelledby="pills-lost-tab">
										<div class="proposal-table">
											<div class="table-responsive">
												<table class="table table-striped">
													<thead>
														<tr>
															<th>Company name</th>
															<th>Created Date</th>
															<th>Value</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td colspan="3" class="text-center" v-if="rejectedProposals.length == 0  || !rejecteds.length"> No Data Found</td>
														</tr>
														<tr v-for="rejected in rejecteds" :key="rejected.proposal_id">
															<td >
																<a class="d-flex" @click="click_on_proposal(rejected.proposal_id)" href="javascript:void(0)">{{rejected.proposal_with_company.company_name}}
																	<span v-if="rejected.original_id !=null"> ( Version No {{ rejected.version_key }}) </span>
                                                                	<span v-if="rejected.children.length >0 && rejected.original_id ==null"> ( Version No 1) </span>
																</a>
															</td>
															<td>{{rejected.created_at | formatDate}}</td>
															<td>{{rejected.prop_value}} {{rejected.currency}}</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="proposal-table__pagination" id="RejectedProposal" v-show="pagination">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getRejectedProposal(currentPage-1)"
															v-bind:style= "[totalRecords < 11 || currentPage == 1 ? { 'pointer-events': 'none' } : null ]">Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in recordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === currentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getRejectedProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getRejectedProposal(currentPage+1)"
																 v-bind:style= "[totalRecords < 11 || rejectedProposals_lastPage == currentPage  ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="inner-page__content-box h-auto pb-0">
						<h2 class="main-subtitle">{{pipeline1}}(In INR)</h2>
						<div class="pipeline">
							<span>₹ {{ inr_total }}</span>
							<p>{{pipeline1}} Total</p>
						</div>
						<div class="text-right">
							<img src="@/assets/images/pipeline.svg" class="img-fluid">
						</div>
					</div>
					<br>
					<div class="inner-page__content-box h-auto pb-0">
						<h2 class="main-subtitle">{{pipeline1}}(In USD)</h2>
						<div class="pipeline">
							<span>$ {{ usd_total }}</span>
							<p>{{pipeline1}} Total</p>
						</div>
						<div class="text-right">
							<img src="@/assets/images/pipeline.svg" class="img-fluid">
						</div>
					</div>
				</div>
			</div>
		</div>
		<loading :active.sync="isLoading"></loading>
	</main>
</template>
<script>
	import Sidebar from '@/components/Sidebar.vue';
  import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
        name: "Proposal",
        data () {
            return {
                usd_total:0,
                inr_total:0,
                isLoading: false,
                draftproposals: [],
                outstandingProposals: [],
                acceptedProposals: [],
                rejectedProposals:[],
				totalProposalOfUser: [],
				search_box:'',
				totalRecords:0,
				totalDraftRecords:0,
				totalOutstandingRecords:0,
				totalAcceptedRecords:0,
				currentPage:1,
				draftCurrentPage:1,
				outstandingCurrentPage:1,
				acceptedCurrentPage:1,
				pagination : false,
				paginationAccepted: false,
				paginationOutstanding : false,
				paginationDraft : false,
				pipeline1:"Draft",
				draftproposals_lastPage:1,
				outstandingProposals_lastPage:1,
				acceptedProposals_lastPage:1,
				rejectedProposals_lastPage:1,
            }
		},
		computed:{
				drafts(){

				return this.draftproposals.filter((draft) => {
					return (
						draft.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
					);
				});
				},
				outstandings(){
				return this.outstandingProposals.filter((outstanding) => {
					return (
						outstanding.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
					);
				});
				},
				accepteds(){
					return this.acceptedProposals.filter((accepted) => {
					return (
						accepted.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
					);
				});
				},
				rejecteds(){
					return this.rejectedProposals.filter((rejected) => {
					return (
						rejected.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
					);
				});
				},
				recordLoop(){
					return  [...Array(Math.ceil(this.totalRecords/10))].map((_, i) => i * 1);
				},
				draftRecordLoop(){
					return  [...Array(Math.ceil(this.totalDraftRecords/10))].map((_, i) => i * 1);
				},
				outstandingRecordLoop(){
					return  [...Array(Math.ceil(this.totalOutstandingRecords/10))].map((_, i) => i * 1);
				},
				acceptedRecordLoop(){
					return  [...Array(Math.ceil(this.totalAcceptedRecords/10))].map((_, i) => i * 1);
				},

		},
		mounted(){
                    this.prposalData();
		},
        methods: {
					openSearch() {
							$(".proposals-search").addClass("active");
					},
					closeSearch() {
							$(".proposals-search").removeClass("active");
						this.search_box ='';
					},
					// kFormatter(num) {
                    //     console.log(Math.abs(num));
					// 	return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(0)) + 'k' : Math.sign(num)*Math.abs(num)
					// },
					draft(){
							var usd_total = 0;
							var inr =0;
							var inr_total = 0;
							this.pipeline1 = "Draft"
                    for (let i = 0; i < this.draftproposals.length; i++) {
                                if(this.draftproposals[i].currency == 'USD'){
                                        usd_total = parseInt(usd_total) + parseInt(this.draftproposals[i].prop_value);
                                }else{
                                    inr = parseInt(inr) + parseInt(this.draftproposals[i].prop_value);
                                }
                            }
							  inr_total = (inr + (usd_total * 70)).toFixed(2);
                              usd_total = (usd_total +(inr / 70)).toFixed(2);
                              this.usd_total= this.kFormatter(usd_total);
                              this.inr_total = this.kFormatter(inr_total);

						},
						outstanding(){
							var usd_total = 0;
							var inr =0;
							var inr_total =0;
							this.pipeline1 = "Pipeline"
                        for (let i = 0; i < this.outstandingProposals.length; i++) {
                                    if(this.outstandingProposals[i].currency == 'USD'){
                                        usd_total = parseInt(usd_total) + parseInt(this.outstandingProposals[i].prop_value);
                                    }else{
                                        inr = parseInt(inr) + parseInt(this.outstandingProposals[i].prop_value);
                                    }
                                }
                                inr_total = (inr + (usd_total * 70)).toFixed(2);
                                usd_total = (usd_total +(inr / 70)).toFixed(2);
                                this.usd_total= this.kFormatter(usd_total);
                                this.inr_total = this.kFormatter(inr_total);

						},
						accepted(){
							var usd_total = 0;
							var inr =0;
							var inr_total =0;
							this.pipeline1 = "Accepted";
                        for (let i = 0; i < this.acceptedProposals.length; i++) {
                                if(this.acceptedProposals[i].currency == 'USD'){
                                    usd_total = parseInt(usd_total) + parseInt(this.acceptedProposals[i].prop_value);
                                }else{
                                    inr = parseInt(inr) + parseInt(this.acceptedProposals[i].prop_value);
                                }
                                }
								inr_total = (inr + (usd_total * 70)).toFixed(2);
                                usd_total = (usd_total +(inr / 70)).toFixed(2);
                                this.usd_total= this.kFormatter(usd_total);
                                this.inr_total = this.kFormatter(inr_total);

						},
						rejected(){
							var usd_total = 0;
							var inr =0;
							var inr_total =0;
							this.pipeline1 = "Rejected"
                            for (let i = 0; i < this.rejectedProposals.length; i++) {
                                if(this.rejectedProposals[i].currency == 'USD'){
                                            usd_total = parseInt(usd_total) + parseInt(this.rejectedProposals[i].prop_value);
                                }else{
                                    inr = parseInt(inr) + parseInt(this.rejectedProposals[i].prop_value);
                                }
                            }
							  inr_total = (inr + (usd_total * 70)).toFixed(2);
                              usd_total = (usd_total +(inr / 70)).toFixed(2);
                              this.usd_total= this.kFormatter(usd_total);
                              this.inr_total = this.kFormatter(inr_total);

						},
					click_on_proposal(id){
							this.isLoading = true;
							this.$store.commit('setproposalid',id);
                            this.$router.push({ path: '/proposaldetail' });
						},
					add_proposal(){
							this.$store.commit('toStartNewProposal');
                            this.$router.push({ path: '/proposal-step-1' });
						},
                    prposalData() {
                        this.isLoading = true;
                        this.$store.dispatch("get_dashboard_data").then(response => {
                            if (response.status) {
                                        this.draftproposals = this.$store.state.draftproposals.data;
                                        this.outstandingProposals= this.$store.state.outstandingProposals.data;
                                        this.acceptedProposals = this.$store.state.acceptedProposals.data;
                                        this.rejectedProposals= this.$store.state.rejectedProposals.data;
                                        this.totalProposalOfUser = this.$store.state.totalProposalOfUser;

										this.totalRecords =  this.$store.state.rejectedProposals.total;
										this.currentPage =  this.$store.state.rejectedProposals.current_page;
										this.rejectedProposals_lastPage = this.$store.state.rejectedProposals.last_page;

										this.totalDraftRecords =  this.$store.state.draftproposals.total;
										this.draftCurrentPage =  this.$store.state.draftproposals.current_page;
										this.draftproposals_lastPage = this.$store.state.draftproposals.last_page;

										this.totalOutstandingRecords =  this.$store.state.outstandingProposals.total;
										this.outstandingCurrentPage =  this.$store.state.outstandingProposals.current_page;
										this.outstandingProposals_lastPage = this.$store.state.outstandingProposals.last_page;

										this.totalAcceptedRecords =  this.$store.state.acceptedProposals.total;
										this.acceptedCurrentPage =  this.$store.state.acceptedProposals.current_page;
										this.acceptedProposals_lastPage = this.$store.state.acceptedProposals.last_page;

										if(this.totalRecords > 10 && this.totalRecords != 0){
											this.pagination = true
										}
										if(this.totalAcceptedRecords > 10 && this.totalAcceptedRecords != 0){
											this.paginationAccepted = true
										}
										if(this.totalOutstandingRecords > 10 && this.totalOutstandingRecords != 0){
											this.paginationOutstanding = true
										}
										if(this.totalDraftRecords > 10 && this.totalDraftRecords != 0){
											this.paginationDraft = true
										}
                                        this.draft();
                            this.isLoading = false;
                                }
                        });
						},
					getRejectedProposal(page){
						this.isLoading = true;
                        this.$store.dispatch("get_rejected_proposal",page)
                        .then(response => {
							if (response.status == "success") {
								this.rejectedProposals = this.$store.state.rejectedProposals.data;
									this.totalRecords =  this.$store.state.rejectedProposals.total;
										this.currentPage =  this.$store.state.rejectedProposals.current_page;
										this.rejectedProposals_lastPage = this.$store.state.rejectedProposals.last_page;
								this.isLoading = false;
							}
						});

					},
					getDraftProposal(page){
						this.isLoading = true;
								this.$store.dispatch("get_draft_proposal",page)
								.then(response => {
							if (response.status == "success") {
								this.draftproposals = this.$store.state.draftproposals.data;
								this.totalDraftRecords =  this.$store.state.draftproposals.total;
								this.draftCurrentPage =  this.$store.state.draftproposals.current_page;
								this.draftproposals_lastPage = this.$store.state.draftproposals.last_page;
								this.isLoading = false;
							}
						});

					},
					getOutstandingProposal(page){
						this.isLoading = true;
								this.$store.dispatch("get_outstanding_proposal",page)
								.then(response => {
							if (response.status == "success") {
								this.outstandingProposals = this.$store.state.outstandingProposals.data;
								this.totalOutstandingRecords =  this.$store.state.outstandingProposals.total;
										this.outstandingCurrentPage =  this.$store.state.outstandingProposals;
										this.outstandingProposals_lastPage = this.$store.state.outstandingProposals.last_page;

								this.isLoading = false;
							}
						});

					},
					getAcceptedProposal(page){
						this.isLoading = true;
								this.$store.dispatch("get_accepted_proposal",page)
								.then(response => {
							if (response.status == "success") {
								this.acceptedProposals = this.$store.state.acceptedProposals.data;
								this.totalAcceptedRecords =  this.$store.state.acceptedProposals.total;
										this.acceptedCurrentPage =  this.$store.state.acceptedProposals.current_page;
										this.acceptedProposals_lastPage = this.$store.state.acceptedProposals.last_page;
								this.isLoading = false;
							}
						});

					},

                    kFormatter(num) {
						return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
					}
        },
        components:{
					Sidebar,
					Loading,
        }
    }
</script>
