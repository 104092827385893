<template>
    <main>
        <sidebar></sidebar>
<div class="dashboard">
				<div class="dashboard_top mb-4">
					<h1 class="dash-title">Your <span>Company</span></h1>
					<a href="javascript:void(0)" @click="click_on_add_company" class="add-proposal">+</a>
				</div>
				<div class="row">
					<div class="dashboard-left col-md-8">
						<div class="row">
							<div class="col-12">
								<div class="dash-inner">
									<div class="proposals-top d-flex align-items-center">
										<div class="proposals-top__left">
											<h2>Company</h2>
											<p>You have created only {{total_page}} Company's so for</p>
										</div>
										<div class="proposals-action">
											<div class="proposals-search position-static">
												<div class="input-group mb-0">
													<input type="text" class="form-control" placeholder="Search here" v-model="search_box" style="border-radius: 20px;">
												</div>
											</div>
										</div>
									</div>
									<div class="tab-content" id="pills-tabContent">
										<div class="tab-pane fade show active" id="pills-draft" role="tabpanel" aria-labelledby="pills-draft-tab">
											<div class="proposal-table">
												<div class="table-responsive">
													<table class="table table-striped">
														<thead>
															<tr>
																<th>Company name</th>
																<th class="text-left">Action</th>
															</tr>
														</thead>
														<tbody>
                                                            <tr>
															    <td colspan="3" class="text-center" v-if="!company.length">No Data Found</td>
														    </tr>
															 <tr v-for="item in company"  :key= "item.id">
																<td>
																	{{item.company_name}}
																</td>
																<td class="text-left">
																	<ul class="proposal-table__action-list list-unstyled mb-0">
																		<li>
																			<a href="javascript:void(0);" @click="edit_company(item.company_id)" class="link" title="">
																				<img src="@/assets/images/icons/writing.svg" alt="">
																				Edit
																			</a>
																		</li>
																		<li>
																			<a v-if="item.withproposal.length == 0" href="javascript:void(0);" @click="delete_company(item.company_id)" class="link" title="">
																				<img src="@/assets/images/icons/delete-package.svg" alt="">
																				Delete
																			</a>
																		</li>
																	</ul>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
                                                <div class="proposal-table__pagination" v-show="pagination">
                                                    <nav>
                                                        <ul class="pagination">
                                                           <li class="page-item" >
															<a class="page-link" ref="#"  @click.prevent="get_companylist(CurrentPage-1)"
															v-bind:style= "[comp_data.total_page < 11 || comp_data.current_page == 1 ? { 'pointer-events': 'none' } : null ]" >Previous</a>
														</li>
                                                           <li
															class="page-item"
															v-for="item in recordLoop"
															:key="item" v-bind:class="{'page-item':true, 'active':(item === CurrentPage-1)}">
															<a class="page-link" href="#" @click.prevent="get_companylist(item+1)">{{item+1}}</a>
														</li>
                                                            <li class="page-item">
                                                                <a class="page-link" href="#"  @click.prevent="get_companylist(CurrentPage+1)"
                                                                    v-bind:style= "[ comp_data.total_page<11 || comp_data.last_page == comp_data.current_page ? { 'pointer-events': 'none' } : null ]"
                                                                >Next</a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="inner-page__content-box h-auto pb-0">
							<h2 class="main-subtitle mb-2">Created Company</h2>
							<h2 >{{total_page}}</h2>
							<div class="text-right">
								<img src="@/assets/images/created-templates.svg" class="img-fluid">
							</div>
						</div>
					</div>
				</div>
			</div>
        <loading :active.sync="isLoading"></loading>
</main>
</template>

<script>
	import Sidebar from '@/components/Sidebar.vue';
	import Loading from 'vue-loading-overlay';
    import ApiService from "../../services/Api";
	import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: "Template",
    data() {
        return {
			isLoading: false,
			company_data: [],
			comp_data:[],
            company_count:0,
            search_box:'',
			api_url:this.$api_url,
            pagination:false,
            CurrentPage:1,
            total_page:0
        };
    },
    computed:{
            company(){
                return this.company_data.filter((company) => {
                        return (
                                company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
                        );
                });
                },
                recordLoop(){
                    return  [...Array(Math.ceil(this.total_page/10))].map((_, i) => i * 1);
                },
		},
    methods: {
		init(){
                let thisPointer = this;
				thisPointer.isLoading = true;
				ApiService.get("get_user_company").then(function(data) {
                    if (data.data.status == true) {
                            thisPointer.company_data = data.data.company.data;
							 thisPointer.comp_data = data.data.company;
                            thisPointer.total_page = data.data.company.total;
                            if( thisPointer.total_page > 10  ){
                                thisPointer.pagination = true;
                            }
				            thisPointer.isLoading = false;
                    }
                });

		},
		click_on_add_company(){
				 this.$router.push({ path: '/add_company' });
		},
        get_companylist(page){
            this.isLoading = true;
            let thisPointer = this;
            let selected = thisPointer.selected;
            ApiService.get(`get_companylist?page=`+page).then(response => {
                if (response.data.status) {
                    thisPointer.company_data = response.data.company.data;
					 thisPointer.comp_data = response.data.company;
                    thisPointer.CurrentPage = page;
                    thisPointer.isLoading = false;
                }
            });
		},
        edit_company(id){
            this.$store.commit('setcompanyid',id);
            this.$router.push({ path: '/edit_company' });
        },
        delete_company(id){
            let thisPointer = this;
            thisPointer.$swal({
                        icon: 'warning',
                        text: 'Are you sure you want Delete?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                        if(result.value) {
                            	thisPointer.isLoading = true;
                            ApiService.get("delete_company/"+id).then(function(data) {
                                if (data.data.status == true) {
                            	    thisPointer.isLoading = false;
                                    thisPointer.$noty.success("Success : Company Deleted");
                                    thisPointer.$router.go();
                                }
                            });
                        }
                })
        }

    },

    mounted() {
          this.init();
    },
    components: {
        	Sidebar,
			Loading,
    }
};
</script>
