<template>
    <main>
        <sidebar></sidebar>
        <!-- proposal-form -->
        <div class="create-proposal">
            <div
                class="create-proposal__top d-flex align-items-center justify-content-between"
            >
                <div>
                    <h2>Create Proposal</h2>
                    <p v-if="this.$store.state.totalProposalOfUser == 0">Send your 1st Proposal</p>
                </div>
            </div>
            <div class="create-proposal__box">
                <div class="create-proposal__box-inner">
                    <form>
                        <div class="create-proposal__form-wrap">
                            <div class="row">
                                <div class="col-md-8">
                                    <div
                                        class="create-proposal__form template-box"
                                    >
                                        <h2>Template</h2>
                                        <div class="template-radio mb-5">
                                            <label>
                                                <input
                                                    type="radio"
                                                    v-model="selected_temp"

                                                    name="template"
                                                    value="0"
                                                    hidden=""

                                                />
                                                <div>
                                                    <span></span>
                                                    Start from <br />Scratch
                                                </div>
                                            </label>
                                            <label v-for="data in template_data">
                                                <input
                                                    type="radio"
                                                    v-model="selected_temp"
                                                    name="template"
                                                    hidden=""
                                                    :value="data.id"
                                                />
                                                <div>
                                                    <span></span>
                                                    <img
                                                        src="@/assets/images/icons/template.png"
                                                        class="img-fluid"
                                                    />
                                                    {{data.template_name}}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 offset-md-1">
                                    <div class="form-progress">
                                        <h3>Step 4 : Select Template</h3>
                                        <p>Next : Write Proposal</p>
                                    </div>
                                    <div class="follow-steps__wrap">
                                        <div class="follow-steps">
                                            <p>
                                                Follow the steps to build your
                                                proposal with a cover, template
                                                and what you’re selling.
                                            </p>
                                            <p>
                                                You can edit all of this later
                                                so just put the basics in to get
                                                started.
                                            </p>
                                        </div>
                                        <div class="img-box">
                                            <img
                                                src="@/assets/images/create-proposal.svg"
                                                class="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="create-proposal__form-actions">
                                    <a
                                       href="javascript:void(0)" @click="click_on_proposal_step3"
                                        class="btn btn-outline-primary"
                                        id="prev"
                                        >Back</a
                                    >
                                    <a
                                        href="javascript:void(0)"
                                        @click="next_page()"
                                        class="btn btn-primary"
                                        id="next"
                                        >Next</a
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </main>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loading from 'vue-loading-overlay';

import { required,requiredIf } from 'vuelidate/lib/validators'
export default {
    name: "Step4",
    data() {
        return {
            selected_temp: 0,
            template_data: [],
            isLoading:false
        };
    },
    methods: {
        init(){
                 this.template_data = this.$store.state.template_pages;
        },
        click_on_proposal_step3(){
            this.$router.push({ path: '/proposal-step-3' });
        },
        next_page() {
            let thisPointer = this;
            this.isLoading = true;
            var Cover = {
                company: thisPointer.$store.state.company_name,
                selected_temp: thisPointer.selected_temp,
                proposal_id: thisPointer.$store.state.proposal_id
            };
            thisPointer.$store
            .dispatch("store_template", Cover)
            .then(function(data) {
                if (data.status) {
                    thisPointer.$router.push({ path: '/proposal-step-5' });
                }
            });
        }
    },
    mounted() {
          this.init();
    },
    components: {
        	Sidebar,
			Loading,
    }
};
</script>
