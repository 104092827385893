<template>
        <section class="success-box">
			<div class="container">
				<div class="back-btn">
					<a href="javascript:void(0)" @click="back_to_home" title="">Back</a>
				</div>
				<div class="success-box__content">
					<img src="@/assets/images/success.svg" class="img-fluid">
					<h5>Your Proposal Was Sent Successfully</h5>
					<p>You will get notified email when they open, forward and sign your proposal.</p>
				</div>
			</div>
		</section>
</template>

<script>
	import Sidebar from '@/components/Sidebar.vue';
	import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: "Success",
    data() {
        return {
			isLoading: false,
        };
    },
    methods: {
        back_to_home()
        {
            this.$router.push({ path: '/home' });
        }
    },

    mounted() {
    },
    components: {
			Loading,
    }
};
</script>
