<template>

<div id="app">
<router-view/>
</div>
</template>

<script>
import ApiService from  "./services/Api";
export default {
        name: 'App',
        mounted:function(){
            let token = localStorage.getItem('authToken');
            let user_email = localStorage.getItem('user_emailaddress');
            let username = localStorage.getItem('user_firstname') + ' ' + localStorage.getItem('user_lastname');
            let current_url = "https://be.proposal.neosofttech.in/";

            if(user_email == null || username == null){
                ApiService.post("getUserDetail").then(response => {

                    if (response.data.status) {
                        console.log(response.data.user_detail.user_firstname,response.data.user_detail.user_lastname,response.data.user_detail.user_emailaddress);
                        var user_firstname = response.data.user_detail.user_firstname;
                        var user_lastname = response.data.user_detail.user_lastname;

                        user_email = response.data.user_detail.user_emailaddress;
                        username = user_firstname + ' ' + user_lastname;

                        localStorage.setItem('user_firstname',user_firstname);
                        localStorage.setItem('user_lastname',user_lastname);
                        localStorage.setItem('user_emailaddress',user_email);
                        checkPortalPermission(current_url, username, user_email, token);
                    }
                });
            }else{
                checkPortalPermission(current_url, username, user_email, token);
            }

        }
    }

</script>
<style>
     .gjs-block-label{
        width: 100%;
    }
    .grapes_label{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 90px;
    }
</style>
