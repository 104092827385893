<template>
    <main>
    <sidebar></sidebar>
    <!-- proposal-form -->
    <div class="create-proposal">
    <div class="create-proposal__top d-flex align-items-center justify-content-between">
    <div>
    <h2>Create Proposal</h2>
    <p v-if="this.$store.state.totalProposalOfUser == 0">Send your 1st Proposal</p>
    </div>
    </div>
    <div class="create-proposal__box">
    <div class="create-proposal__box-inner">
    <form>
    <div class="create-proposal__form-wrap">
    <div class="row">
    <div class="col-md-8">
    <div class="create-proposal__form send-proposal">
    <h2>Contact</h2>
    <div class="row">
    <div class="col-12">
    <div class="company-btn">
    <label>
    <input :disabled="is_company == 0" type="radio" name="proposal" value="1" v-model="checked" hidden>
    <div class="btn existing">
    <img src="@/assets/images/icons/search.svg" class="img-fluid">
    <p>An Exisiting Contract</p>
    </div>
    </label>
    <label>
    <input :checked="is_company == 0" type="radio" name="proposal" value="0" v-model="checked" hidden>
    <div class="btn new">
    <span>+</span>
    <p>Add New Contract</p>
    </div>
    </label>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-3 offset-md-1">
    <div class="form-progress">
    <h3>Step 1 : Contact</h3>
    <p>Next : Basic Details</p>
    </div>
    <div class="follow-steps__wrap">
    <div class="follow-steps">
    <p>Follow the steps to build your proposal with a cover, template and what you’re selling.</p>
    <p>You can edit all of this later so just put the basics in to get started.</p>
    </div>
    <div class="img-box">
    <img src="@/assets/images/create-proposal.svg" class="img-fluid">
    </div>
    </div>
    </div>
    <div class="create-proposal__form-actions">
    <a href="javascript:void(0)" @click="check_choise" class="btn btn-primary">Next</a>
    </div>
    </div>
    </div>
    </form>
    </div>
    </div>
    </div>
    <loading :active.sync="isLoading"></loading>
    </main>
    </template>
    <script>
    import Sidebar from '@/components/Sidebar.vue';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
    name: "Step1",
    data () {
    return {
    is_company:0,
    checked:0,
    isLoading: false,
    }
    },
    methods: {
    check_company_count(){
        let thisPointer = this;
        thisPointer.isLoading = true;
        thisPointer.$store.dispatch('get_company').then(function (data) {
            if(data.status){
                thisPointer.is_company =thisPointer.$store.getters.getisusercompany;
                thisPointer.isLoading = false;
            }
        });
    },
    check_choise(){
        let thisPointer = this;
        thisPointer.$store.commit('setuserchoise', thisPointer.checked);
        thisPointer.$router.push({ path: '/proposal-step-2' });
    }
    },
    mounted:function(){
        this.check_company_count();
    },
    components:{
        Sidebar,
        Loading
    }
}
</script>

<style scoped>

    .disableddiv {
    pointer-events: none;
    opacity: 0.5;
    }

</style>
