<template>
<main>
<sidebar></sidebar>
<!-- reports -->
<div class="reports inner-page-pd">
<h2 class="main-title mb-0">Reports</h2>
<span>Check Your Reports</span>
<div class="row mt-4">
<div class="col-md-8 pr-0">
<div class="inner-page__content-box mb-4">
<form>
<div class="row">
<div class="col-md-6">
<div class="form-group">
<label for="from-date">Run Report From Date</label>
<div class="date">
<input v-model="from_date" type="date" class="form-control" :class="{ 'is-invalid': submitted && $v.from_date.$error }" id="from-date" placeholder="">
<div v-if="submitted && $v.from_date.$error " class="invalid-feedback">
<span v-if="!$v.from_date.required " class="text-danger">From Date Is Requrired</span>
</div>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-6">
<div class="form-group">
<label for="to-date">Run Report To Date</label>
<div class="date">
<input v-model="to_date" type="date" class="form-control" :class="{ 'is-invalid': submitted && $v.to_date.$error }" id="to-date" placeholder="">
<div v-if="submitted && $v.to_date.$error " class="invalid-feedback">
<span v-if="!$v.to_date.required" class="text-danger">To Date Is Requrired</span>
</div>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-12">
<div class="inner-form-actions mt-2">
<a href="javascript:void(0)" @click="generate_report()" class="btn btn-primary">Generate report</a>
</div>
</div>
</div>
</form>
</div>
</div>
<div class="col-md-3 offset-md-1">
<div class="follow-steps__wrap">
<div class="follow-steps">
<p>Here you see your reports. You can view your report for specific date. Simply select date range you want to see.</p>
<p>If you have any query. please contact admin.</p>
</div>
<div class="img-box">
<img src="@/assets/images/reports-right.svg" class="img-fluid">
</div>
</div>
</div>
</div>
</div>
<loading :active.sync="isLoading"></loading>
</main>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue';
import { required ,requiredIf } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
name: "Report",
data () {
return {
from_date:'',
to_date:'',
select_user:'',
submitted:false,
is_user:0,
isLoading: false,
}
},
validations:{
from_date:{ required },
to_date:{ required },
select_user:{
required:requiredIf(function () {
return this.is_user == 1
})
},
},
mounted() {
this.getUserList();
},
methods: {
generate_report(){
let thisPointer = this;
thisPointer.submitted = true;
thisPointer.$v.$touch();
if(!thisPointer.$v.$invalid) {
var generateReportDetails = {'from_date':thisPointer.from_date,'to_date':thisPointer.to_date,};
this.isLoading = true;
thisPointer.$store.dispatch('generateReport',generateReportDetails)
.then((response)=>{
if(response.status == 'success'){
this.$router.push({ path: '/viewreport' });
this.isLoading = false;
}
})
}
},
getUserList(){
let thisPointer = this;
thisPointer.$store.dispatch('getuserlist')
}
},
components:{
Sidebar,
Loading
}
}
</script>
