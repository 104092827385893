<template>
</template>
<script>
import ApiService from '../services/Api';
import Auth from '../services/Auth';

    export default {
        name: "Ssologin",
        data () {
            return {
            }
        },
        mounted(){
          let thisPointer = this;
          var token = thisPointer.$route.query.token;
          ApiService.get("ssologin/"+token).then(function(data) {
             if(data.data.status == true){
                  Auth.saveToken(data.data.token);
                  var username = data.data.username;
                  localStorage.setItem('username',username);
                  localStorage.setItem('workplace_id',1);
                  localStorage.setItem('workplace','Default-Workplace');
                  var myCurrentDate = new Date();
                  var myFutureDate  = new Date(myCurrentDate);
                  myFutureDate.setDate(myFutureDate.getDate()+ 10);
                  localStorage.setItem('sessionexpiry',myFutureDate);
                  thisPointer.$router.push('/home');
              } else {
                if(data.data.html){
                  document.getElementsByTagName('html')[0].innerHTML = data.data.html;
                }
                else{
                  thisPointer.$router.push('/login');
                }
              }
          });
       }

    }
</script>
