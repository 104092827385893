<template>
	<main>
		<sidebar></sidebar>
		<div class="dashboard">
			<div class="dashboard_top mb-4">
				<h1 class="dash-title">Proposal for <span>{{ proposalCompanyNmae }} {{proposalData.original_id !=null?'Version No '+proposalData.version_key:proposalData.children_count>0?'Version No 1':''}}</span></h1>
				<a href="javascript:void(0);" @click="add_proposal()" class="add-proposal">+</a>
			</div>
			<div class="row">
				<div class="col-md-8">
					<div class="proposals-details__actions mb-4">
						<ul class="list-unstyled mb-0">
							<li>
								<span><img src="@/assets/images/icons/edit.svg" class="img-fluid"></span>
								Edit
								<div class="submenu">
									<ul class="list-unstyled mb-0">
										<li><a href="javascript:void(0);" @click="click_on_basic_details()">Edit Basic Details</a></li>
										<li><a href="javascript:void(0);" @click="click_on_cover_details()">Edit Cover</a></li>
										<li><a href="javascript:void(0);" @click="click_on_edit_content()">Edit Content</a></li>
									</ul>
								</div>
							</li>
							<li>
								<!-- Send -->
								<a v-if="template_id != 0" href="javascript:void(0)" @click="click_on_proposal_step6">
                                    <span><img src="@/assets/images/icons/send.svg" class="img-fluid"></span>
                                    Send
                                </a>
								<a v-else href="proposal-step-4" @click="click_on_send()">
                                    <span><img src="@/assets/images/icons/send.svg" class="img-fluid"></span>
                                    Send
                                </a>
							</li>
							<li>
								<a v-if="template_id != 0" :href="api_url+'Proposal-pages/'+template_id"  target="_blank">
                                    <span><img src="@/assets/images/icons/preview.svg" class="img-fluid"></span>
                                    Preview
                                </a>
								<a v-else href="javascript:void(0)">
                                    <span><img src="@/assets/images/icons/preview.svg" class="img-fluid"></span>
                                    Preview
                                </a>
							</li>
							<li>
								<span><img src="@/assets/images/icons/options.svg" class="img-fluid"></span>
								Options
								<div class="submenu">
									<ul class="list-unstyled mb-0">
										<li v-if="template_id != 0"><a href="javascript:void(0);" @click="click_on_save_template">Save as Template</a></li>
										<li v-if="template_id != 0"><a href="javascript:void(0);" @click="generate_version">Create Version</a></li>
										<li><a href="javascript:void(0);" @click="click_on_mark_lost">Mark as Rejected</a></li>
										<li><a href="javascript:void(0);" v-if="proposalIsMail == 1" @click="click_on_manual_approve">Mark as Accepted</a></li>
										<li><a href="javascript:void(0);" @click="click_on_delete_proposal">Delete</a></li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
					<div class="inner-page__content-box">
						<h2 class="main-subtitle">Proposal Activity</h2>
						<div class="proposal-activity">
							<ul class="list-unstyled mb-0">
								<li>
									<span>Proposal Created</span>
									<p>{{ proposalCreatedAt | formatDate }} at {{ proposalCreatedAt | formatTime}} by You</p>
								</li>
								<li v-if="proposalIsMail == 1">
									<span>Sent by You</span>
									<p>{{ proposalIsMailDate | formatDate }} at  {{ proposalIsMailDate | formatTime}}</p>
									<div class="proposal-activity__details">
										<p>Recipients</p>
										<div v-for=" data in proposal_receipent" :key="data.id">
										<h2>{{data.firstname}}</h2>
										<p>{{data.email}}</p>
										</div>
									</div>
								</li>
                                <li v-if="proposalIsOpen == 1 && proposalIsOpenDate !=' '">
									<span >Proposal Open</span>
									<p>{{ proposalIsOpenDate | formatDate }} at {{ proposalIsOpenDate | formatTime}}</p>
								</li>

                                <li v-if="proposalIsRead == 1 && proposalIsReadDate !=' '">
									<span >Proposal Read</span>
									<p>{{ proposalIsReadDate | formatDate }} at {{ proposalIsReadDate | formatTime}}</p>
								</li>

								<li v-if="proposalIsAccepted == 1">
									<span >Proposal Accepted</span>
									<p>{{ proposalIsAcceptedDate | formatDate }} at {{ proposalIsAcceptedDate | formatTime}}</p>
								</li>

								<li v-if="proposalIsRejected == 1">
									<span >Proposal Rejected</span>
									<p>{{ proposalIsRejectedDate | formatDate }} at {{ proposalIsRejectedDate | formatTime}}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="inner-page__content-box">
						<h2 class="main-subtitle">Proposal Progress</h2>
						<div class="proposal-progress">
							<ul class="list-unstyled mb-0">
								<li :class="checkStatusSent()">Sent</li>
								<li :class="checkStatusOpened()">Email Opened</li>
								<li :class="checkStatusRead()">Proposal Read</li>
								<li :class="checkIsAccepted()">{{proposalProgressLabel}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<loading :active.sync="isLoading"></loading>
	</main>
</template>
<script>
	// import {mapState} from 'vuex';
	import Sidebar from '@/components/Sidebar.vue';
	import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
    import ApiService from "../../services/Api";
	export default {
        name: "ProposalDetail",
        data () {
            return {
                    isLoading: false,
					api_url:this.$api_url,
                    proposalId:0,
                    proposalCompanyNmae:'',
                    proposalCreatedAt:'',
                    proposalUpdatedAt:'',
                    proposalIsAccepted:0,
                    proposal_receipent:[],

                    proposalIsMail:0,
                    proposalIsMailDate:'',

                    proposalIsOpen:0,
                    proposalIsOpenDate:'',

                    proposalIsRead:0,
                    proposalIsReadDate:'',

                    proposalIsAccepted:0,
                    proposalIsAcceptedDate:'',

                    proposalIsRejected:0,
                    proposalIsRejectedDate:'',
					proposalProgressLabel:'',
                    template_id:0
            }
        },
        methods: {
					add_proposal(){
					this.$store.commit('toStartNewProposal');
                        this.$router.push({ path: '/proposal-step-1' });
                    },
                    click_on_send(){
					    this.$store.commit('onPressOfBack');
                        this.$store.commit('setuserchoise', 0);
						this.$router.push({ path: '/proposal-step-4' });
                        
                    },
					click_on_proposal_step6(){
						let thisPointer = this;
						thisPointer.isLoading = true;
						ApiService.get('check_html/'+thisPointer.template_id)
						.then(response=>{
								if(response.data.status){
									thisPointer.isLoading = false;
									thisPointer.$router.push({ path: '/proposal-step-6' });
								}
						})
						  
					},
                    generate_pdf(){
                        this.isLoading = true;
						let thisPointer =this;
                        ApiService.get("downloadpdf/"+this.proposalId)
                        .then(response => {
                            var link=document.createElement('a');
                            document.body.appendChild(link);
                            link.href=this.api_url+'pdf/'+this.proposalId+'.pdf';
                            link.target='_blank';
                            this.isLoading = false;
                            thisPointer.$noty.success("Pdf Generated!");
                            link.click();
                        });

                    },
					generate_version(){
                        let thisPointer =this;
							thisPointer.isLoading = true;
						thisPointer.$store.dispatch('generate_version',this.proposalId)
						.then(response=>{
							if (response.status == "success") {
                                    thisPointer.$noty.success("Success : Version Created");
                                    thisPointer.isLoading = false;
								}
						})

                    },
					checkStatusSent(){
							if(this.proposalIsMail == 1)
							{
								return "done";
							}
							else{
								return '';
							}
					},
					checkIsAccepted(){
						if(this.proposalIsAccepted == 1 && this.proposalIsOpen == 1 && this.proposalIsRead == 1){
							this.proposalProgressLabel= "Accepted";
							return "done";
						}
                        else if(this.proposalIsAccepted == 1){
							this.proposalProgressLabel= "Manually Accepted";
							return "done";
						}
                        else if(this.proposalIsRejected == 1){
							this.proposalProgressLabel= "Rejected";
							return "done";
						}else{
							this.proposalProgressLabel= "Signed";
							return '';
						}
					},
					checkStatusOpened(){
						if(this.proposalIsOpen == 1){
							return "done";
						}else{
							return '';
						}
					},
                    checkStatusRead(){
						if(this.proposalIsRead == 1){
							return "done";
						}else{
							return '';
						}
					},
					click_on_basic_details(){
							this.isLoading = true;
                            this.$store.commit('setuserchoise', 0);
						this.$store.dispatch('edit_proposal_basic_detail',this.proposalId)
						.then(response=>{
							if (response.status == "success") {
                                        this.$router.push({ path: '/proposal-step-2' });
										this.isLoading = false;
								}
						})
					},
					click_on_cover_details(){
					    this.isLoading = true;
                        this.$store.commit('setuserchoise', 0);
						this.$store.dispatch('edit_proposal_basic_detail',this.proposalId)
					 	.then(response=>{
							if (response.status == "success") {
                                        this.$router.push({ path: '/proposal-step-3' });
										this.isLoading = false;
								}
						})
					},
					click_on_edit_content(){
							let thisPointer = this;
                            this.$store.commit('setuserchoise', 0);
						 	thisPointer.isLoading = true;
							this.$store.dispatch('get_data_of_cover',this.proposalId)
							.then(response=>{
									if (response.status) {
										if(this.$store.state.template_id != 0){
                                                this.$router.push({ path: '/proposal-step-5' });
										}else{
                                                this.$router.push({ path: '/proposal-step-4' });
										}
												this.isLoading = false;
										}
							})
					},
					click_on_manual_approve(){
							this.isLoading = true;
							let thisPointer =this;
						this.$store.dispatch('manually_approve',this.proposalId)
						.then(response=>{
							if (response.status == true) {
                                        thisPointer.$noty.success("Success : Marked As Approved");
                                        thisPointer.proposalIsAccepted =  response.Proposals_data[0].is_accepted;
                                        thisPointer.proposalIsOpen =  response.Proposals_data[0].is_open;
                                        thisPointer.proposalMailAt = response.Proposals_data[0].mail_date;
                                        thisPointer.proposalIsMail =  response.Proposals_data[0].is_mail;
                                        thisPointer.proposalIsAcceptedAt = response.Proposals_data[0].accepted_date;
                                        thisPointer.proposalIsAcceptedDate = response.Proposals_data[0].accepted_date;
                                        thisPointer.proposalIsRejected = response.Proposals_data[0].is_rejected;
                                        thisPointer.proposalIsRejectedDate = response.Proposals_data[0].rejected_date;
										thisPointer.isLoading = false;
								}
						})
					},
					click_on_mark_lost(){
						this.isLoading = true;
						let thisPointer =this;
						this.$store.dispatch('mark_lost',this.proposalId)
						.then(response=>{
							if (response.status == true) {
                                        thisPointer.$noty.error("Success : Marked As Rejected");
									thisPointer.proposalIsAccepted =  response.Proposals_data[0].is_accepted;
									thisPointer.proposalIsOpen =  response.Proposals_data[0].is_open;
									thisPointer.proposalIsMail =  response.Proposals_data[0].is_mail;
									thisPointer.proposalIsRejected = response.Proposals_data[0].is_rejected;
									thisPointer.proposalIsRejectedDate = response.Proposals_data[0].rejected_date;
										this.isLoading = false;
								}
						})
					},
					click_on_delete_proposal(){
						this.$swal({
                        icon: 'warning',
                        text: 'Are you sure you want Delete?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                        if(result.value) {
                            	this.isLoading = true;
								let thisPointer =this;
							this.$store.dispatch('delete_proposal',this.proposalId)
							.then(response=>{
								if (response.status == "success") {
                                            thisPointer.$noty.success("Success : Proposal Deleted");
                                            thisPointer.$router.push({ path: '/proposal' });
											this.isLoading = false;
									}
							})
                        }
                })
					},
					click_on_save_template(){
                        let thisPointer =this;
							thisPointer.isLoading = true;
						thisPointer.$store.dispatch('save_template',this.proposalId)
						.then(response=>{
							if (response.status == "success") {
                                    thisPointer.$noty.success("Success : Template Created");
                                    thisPointer.isLoading = false;
								}
						})
					},
                    get_proposaldetail(){
							var id = this.$store.state.proposal_id;
							this.isLoading = true;
                    ApiService.post("clickOnProposal/" + id)
                    .then(response => {
                            if (response.data.status) {
                                this.proposalId = response.data.Proposals_data[0].proposal_id;
								// console.log( response.data.Proposals_data[0].accepted_date);
                                this.proposalCompanyNmae = response.data.Proposals_data[0].proposal_with_company.company_name;
                                this.proposalCreatedAt= response.data.Proposals_data[0].created_at;
                                this.proposalUpdatedAt= response.data.Proposals_data[0].updated_at;

                                this.proposal_receipent= response.data.proposal_receipent;

                                this.proposalIsMail= response.data.Proposals_data[0].is_mail;
                                this.proposalIsMailDate= response.data.Proposals_data[0].mail_date;

                                this.proposalIsOpen= response.data.Proposals_data[0].is_open;
                                this.proposalIsOpenDate= response.data.Proposals_data[0].open_date;

                                this.proposalIsRead= response.data.Proposals_data[0].is_read;
                                this.proposalIsReadDate= response.data.Proposals_data[0].read_date;

                                this.proposalIsAccepted= response.data.Proposals_data[0].is_accepted;
                                this.proposalIsAcceptedDate= response.data.Proposals_data[0].accepted_date;

                                this.proposalIsRejected= response.data.Proposals_data[0].is_rejected;
                                this.proposalIsRejectedDate= response.data.Proposals_data[0].rejected_date;

                                this.proposalData= response.data.Proposals_data[0];

                                this.template_id = response.data.template_id;
                                this.isLoading = false;
                            }
                    })

					},
        },
        mounted(){
            this.get_proposaldetail();
				},
		computed: {

				},
        components:{
					Sidebar,
					Loading,
        }
    }
</script>
