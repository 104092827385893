export default {
    getToken(){
        return localStorage.getItem('authToken');
    },
    saveToken(authToken){
        localStorage.setItem('authToken',authToken);
    },
    removeToken(){
        localStorage.removeItem('authToken');
    },
    addUserDetails(userDetails){
        localStorage.setItem('userDetails',userDetails);
    },
    logoutUser(){
        this.removeToken();
        localStorage.removeItem('userDetails');
    },
    getUserDetails(param = null){
        let useDetails  =   JSON.parse(localStorage.getItem('userDetails'));
        return (param == null) ? useDetails : (param in useDetails ? userDetails.param : null );
    }
}
