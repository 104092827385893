import "sweetalert2/dist/sweetalert2.min.css";
import "vuejs-noty/dist/vuejs-noty.css";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";

import Vue from "vue";

// import VueLodash from 'vue-lodash'
import lodash from "lodash";
import moment from "moment";
import VModal from "vue-js-modal";
import VueSweetalert2 from "vue-sweetalert2";
import VueNoty from "vuejs-noty";
import Vuelidate from "vuelidate";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(VueNoty, {
  timeout: 4000,
  progressBar: true,
  theme : 'bootstrap-v4',
  layout: 'topRight'
})
Vue.use(VueSweetalert2);


Vue.use(Vuelidate)
Vue.use(lodash);
Vue.use(VModal, { componentName: 'modal' })
Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('D MMMM YYYY')
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm A')
  }
});

Vue.filter('forMonthAndYear', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM YYYY')
  }
});

Vue.filter('forDateAndMonth', function(value) {
  if (value) {
    return moment(String(value)).format('D MMMM')
  }
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
