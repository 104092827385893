import Vue from "vue";

import VueRouter, { RouteConfig } from "vue-router";

import AuthToken from "../services/Auth";
import AddCompany from "../views/Company.vue";
import EditCompany from "../views/edit-company.vue";
import EditTempalte from "../views/edit-template.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Profile from "../views/Profile.vue";
import Company from "../views/proposal/Company_list.vue";
import PreviewCover from "../views/proposal/previewcover.vue";
import Proposal from "../views/proposal/proposal.vue";
import ProposalDetail from "../views/proposal/proposaldetail.vue";
import Step1 from "../views/proposal/step1.vue";
import Step2 from "../views/proposal/step2.vue";
import Step3 from "../views/proposal/step3.vue";
import Step4 from "../views/proposal/step4.vue";
import Step5 from "../views/proposal/step5.vue";
import Step6 from "../views/proposal/step6.vue";
import Report from "../views/Report.vue";
import ReportView from "../views/ReportView.vue";
import Ssologin from "../views/Ssologin.vue";
import Success from "../views/success.vue";
import Tempalte from "../views/Template.vue";

Vue.use(VueRouter)

const routes = <Array<RouteConfig>>[
{
path: '/',
name: 'Home',
component: Home
},
{
    path: '/ssologin',
    name: 'ssologin',
    component: Ssologin
},
{
path: '*',
name: 'Home',
component: Home
},
{
path: '/home',
name: 'Home',
component: Home
},
{
path: '/login',
name: 'Login',
component: Login
},
{
path: '/company',
name: 'Company',
component: Company
},
{
path: '/add_company',
name: 'AddCompany',
component: AddCompany
},
{
path: '/edit_company',
name: 'EditCompany',
component: EditCompany
},
{
path: '/template',
name: 'Template',
component: Tempalte
},
{
path: '/success',
name: 'Success',
component: Success
},

{
path: '/proposal-step-1',
name: 'Step1',
component: Step1
},
{
path: '/proposal-step-2',
name: 'Step2',
component: Step2
},
{
path: '/proposal-step-3',
name: 'Step3',
component: Step3
},
{
path: '/proposal-step-4',
name: 'Step4',
component: Step4
},
{
path: '/proposal-step-5',
name: 'Step5',
component: Step5
},
{
path: '/proposal-step-6',
name: 'Step6',
component: Step6
},
{
path: '/proposal-preview-cover',
name: 'PreviewCover',
component: PreviewCover
},
{
path: '/profile',
name: 'Profile',
component: Profile
},
{
path: '/report',
name: 'Report',
component: Report
},
{
path: '/edit-template',
name: 'EditTemplate',
component: EditTempalte
},
{
path: '/viewreport',
name: 'ReportView',
component: ReportView
},
{
path: '/proposaldetail',
name: 'ProposalDetail',
component: ProposalDetail
},
{
path: '/proposal',
name: 'Proposal',
component: Proposal
},
]

const router = new VueRouter({
// mode: 'history',
// base: process.env.BASE_URL,
routes
})

router.beforeEach((to, from, next) => {

if (to.name == 'ssologin' && (AuthToken.getToken() == '' ||  AuthToken.getToken() == null )) {
        next()
        return
      }

/* if login page is not called and authentication is not there then go to login*/
if (to.name != 'Login' && (AuthToken.getToken() == '' || AuthToken.getToken() == null )) {
next('/login');
return
}

/* if login page is not called and authentication is there then go to that page*/
if (to.name != 'Login' && AuthToken.getToken() != '' && AuthToken.getToken() != null ) {
next()
return
}

/* if login page is called and authentication is there then go to home page*/
if (to.name == 'Login' && AuthToken.getToken() != '' && AuthToken.getToken() != null ) {
next('/home')
return
}
next()
});

export default router