<template>
    <main>
        <sidebar></sidebar>
        <div class="dashboard">
            <div class="dashboard_top">
                <h1 class="dash-title">
                    Welcome <span>{{ user_firstname }}!</span>
                </h1>
                <p class="dash-subtitle">
                    Send proposals to your clients easily
                </p>
                <a href="javascript:void(0)" @click="add_proposal" class="add-proposal">+</a>
            </div>
            <div class="row">
                <div class="dashboard-left col-md-8">
                    <div class="row">
                        <div class="col-12 mb-4">
                            <div class="create-proposal-box">
                                <img
                                    src="@/assets/images/create-proposal.svg"
                                    alt=""
                                    class="img-fluid"
                                />
                                <div>
                                    <h2>
                                        Let’s Create <br /><span
                                            >Amazing Proposals</span
                                        >
                                    </h2>
                                    <p v-if="totalProposalOfUser == 0">
                                        Send your 1st Proposal
                                    </p>
                                    <a
                                        href="javascript:void(0)"
                                        class="btn btn-primary"@click="add_proposal"
                                        >CREATE PROPOSAL</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div :class="lastProposalSentProposalId == 0 ?'col-lg-12 pr-2':'col-lg-8 pr-2'">
                            <div class="dash-inner">
                                <h2>Overview</h2>
                                <div class="overview">
                                    <div class="overview__content">
                                        <span>{{ proposalsShared }}</span>
                                        <label>proposals <br />Shared</label>
                                    </div>
                                    <div class="overview__content">
                                        <span>{{ draftproposals.length }}</span>
                                        <label
                                            >Unassigned <br />proposals</label
                                        >
                                    </div>
                                    <div class="overview__content">
                                        <span
                                            >{{
                                                conversionRate.toFixed(0)
                                            }}%</span
                                        >
                                        <label>Conversion <br />Rate</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="lastProposalSentProposalId != 0" class="col-lg-4 pl-2">
                            <div class="dash-inner">
                                <h2   class="black">Last Proposal<br/>Sent({{lastProposalSentName}})</h2>
                                <!--<a v-if="lastProposalSentProposalId != 0"  @click="generate_pdf(lastProposalSentProposalId)" href="javascript:void(0)" type="button" class="btn btn-outline-primary btn-sm" > Download </a>-->
                                <a v-if="lastProposalSentProposalId != 0" target="_blank" :href="api_url+'Proposal-pages/'+lastProposalSent.proposal_with_template.id"  type="button" class="btn btn-outline-primary btn-sm" > Preview </a>
                                <div class="graph mt-4">
                                    <img
                                        src="@/assets/images/graph.svg"
                                        class="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="dash-inner">
                                <div
                                    class="proposals-top d-flex align-items-center"
                                >
                                    <div class="proposals-top__left">
                                        <h2>Proposals</h2>
                                        <p>
                                            You have overall created {{ totalProposalOfUser }} proposals so far
                                        </p>
                                    </div>
                                    <div class="proposals-action">
                                        <div class="proposals-action__tabs">
                                            <ul
                                                class="nav nav-pills"
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link active"
                                                        id="pills-draft-tab"
                                                        data-toggle="pill"
                                                        href="#pills-draft"
                                                        role="tab"
                                                        aria-selected="true"
                                                        >Draft</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        id="pills-outstanding-tab"
                                                        data-toggle="pill"
                                                        href="#pills-outstanding"
                                                        role="tab"
                                                        aria-selected="false"
                                                        >Outstanding</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        id="pills-accepted-tab"
                                                        data-toggle="pill"
                                                        href="#pills-accepted"
                                                        role="tab"
                                                        aria-selected="false"
                                                        >Accepted</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        id="pills-lost-tab"
                                                        data-toggle="pill"
                                                        href="#pills-lost"
                                                        role="tab"
                                                        aria-selected="false"
                                                        >Rejected</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="proposals-search">
                                            <div class="input-group mb-0">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search here"
                                                    v-model="search_box"
                                                />
                                                <div class="input-group-append">
                                                    <a
                                                        href="javascript:void(0);"
                                                        class="close-search"
                                                        @click="closeSearch"
                                                        ><img
                                                            src="@/assets/images/icons/cross.svg"
                                                            class="img-fluid"
                                                    /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="search-btn d-block ml-1"
                                        @click="openSearch"
                                    >
                                        <a href="javascript:void(0);"
                                            ><img
                                                src="@/assets/images/icons/search.svg"
                                                class="img-fluid"
                                        /></a>
                                    </div>
                                </div>
                                <div class="tab-content" id="pills-tabContent">
                                    <div
                                        class="tab-pane fade show active"
                                        id="pills-draft"
                                        role="tabpanel"
                                        aria-labelledby="pills-draft-tab"
                                    >
                                        <div class="proposal-table">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-striped"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Company name
                                                            </th>
                                                            <th>
                                                                Created Date
                                                            </th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
													<tr>
													<td colspan="3" class="text-center" v-if=" !drafts.length"> No Data Found</td>
													</tr>
                                                        <tr
                                                            v-for="draft in drafts"
                                                            :key="
                                                                draft.proposal_id
                                                            "
                                                        >
                                                            <td>
                                                                <span class="d-flex">
                                                                    {{
                                                                        draft
                                                                            .proposal_with_company
                                                                            .company_name
                                                                    }} 
                                                                    <span v-if="draft.original_id !=null">( Version No {{ draft.version_key }}) </span>
                                                                    <span v-if="draft.children.length >0 && draft.original_id ==null"> ( Version No 1) </span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    draft.created_at
                                                                        | formatDate
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    draft.prop_value
                                                                }} {{draft.currency}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                             <div class="proposal-table__pagination" id="DraftProposal" v-show="paginationDraft">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getDraftProposal(draftCurrentPage-1)"
															v-bind:style= "[totalDraftRecords < 11 || draftCurrentPage == 1 ? { 'pointer-events': 'none' } : null ]" >Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in draftRecordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === draftCurrentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getDraftProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getDraftProposal(draftCurrentPage+1)"
															 v-bind:style= "[ totalDraftRecords<11 || draftproposals_lastPage == draftCurrentPage ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="tab-pane fade"
                                        id="pills-outstanding"
                                        role="tabpanel"
                                        aria-labelledby="pills-outstanding-tab"
                                    >
                                        <div class="proposal-table">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-striped"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Company name
                                                            </th>
                                                            <th>
                                                                Created Date
                                                            </th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
														<tr>
															<td colspan="3" class="text-center" v-if=" !outstandings.length"> No Data Found</td>
														</tr>
                                                        <tr
                                                            v-for="outstanding in outstandings"
                                                            :key="
                                                                outstanding.proposal_id
                                                            "
                                                        >
                                                            <td>
                                                                <span class="d-flex">
                                                                    {{
                                                                        outstanding
                                                                            .proposal_with_company
                                                                            .company_name
                                                                    }} 
                                                                    <span v-if="outstanding.original_id !=null">( Version No {{ outstanding.version_key }}) </span>
                                                                    <span v-if="outstanding.children.length >0 && outstanding.original_id ==null"> ( Version No 1) </span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    outstanding.created_at
                                                                        | formatDate
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    outstanding.prop_value
                                                                }} {{outstanding.currency}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                 <div class="proposal-table__pagination" id="OutstandingProposal" v-show="paginationOutstanding">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getOutstandingProposal(outstandingCurrentPage-1)"
															v-bind:style= "[totalOutstandingRecords < 11 || outstandingCurrentPage == 1 ? { 'pointer-events': 'none' } : null ]">Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in outstandingRecordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === outstandingCurrentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getOutstandingProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getOutstandingProposal(outstandingCurrentPage+1)"
															 v-bind:style= "[ totalOutstandingRecords<11 || outstandingproposals_lastPage == outstandingCurrentPage ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="tab-pane fade"
                                        id="pills-accepted"
                                        role="tabpanel"
                                        aria-labelledby="pills-accepted-tab"
                                    >
                                        <div class="proposal-table">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-striped"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Company name
                                                            </th>
                                                            <th>
                                                                Created Date
                                                            </th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
															<tr>
															    <td colspan="3" class="text-center" v-if=" !accepteds.length"> No Data Found</td>
															</tr>
                                                        <tr
                                                            v-for="accepted in accepteds"
                                                            :key="
                                                                accepted.proposal_id
                                                            "
                                                        >
                                                            <td>
                                                                <span class="d-flex">
                                                                    {{
                                                                        accepted
                                                                            .proposal_with_company
                                                                            .company_name
                                                                    }} 
                                                                    <span v-if="accepted.original_id !=null">( Version No {{ accepted.version_key }}) </span>
                                                                    <span v-if="accepted.children.length >0 && accepted.original_id ==null"> ( Version No 1) </span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    accepted.created_at
                                                                        | formatDate
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    accepted.prop_value
                                                                }} {{accepted.currency}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                              <div class="proposal-table__pagination" id="AcceptedProposal" v-show="paginationAccepted">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getAcceptedProposal(acceptedCurrentPage-1)"
															v-bind:style= "[totalAcceptedRecords < 11 || acceptedCurrentPage == 1 ? { 'pointer-events': 'none' } : null ]">Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in acceptedRecordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === acceptedCurrentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getAcceptedProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getAcceptedProposal(acceptedCurrentPage+1)"
																 v-bind:style= "[ totalAcceptedRecords<11 || acceptedProposals_lastPage == acceptedCurrentPage  ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        class="tab-pane fade"
                                        id="pills-lost"
                                        role="tabpanel"
                                        aria-labelledby="pills-lost-tab"
                                    >
                                        <div class="proposal-table">
                                            <div class="table-responsive">
                                                <table
                                                    class="table table-striped"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Company name
                                                            </th>
                                                            <th>
                                                                Created Date
                                                            </th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="3" class="text-center" v-if=" !rejecteds.length"> No Data Found</td>
                                                        </tr>
                                                        <tr
                                                            v-for="rejected in rejecteds"
                                                            :key="
                                                                rejected.proposal_id
                                                            "
                                                        >
                                                            <td>
                                                                <span class="d-flex">
                                                                    {{
                                                                        rejected
                                                                            .proposal_with_company
                                                                            .company_name
                                                                    }} 
                                                                    <span v-if="rejected.original_id !=null">( Version No {{ rejected.version_key }}) </span>
                                                                    <span v-if="rejected.children.length >0 && rejected.original_id ==null"> ( Version No 1) </span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {{
                                                                    rejected.created_at
                                                                        | formatDate
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    rejected.prop_value
                                                                }} {{rejected.currency}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="proposal-table__pagination" id="RejectedProposal" v-show="pagination">
                                                <nav>
                                                   <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" @click.prevent="getRejectedProposal(currentPage-1)"
															v-bind:style= "[totalRecords < 11 || currentPage == 1 ? { 'pointer-events': 'none' } : null ]">Previous</a>
                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in recordLoop"
                                                            :key="item"
															v-bind:class="{'page-item':true, 'active':(item === currentPage-1)}">
                                                            <a class="page-link" href="#" @click.prevent="getRejectedProposal(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getRejectedProposal(currentPage+1)"
																 v-bind:style= "[totalRecords < 11 || rejectedProposals_lastPage == currentPage  ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dashboard-right col-md-4">
                    <div class="latest-template mb-4">
                        <span class="template-count">
                            {{template_count}}
                        </span>
                        <p>Latest Templates</p>
                        <a  href="javascript:void(0)" @click="click_on_template" class="chekout"
                            >Checkout Now</a
                        >
                    </div>
                    <div class="activity-board">
                        <div class="dash-inner h-auto">
                            <div
                                class="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3"
                            >
                                <h2 class="mb-0">Activity Board</h2>
                                <!--<a href="javascript:void(0);">View All</a>-->
                            </div>
                            <span class="date">{{
                                todaysDate.date | formatDate
                            }}</span>
                            <span
                                v-if="
                                    proposalsActivityBoard.length == 0
                                            &&
                                        companyActivityBoard.length == 0
                                "
                            >
                                No Recent Activity Found
                            </span>
                            <ul class="list-unstyled mb-0">
									<li v-for="activityBoard in proposalsActivityBoard">
                                      <div v-if=" activityBoard.create">
										<span  class="activity-time">{{ activityBoard.created_at | formatTime }}</span>
                                        <div class="activity-content">
                                            <p class="status" >
                                                Proposal Created For Company
                                            </p>
                                            <p>{{ activityBoard.proposal_with_company.company_name }} </p>
                                        </div>
                                      </div>
                                      <div v-if=" activityBoard.update">
										<span class="activity-time">{{ activityBoard.updated_at | formatTime }}</span>
                                        <div class="activity-content">
                                            <p class="status">
                                                Proposal Updated For Company
                                            </p>
                                            <p>{{ activityBoard.proposal_with_company.company_name }} </p>
                                        </div>
                                      </div>
                                      <div v-if="activityBoard.send == '1'">
										<span class="activity-time">{{ activityBoard.mail_date | formatTime }}</span>
                                            <div class="activity-content">
                                                <p class="status">
                                                    Proposal is sent
                                                </p>
                                                <p>{{ activityBoard.proposal_with_company.company_name }} </p>
                                            </div>
                                      </div>
                                      <div v-if="activityBoard.open">
                                            <span class="activity-time">{{ activityBoard.open_date | formatTime }}</span>
                                            <div class="activity-content">
                                                <p class="status">
                                                    Proposal is Opened
                                                </p>
                                                <p>{{activityBoard.opened_by}} ({{ activityBoard.proposal_with_company.company_name }}) </p>
                                            </div>
                                      </div>
                                      <div v-if=" activityBoard.read">
										<span class="activity-time">{{ activityBoard.read_date | formatTime }}</span>
                                            <div class="activity-content">
                                                <p class="status">
                                                    Proposal Read
                                                </p>
                                                <p>{{activityBoard.read_by}} ({{ activityBoard.proposal_with_company.company_name }}) </p>
                                            </div>
                                      </div>
                                      <div v-if=" activityBoard.accept">
										<span class="activity-time">{{ activityBoard.accepted_date | formatTime }}</span>
                                            <div class="activity-content">
                                                <p class="status">
                                                    Proposal is Accepted
                                                </p>
                                                <p>{{ activityBoard.proposal_with_company.company_name }} </p>
                                            </div>
                                      </div>
                                      <div v-if=" activityBoard.reject">
										 <span class="activity-time">{{ activityBoard.rejected_date | formatTime }}</span>
                                            <div class="activity-content">
                                                <p class="status">
                                                    Proposal Rejected
                                                </p>
                                                <p>{{ activityBoard.proposal_with_company.company_name }} </p>
                                            </div>
                                      </div>
									</li>
								</ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </main>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "../services/Api";

export default {
    name: "Home",
    data() {
        return {
            isLoading: false,
            username: "",
            user_firstname: '',
            proposalsShared: '',
            unassignedProposals: 0,
            conversionRate: 0.00,
            draftproposals:[],
            outstandingProposals: [],
            acceptedProposals: [],
            rejectedProposals: [],
            proposalsActivityBoard: [],
            companyActivityBoard: [],
            totalProposalOfUser: 0,
            todaysDate: '',
            search_box:'',
            lastProposalSent:[],
            lastProposalSentName:'',
            lastProposalSentProposalId:0,
            lastProposalSentId:0,
            totalRecords:0,
            totalDraftRecords:0,
            totalOutstandingRecords:0,
            totalAcceptedRecords:0,
            currentPage:1,
            draftCurrentPage:1,
            outstandingCurrentPage:1,
            acceptedCurrentPage:1,
            template_count:0,
            pagination : false,
			paginationAccepted: false,
			paginationOutstanding : false,
			paginationDraft : false,
            api_url:this.$api_url,
            draftproposals_lastPage:1,
				outstandingProposals_lastPage:1,
				acceptedProposals_lastPage:1,
				rejectedProposals_lastPage:1,
        };
    },
    computed: {
        // a computed getter
        profileData: function() {
            return this.$store.getters.getusername;
        },
        // start for searching proposals
        drafts(){
        return this.draftproposals.filter((draft) => {
            return (
                draft.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
            );
        });
        },
        outstandings(){
        return this.outstandingProposals.filter((outstanding) => {
            return (
                outstanding.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
            );
        });
        },
        accepteds(){
            return this.acceptedProposals.filter((accepted) => {
            return (
                accepted.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
            );
        });
        },

        rejecteds(){
            return this.rejectedProposals.filter((rejected) => {
            return (
                rejected.proposal_with_company.company_name.toLowerCase().includes(this.search_box.toLowerCase())
            );
        });
        },
        recordLoop(){
            return  [...Array(Math.ceil(this.totalRecords/10))].map((_, i) => i * 1);
        },
        draftRecordLoop(){
            return  [...Array(Math.ceil(this.totalDraftRecords/10))].map((_, i) => i * 1);
        },
        outstandingRecordLoop(){
            return  [...Array(Math.ceil(this.totalOutstandingRecords/10))].map((_, i) => i * 1);
        },
        acceptedRecordLoop(){
            return  [...Array(Math.ceil(this.totalAcceptedRecords/10))].map((_, i) => i * 1);
        },

    },
    watch: {
        profileData: {
            handler: function(value, oldVal) {
                this.username = _.cloneDeep(value);
            },
            deep: true
        }
    },
    mounted() {
        $( document ).ready(function() {
            var token =localStorage.getItem('authToken');
            if(token == null){
            document.getElementsByClassName('app-listing-field')[0].
            style.display = 'none';
            }
            else{
            document.getElementsByClassName('app-listing-field')[0].
            style.display = 'block';
            }
            });
        this.getDashbordData();
    },
    methods: {
        openSearch() {
            $(".proposals-search").addClass("active");
        },
        compare_date(date){
            var date1=new Date().toJSON().slice(0,10).replace(/-/g,'/');
            var date2=new Date(date).toJSON().slice(0,10).replace(/-/g,'/');
            if(date1 == date2){
                return true;
            }
            else{
                return false;
            }
        },
        closeSearch() {
            $(".proposals-search").removeClass("active");
            this.search_box ='';
        },
        generate_pdf(id){
            this.isLoading = true;
            ApiService.get("downloadpdf/"+id)
            .then(response => {
                var link=document.createElement('a');
                document.body.appendChild(link);
                link.href=this.api_url+'pdf/'+id+'.pdf';
                link.target='_blank';
                this.isLoading = false;
                link.click();
            });

        },
         getDashbordData() {
            this.isLoading = true;

            this.$store.dispatch("get_dashboard_data").then(response => {
                if (response.status) {
                    localStorage.setItem('user_firstname',this.$store.state.user_firstname);
                    localStorage.setItem('user_lastname',this.$store.state.user_lastname);
                    localStorage.setItem('user_emailaddress',this.$store.state.user_emailaddress);
                    this.user_firstname = this.$store.state.user_firstname,
                    this.proposalsShared= this.$store.state.proposalsShared;
                    this.draftproposals = this.$store.state.draftproposals.data;
                    this.unassignedProposals = this.$store.state.unassignedProposals;
                    this.outstandingProposals= this.$store.state.outstandingProposals.data;
                    this.acceptedProposals = this.$store.state.acceptedProposals.data;
                    this.rejectedProposals = this.$store.state.rejectedProposals.data;
                    this.totalRecords =  this.$store.state.rejectedProposals.total;
                    this.currentPage =  this.$store.state.rejectedProposals.current_page;
                    this.totalDraftRecords =  this.$store.state.draftproposals.total;
                    this.draftCurrentPage =  this.$store.state.draftproposals.current_page;
                    this.totalOutstandingRecords =  this.$store.state.outstandingProposals.total;
                    this.outstandingCurrentPage =  this.$store.state.outstandingProposals.current_page;
                    this.totalAcceptedRecords =  this.$store.state.acceptedProposals.total;
                    this.acceptedCurrentPage =  this.$store.state.acceptedProposals.current_page;
                    this.template_count = this.$store.state.template_pages.length;
                    this.proposalsActivityBoard= response.temp;
                    this.companyActivityBoard= this.$store.state.companyActivityBoard;
                    this.todaysDate= this.$store.state.todaysDate;
                    this.draftproposals_lastPage = this.$store.state.draftproposals.last_page;
					this.outstandingProposals_lastPage = this.$store.state.outstandingProposals.last_page;
					this.acceptedProposals_lastPage = this.$store.state.acceptedProposals.last_page;
					this.rejectedProposals_lastPage = this.$store.state.rejectedProposals.last_page;
                    var total = this.draftproposals.length+this.proposalsShared;
                    if(total!=0){
                        this.conversionRate = ((this.proposalsShared/total)*100);
                    }
                    else{
                        this.conversionRate = 0;
                    }
                    this.totalProposalOfUser = this.$store.state.totalProposalOfUser;
                    if(this.$store.state.lastProposalSent.length != 0){
                        this.lastProposalSent = this.$store.state.lastProposalSent;
                        this.lastProposalSentProposalId = this.$store.state.lastProposalSent.proposal_id;
                        this.lastProposalSentName = this.lastProposalSent.proposal_with_company.company_name;
                    }else{
                        console.log("null");
                    }

                    this.lastProposalSentId = this.lastProposalSent.proposal_id
                    if(this.totalRecords > 10 && this.totalRecords != 0 ){
							this.pagination = true
					}
                    if(this.totalAcceptedRecords > 10 && this.totalAcceptedRecords != 0){
                        this.paginationAccepted = true
                    }
                    if(this.totalOutstandingRecords > 10 && this.totalOutstandingRecords != 0){
                        this.paginationOutstanding = true
                    }
                    if(this.totalDraftRecords > 10 && this.totalDraftRecords != 0){
                        this.paginationDraft = true
                    }
                    this.isLoading = false;
                }
            });
        },
        getRejectedProposal(page){
            this.isLoading = true;
                    this.$store.dispatch("get_rejected_proposal",page)
                    .then(response => {
                if (response.status == "success") {
                    this.rejectedProposals = this.$store.state.rejectedProposals.data;
                        this.totalRecords =  this.$store.state.rejectedProposals.total;
                        this.currentPage =  this.$store.state.rejectedProposals.current_page;
						this.rejectedProposals_lastPage = this.$store.state.rejectedProposals.last_page;
                    this.isLoading = false;
                }
            });

        },
        getDraftProposal(page){
            this.isLoading = true;
					this.$store.dispatch("get_draft_proposal",page)
					.then(response => {
                if (response.status == "success") {
                     this.draftproposals = this.$store.state.draftproposals.data;
                     this.totalDraftRecords =  this.$store.state.draftproposals.total;
                     this.draftCurrentPage =  this.$store.state.draftproposals.current_page;
					this.draftproposals_lastPage = this.$store.state.draftproposals.last_page;
                    this.isLoading = false;
                }
            });

        },
        getOutstandingProposal(page){
            this.isLoading = true;
					this.$store.dispatch("get_outstanding_proposal",page)
					.then(response => {
                if (response.status == "success") {
                     this.outstandingProposals = this.$store.state.outstandingProposals.data;
                      this.totalOutstandingRecords =  this.$store.state.outstandingProposals.total;
                      this.outstandingCurrentPage =  this.$store.state.outstandingProposals.current_page;
					this.outstandingProposals_lastPage = this.$store.state.outstandingProposals.last_page;
                    this.isLoading = false;
                }
            });

        },
         getAcceptedProposal(page){
            this.isLoading = true;
					this.$store.dispatch("get_accepted_proposal",page)
					.then(response => {
                if (response.status == "success") {
                     this.acceptedProposals = this.$store.state.acceptedProposals.data;
                       this.totalAcceptedRecords =  this.$store.state.acceptedProposals.total;
                       this.acceptedCurrentPage =  this.$store.state.acceptedProposals.current_page;
						this.acceptedProposals_lastPage = this.$store.state.acceptedProposals.last_page;
                    this.isLoading = false;
                }
            });

        },
		add_proposal(){
            this.$store.commit('toStartNewProposal');
            this.$router.push({ path: '/proposal-step-1' });
        },
         click_on_template(){
            this.$router.push({ path: '/template' });
        },

    },
    components: {
        Sidebar,
        Loading
    }
};
</script>
