<template>
    <main>
        <sidebar></sidebar>
        <!-- my-profile -->
        <div class="my-profile inner-page-pd">
            <h2 class="main-title">
                Welcome <span>{{ user_firstname }}! </span>
            </h2>
            <div class="row">
                <div class="col-md-8 pr-0">
                    <div class="inner-page__content-box mb-4">
                        <h3 class="main-subtitle">My Profile</h3>
                        <div class="my-profile__content">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="img-box">
                                        <img
                                            src="@/assets/images/blank-user.png"
                                            class="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div
                                        class="d-flex flex-column justify-content-between h-100"
                                    >
                                        <div class="user-details">
                                            <h3>
                                                {{ user_firstname }}
                                                {{ user_lastname }}
                                            </h3>
                                            <span>{{$store.state.department}}</span>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <div class="user-details">
                                                    <h5>Email</h5>
                                                    <p>
                                                        {{ user_emailaddress }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="user-details">
                                                    <h5>Phone Number</h5>
                                                    <p>{{ user_phoneno }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="inner-page__content-box">
                        <div
                            class="top d-flex justify-content-between align-items-start"
                        >
                            <div>
                                <h3 class="main-subtitle">My Statistics</h3>
                                <p>
                                    A quick overview of how many and how fast
                                    your proposals are being signed. Run
                                    different searches and compare your
                                    conversion rate over different periods.
                                </p>
                            </div>
                            <button
                                type="button"
                                href="javascript:void(0)"
                                @click="generate_report()"
                                class="btn btn-primary"
                            >
                                View report
                            </button>
                        </div>
                        <div class="statistics">
                            <div class="statistics__content">
                                <span>{{ proposalsSent }}</span>
                                <label>proposals <br />Sent</label>
                            </div>
                            <div class="statistics__content">
                                <span>{{ signedProposalsCount }}</span>
                                <label>Proposals <br />Signed</label>
                            </div>
                            <div class="statistics__content">
                                <span
                                    >{{
                                        this.conversionRate.toFixed(0)
                                    }}%</span
                                >
                                <label>Conversion <br />Rate</label>
                            </div>
                            <!-- <div class="statistics__content">
<span>0</span>
<label>AVERAGE TIME <br>TO SIGN</label>
</div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-3 offset-md-1">
                    <div class="follow-steps__wrap">
                        <div class="follow-steps">
                            <p>
                                Here you see your profile details. You can view
                                your quick stat and also edit profile.
                            </p>
                            <p>If you have any query. please contact admin.</p>
                        </div>
                        <div class="img-box">
                            <img
                                src="@/assets/images/my-profile.svg"
                                class="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </main>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    name: "Profile",
    data() {
        return {
            user_firstname:'',
            user_lastname: '',
            user_emailaddress:'',
            user_phoneno: '',

            proposalsSent: 0,
            signedProposalsCount: 0,
            conversionRate: 0,
            isLoading: false
        };
    },
    mounted() {
        this.profileData();
    },
    methods: {
        generate_report() {
            let thisPointer = this;
            const today = new Date();
            var generateReportDetails = {
                to_date:
                    today.getFullYear() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getDate(),
                from_date: moment(today.setMonth(today.getMonth() - 1)).format(
                    "YYYY-MM-DD"
                )
            };
            thisPointer.isLoading = true;
            thisPointer.$store
                .dispatch("generateReport", generateReportDetails)
                .then(response => {
                    if (response.status == "success") {
                        thisPointer.$router.push({ path: '/viewreport' });
                        thisPointer.isLoading = false;
                    }
                });
        },
        profileData() {
            this.isLoading = true;
            this.$store.dispatch("get_dashboard_data").then(response => {
                if (response.status) {
                        this.proposalsSent= this.$store.state.proposalsSent;
                        this.signedProposalsCount= this.$store.state.signedProposalsCount;
                         if(this.signedProposalsCount !=0 && this.proposalsSent !=0){
                            this.conversionRate = parseInt(( this.$store.state.signedProposalsCount / this.$store.state.proposalsSent)*100);
                        }
                        else{
                            this.conversionRate = 0;
                        }
                        this.user_firstname = this.$store.state.user_firstname;
                        this.user_lastname = this.$store.state.user_lastname;
                        this.user_emailaddress= this.$store.state.user_emailaddress;
                        this.user_phoneno= this.$store.state.user_phoneno;
                        this.isLoading = false;
								}
						});
					},
    },
    components: {
        Sidebar,
        Loading
    }
};
</script>
