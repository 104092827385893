<template>
	<main>
		<sidebar></sidebar>
		<!-- proposal-form -->
		<div class="create-proposal">
			<div class="create-proposal__top d-flex align-items-center justify-content-between">
				<div>
					<h2>Create Proposal</h2>
					<p v-if="this.$store.state.totalProposalOfUser == 0">Send your 1st Proposal</p>
				</div>
			</div>
			<div class="create-proposal__box">
				<div class="create-proposal__box-inner">
					<form>
						<div class="create-proposal__form-wrap">
							<div class="row">
								<div class="col-md-8">
									<div class="create-proposal__form send-proposal-box">
										<h2>Send Proposal</h2>
										<div class="row">
											<div class="col-12">
												<label>This Proposal will come from</label>
												<div class="d-flex align-items-center user-details">
													<div class="name">
														<img src="@/assets/images/icons/user.svg" class="img-fluid">
														<span>{{ $store.state.user_firstname }}</span>
													</div>
													<div class="email">
														<img src="@/assets/images/icons/mail.svg" class="img-fluid">
														<span>{{ $store.state.user_emailaddress }}</span>
													</div>
												</div>
											</div>
											<div class="col-12" v-for="(input,k) in $v.information.$each.$iter" :key="k">
												<div class="row" :class="{ 'form-group--error': input.$error }">
													<div class="col-md-3">
														<div class="form-group">
															<label for="first-name" v-show="k == 0" >First Name</label>
															<input type="text" v-model.trim="input.first_name.$model" class="form-control" :class="{ 'is-invalid': submitted && input.first_name.$error }"  id="first-name" placeholder="First Name">
													 <div v-if="input.first_name.$error && submitted " class="invalid-feedback">
															<span v-if="!(input.first_name.required)">First Name Is Requrired</span>
                            </div>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label for="last-name" v-show="k == 0">Last Name</label>
															<input type="text" v-model.trim="input.last_name.$model" class="form-control" :class="{ 'is-invalid': submitted && input.last_name.$error }" id="last-name" placeholder="Last Name">
															<div v-if="input.last_name.$error && submitted " class="invalid-feedback">
																<span v-if="!(input.last_name.required)">Last Name Is Requrired</span>
                            	</div>
														</div>
													</div>
													<div class="col-md-5">
														<div class="form-group">
															<label for="email" v-show="k == 0">Email</label>
															<input style="text-transform:none" type="text" v-model.trim="input.email.$model" class="form-control" :class="{ 'is-invalid': submitted && input.email.$error }" id="email" placeholder="Email">
															 <div v-if="input.email.$error && submitted " class="invalid-feedback">
																	<span v-if="!(input.email.required)">First Name Is Requrired</span>
                            	</div>
														</div>
													</div>
													<div class="col-md-1">
														<div class="close-btn d-flex h-100 pb-4">
															<span class="align-self-end" @click="removeRecipient(k)" v-show="k > 0" style="font-size: 20px;">&times;</span>
														</div>
													</div>
												</div>

												<button type="button" @click="addRecipient(k)"  class="btn btn-outline-primary" v-show="k == information.length-1">Add Another recipient</button>
											</div>

											<div class="col-12 mt-4">
												<label>Write your mail</label>
												<div class="form-group">
													<label for="email-subject">Email Subject</label>
													<input type="text" v-model.trim="email_subject" class="form-control" :class="{ 'is-invalid': submitted && $v.email_subject.$error }" id="email-subject" placeholder="Email Subject">
													<div v-if="submitted && $v.email_subject.$error " class="invalid-feedback">
                  	            <span v-if="!$v.email_subject.required">Proposal Value is required</span>
                            </div>
												</div>
											</div>
											<div class="col-12 mb-3">
												<div class="form-group">
													<label for="">How your email will look</label>
													<div style="padding:0" class="email-box">
                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="background-color: #fff; max-width: 600px;">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="padding: 50px 30px 10px 30px; text-align: center;">
                                                                            <img src="@/assets/images/ProposalLogo.svg" alt="" style="max-width: 150px; height: auto; margin-bottom: 20px;">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding: 20px 30px; line-height: 1.5; text-align: center; border: 1px solid #ccc; border-radius: 10px;">
                                                                            <img src="@/assets/images/mail-closed.svg" alt="" style="max-width: 40px; height: auto;">
                                                                            <p style="margin-bottom: 20px; color: #848484;">{{$store.state.user_firstname}} {{$store.state.user_lastname}} From Neosoft Technologies Has Shared You Proposal . Please Find Below Link to Open the proposal.</p>
                                                                            <a v-bind:href="'https://be.proposal.neosofttech.in/Cover-page/'+ proposal_id +'/0' " target="_blank" title="" style="display: block; padding: 10px 20px; color: #fff; background-color: #000; text-decoration: none; border-radius: 10px;">Read Your Proposal</a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-3 offset-md-1">
									<div class="form-progress">
										<h3>Step 6 : Send Proposal</h3>
										<p>Proposal ready to send</p>
									</div>
									<div class="follow-steps__wrap">
										<div class="follow-steps">
											<p>Follow the steps to build your proposal with a cover, template and what you’re selling.</p>
											<p>You can edit all of this later so just put the basics in to get started.</p>
										</div>
										<div class="img-box">
											<img src="@/assets/images/create-proposal.svg" class="img-fluid">
										</div>
									</div>
								</div>
								<div class="create-proposal__form-actions" :class="{ 'form-group--error': $v.information.$error }">
									<a href="javascript:void(0)" @click="click_on_proposal_step5" class="btn btn-outline-primary" id="prev">Back</a>
									<a href="javascript:void(0);" @click="next_page()" class="btn btn-primary" id="next">Next</a>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
        <loading :active.sync="isLoading"></loading>
	</main>
</template>
<script>
	import ApiService from "../../services/Api";
	import Sidebar from '@/components/Sidebar.vue';
	import { required, email } from 'vuelidate/lib/validators'
    import Loading from "vue-loading-overlay";
    export default {
        name: "Step6",
        data () {
            return {
				information:[{
                first_name:'',
                last_name:'',
				email:'',
				}],
                email_subject:'',
				submitted: false,
				isLoading: false,
                proposal_id:this.$store.state.proposal_id
            }
				},
				validations:{
					information:{
						required,
						  $each: {
								first_name:{ required },
								last_name:{ required },
								email:{ required, email },
							}
							},
					email_subject:{ required },
				},
        methods: {
                next_page(){
                let thisPointer = this;
                thisPointer.submitted = true;
                thisPointer.$v.information.$touch();
                thisPointer.$v.$touch();
                if(!thisPointer.$v.$invalid) {
                    var link = thisPointer.$api_url+'Cover-page/';
                    var data_information = thisPointer.information;
                    var Data = {'proposal_id':thisPointer.$store.state.proposal_id,'information':data_information,'url':link,'email_subject':thisPointer.email_subject,'username':thisPointer.$store.state.user_firstname +' '+ thisPointer.$store.state.user_lastname};
                    thisPointer.isLoading= true;
                    ApiService.post('sendmail',Data)
                    .then(responce =>{
                        if(responce.status){
                            thisPointer.$router.push("/success");
                            thisPointer.isLoading= false;
                        }
                    })
                }
								},

						addRecipient(){
							this.information.push({
								first_name:'',
                                last_name:'',
								email:'',
							})
						},
						removeRecipient(index){
							this.information.splice(index,1)
						},
						click_on_proposal_step5(){
							 this.$router.push({ path: '/proposal-step-5' });
						},
                        edit_proposal_recipient(){
								var proposal_id = this.$store.state.update_proposal_id;
								let thisPointer = this;
								thisPointer.isLoading= true;
								ApiService.get('get_data_recipient/' + proposal_id)
								.then(responce =>{
								if(responce.status){
                                    var data = responce.data.proposal_receipent;
                                    if(data.length > 0){
                                        var length = data.length;
                                        thisPointer.information = [];
                                        for( var i=0; i<length;i++ ){
                                            thisPointer.information.push({
                                            first_name:responce.data.proposal_receipent[i].firstname,
                                            last_name:responce.data.proposal_receipent[i].lastname,
                                            email:responce.data.proposal_receipent[i].email,
                                        })
                                        }
                                    }
                                    thisPointer.isLoading= false;
                                }
								})

						}

        },
        mounted() {
				},
        components:{
        	Sidebar,
            Loading
        }
    }
</script>
