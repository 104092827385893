<template>
	<div class="cover-page">
		<div class="container">
			<div class="cover-page__box">
				<div class="cover-page__box-inner">
					<div class="cover-page__top">
						<a href="javascript:void(0)" @click="close_preview" class="back-link">Back to Proposal</a>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="cover-preview">
								<img src="@/assets/images/neosoft.svg" class="img-fluid">
								<div class="cover-preview__box">
									<div class="preview-info">
										<h5>Proposal For</h5>
										<p class="mb-0 proposal-for">{{compnay_name}}</p>
										<div class="row mt-4">
											<div class="col-md-6">
												<label class="preview-label">Written By</label>
												<p>{{written_by}}</p>
											</div>
											<div class="col-md-6">
												<label class="preview-label">For</label>
												<p>{{written_for}}</p>
											</div>
										</div>
									</div>
								</div>
								<button type="button" class="btn btn-primary">{{button_text}}</button>
							</div>
						</div>
					</div>
					<img src="@/assets/images/cover-page-img.svg" class="img-fluid cover-img">
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
        name: "PreviewCover",
        data () {
            return {
				written_by:'',
				written_for:'',
				compnay_name:'',
                button_text:'',
            }
        },
        methods: {
            ShowPreviewPage: function (fetchData) {
				this.written_by = fetchData.WrittenBy;
				this.written_for = fetchData.WrittenFor;
				this.compnay_name = fetchData.Company_name;
				this.button_text = fetchData.Button_text;
			},
            close_preview: function(){
                this.$root.$emit('HidePreviewPage');
            }
        },
        mounted(){
            this.$root.$on('ShowPreviewPage', this.ShowPreviewPage);
        },
        components:{

        }
    }
</script>
