import axios from "axios";

import AuthToken from "./Auth";

// let API_URL = 'http://localhost/digital-proposal/core/public/';
let API_URL = 'https://be.proposal.neosofttech.in/';
// let API_URL = 'http://internal-projects.php-dev.in/digital-proposal/core/public/';
const axiosApi = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default {
    getApiUrl() {
        return this.API_URL;
    },
    get(url, passPayload = 0) {
        this.addHeaders();
        return axiosApi.get(url).then(respose => {
            return respose;
        });
    },
    post(url, payload, passPayload = 0) {
        this.addHeaders();
        return axiosApi.post(url, payload);
    },
    upload(url, payload, passPayload = 0) {
        url = API_URL + url;
        let config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return axiosApi.post(url, config).then(respose => {
            return respose;
        });

    },
    addpayloadtourl(url) {
        return url;
    },
    addHeaders() {
        axiosApi.defaults.headers.common['Authorization'] = AuthToken.getToken();
    }
}