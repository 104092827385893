<template>
	<main>
		<sidebar></sidebar>
		<!-- proposal-form -->
		<div class="create-proposal">
			<div class="create-proposal__top d-flex align-items-center justify-content-between">
				<div>
					<h2>Create Proposal</h2>
					<p v-if="this.$store.state.totalProposalOfUser == 0">Send your 1st Proposal</p>
				</div>
			</div>
			<div class="create-proposal__box">
				<div class="create-proposal__box-inner">
					<form>
						<div class="create-proposal__form-wrap">
							<div class="row">
								<div class="col-md-8">
									<div class="create-proposal__form basic-details-box">
										<h2>BASIC DETAILS</h2>
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<div class="select-box" v-if="user_choise == 1">
													    <label for="company">Which company do you want to send to?</label>
														<select v-model="selected_company"  class="form-control" :class="{ 'is-invalid': submitted && $v.selected_company.$error }" id="company">
															<option selected disabled value="">Select the company you want to send it.</option>
															<option v-for="data in company_list" :value="data.company_id" :key="data.company_id">{{data.company_name}}</option>
														</select>
														<div v-if="submitted && $v.selected_company.$error " class="invalid-feedback">
                                                            <span v-if="!$v.selected_company.$required ">Company is required</span>
                                                        </div>
													</div>
                                                    <div v-if="user_choise == 0">
                                                        <label for="company">Which company do you want to send to?</label>
                                                         <input v-model="company_name"  type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.company_name.$error }" id="company" placeholder="Enter Company Name">
                                                        <div v-if="submitted && ($v.company_name.$error || user_choise == 0 )" class="invalid-feedback">
                                                                <span v-if="!($v.company_name.$required )">Company is required</span>
                                                        </div>
                                                    </div>

												</div>
											</div>
											<div class="col-12">
												<div class="form-group">
													<div class="select-box">
														<label for="currency">How are you going to charge?</label>
														<select v-model="currency" class="form-control" :class="{ 'is-invalid': submitted && $v.currency.$error }" id="currency">
																<option selected disabled value="">Select the currency</option>
																<option value="INR" >INR</option>
																<option value="USD" >USD</option>
														</select>
														 <div v-if="submitted && $v.currency.$error " class="invalid-feedback">
                              <span v-if="!$v.currency.$required ">Currency is required</span>
                          	</div>
													</div>

												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label for="">Is tax applicable on the price</label>
													<div class="radio-btn">
														<label>
															<input type="radio" v-model="is_tax" name="price" hidden value="1">
															<span>Yes</span>
														</label>
														<label>
															<input type="radio" v-model="is_tax" name="price" hidden value="0">
															<span>No</span>
														</label>
													</div>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label for="proposal-value">Proposal Value</label>
													<input type="number" v-model="prop_value" class="form-control" :class="{ 'is-invalid': submitted && $v.prop_value.$error }"  id="proposal-value" placeholder="Enter Proposal Value">
													 <div v-if="submitted && $v.prop_value.$error " class="invalid-feedback">
                  	            <span v-if="!$v.prop_value.$required ">Proposal Value is required</span>
                            </div>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label for="tax">What is it TAX called?</label>
													<input :disabled="is_tax == 0" v-model="tax_type" :class="{ 'is-invalid': submitted && $v.tax_type.$error }" type="text" class="form-control" id="tax" placeholder="">
														<div v-if="submitted  && $v.tax_type.$error  " class="invalid-feedback">
																	<span v-if="!$v.tax_type.$required ">Tax Type is required</span>
																</div>
														</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label for="percentage">What’s the % charged?</label>
													<input :disabled="is_tax == 0" v-model="tax_percent" :class="{ 'is-invalid': submitted && $v.tax_percent.$error }" type="text" class="form-control" id="percentage" placeholder="">
                                                    <div v-if="submitted  && $v.tax_percent.$error " class="invalid-feedback">
                                                        <span v-if="!$v.tax_percent.$required ">Tax Percent is required</span>
                                                    </div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-3 offset-md-1">
									<div class="form-progress">
										<h3>Step 2 : Basic Details</h3>
										<p>Next : Cover Page</p>
									</div>
									<div class="follow-steps__wrap">
										<div class="follow-steps">
											<p>Follow the steps to build your proposal with a cover, template and what you’re selling.</p>
											<p>You can edit all of this later so just put the basics in to get started.</p>
										</div>
										<div class="img-box">
											<img src="@/assets/images/create-proposal.svg" class="img-fluid">
										</div>
									</div>
								</div>
								<div class="create-proposal__form-actions">
									<a href="javascript:void(0)" v-if="this.$store.state.updating_basic_detail == 1" type="submit" @click="next_page(1)" class="btn btn-outline-primary">Save & Exit</a>
									<a v-else href="javascript:void(0)" @click="click_on_proposal_step_1"  class="btn btn-outline-primary" id="prev">Back</a>
									<a href="javascript:void(0)" @click="next_page(0)" class="btn btn-primary" id="next">Next</a>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
  <loading :active.sync="isLoading"></loading>
	</main>
</template>
<script>
	import ApiService from "../../services/Api";
	import Sidebar from '@/components/Sidebar.vue';
		import { required,requiredIf } from 'vuelidate/lib/validators'
		import Loading from 'vue-loading-overlay';
		import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: "Step2",
        data () {
            return {
                is_company:0,
                is_tax:0,
                tax_type:'',
                tax_percent:'',
                currency:'',
                user_choise:0,
                selected_company:'',
                company_name:'',
                company_list:{},
                submitted: false,
                isLoading: false,
                prop_value:'',
                currency_user:'',
                updating_basic_detail:0,
            }
        },
        validations: {
            currency: { required },
            company_name: {
            	required : requiredIf(function () {
			        return this.user_choise == 0
		      	})
            },
            selected_company:{
            	required : requiredIf(function () {
			        return this.user_choise == 1
		      	})
						},
						prop_value:{ required },
						tax_type: {
            	required : requiredIf(function () {
			        return this.is_tax == 1
		      	})
						},
						tax_percent: {
            	required : requiredIf(function () {
			        return this.is_tax == 1
		      	})
            },
        },
        methods: {
            get_company(){
                let thisPointer = this;
								thisPointer.isLoading = true;
                thisPointer.$store.dispatch('get_company').then(function (data) {
                        if(data.status){
                            thisPointer.is_company =thisPointer.$store.getters.getisusercompany;
                            thisPointer.company_list =thisPointer.$store.state.usercompanylist;
                            thisPointer.user_choise =thisPointer.$store.state.checkuserchoise;
							thisPointer.isLoading = false;
                        }
                    });
            },
			click_on_proposal_step_1(){
				this.$router.push({ path: '/proposal-step-1' });
			},
            next_page(value){
                let thisPointer = this;
                thisPointer.submitted = true;
                thisPointer.$v.$touch();
                if(!thisPointer.$v.$invalid) {
                    if(this.$store.state.step2_is_working == 1){
                        var proposal_id = thisPointer.$store.state.proposal_id;
                        var company_id = thisPointer.$store.state.company_id;
                    }
                    else if(thisPointer.$store.state.updating_basic_detail == 1){
                        var proposal_id = thisPointer.$store.state.proposalbasicdetail[0].proposal_id;
                        var company_id = thisPointer.$store.state.proposalbasicdetail[0].company_id;
                    }
                    else{
                        var proposal_id = 0;
                        var company_id = 0;
                    }

                    if(thisPointer.user_choise == 0){
                            var Company={'updating':thisPointer.updating_basic_detail,'proposal_id': proposal_id,'company_id':company_id,
                            'company':thisPointer.company_name,'currency':thisPointer.currency, 'prop_value':thisPointer.prop_value,
                            'is_tax':thisPointer.is_tax,'tax_type':thisPointer.tax_type,'tax_percent':thisPointer.tax_percent,'is_company':1};
                    }
                    else{
                            var Company={'updating':thisPointer.updating_basic_detail,'proposal_id': proposal_id,'company_id':company_id,
                            'company':thisPointer.selected_company,'prop_value':thisPointer.prop_value,
                            'currency':thisPointer.currency,'is_tax':thisPointer.is_tax,'tax_type':thisPointer.tax_type,'tax_percent':thisPointer.tax_percent,'is_company':0};
                        }
                        thisPointer.isLoading = true;
                        thisPointer.$store.dispatch('store_company',Company)
                        .then(function (data) {
                            if(data.status){
                                if(value == 1){
                                    thisPointer.$router.push({ path: '/proposal' });
                                }else{
                                    thisPointer.$router.push({ path: '/proposal-step-3' });
                                }
								thisPointer.isLoading = false;
                            }
                        });
										}
						},

						on_press_oF_back(){
                            let thisPointer = this;
							var id = thisPointer.$store.state.proposal_id;
							thisPointer.isLoading = true;
							ApiService.post('get_data_of_proposal_on_back_step2/'+id)
							.then(responce =>{
								if(responce.status){
									thisPointer.company_name = responce.data.prposal_data[0].proposal_with_company.company_name;
									thisPointer.prop_value = responce.data.prposal_data[0].prop_value;
									thisPointer.currency = responce.data.prposal_data[0].currency;
									thisPointer.selected_company = responce.data.prposal_data[0].proposal_with_company.company_id;
									thisPointer.is_tax = responce.data.prposal_data[0].is_tax;
									thisPointer.tax_type = responce.data.prposal_data[0].tax_type;
									thisPointer.tax_percent = responce.data.prposal_data[0].tax_percentage;
									thisPointer.isLoading = false;

								}
							})
						},
						edit_basic_detail(){
                            this.company_name = this.$store.state.proposalbasicdetail[0].proposal_with_company.company_name;
                            this.prop_value = this.$store.state.proposalbasicdetail[0].prop_value;
                            this.currency = this.$store.state.proposalbasicdetail[0].currency;
                            this.is_tax = this.$store.state.proposalbasicdetail[0].is_tax;
                            this.tax_type = this.$store.state.proposalbasicdetail[0].tax_type;
                            this.tax_percent = this.$store.state.proposalbasicdetail[0].tax_percentage;
						}
        },
        mounted:function(){
			this.get_company();
			if(this.$store.state.step2_is_working == 1){
			this.on_press_oF_back();
			}
			if(this.$store.state.updating_basic_detail == 1){
			this.edit_basic_detail();
			}
			this.updating_basic_detail = this.$store.state.updating_basic_detail;
		},
        components:{
					Sidebar,
					Loading,
        }
    }
</script>
