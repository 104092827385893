<template>
	<main>
		<sidebar></sidebar>
		<!-- proposal-form -->
		<div class="create-proposal">
			<div class="create-proposal__top d-flex align-items-center justify-content-between">
				<div>
					<h2>Company Details</h2>
					<p>Add company information</p>
				</div>
			</div>
			<div class="create-proposal__box">
				<div class="create-proposal__box-inner">
					<form @submit.prevent="submitform">
						<div class="create-proposal__form-wrap">
							<div class="row">
								<div class="col-md-8">
									<div class="create-proposal__form add-company">
										<h2>Add Company</h2>
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<label for="company-name">Company Name</label>
													<input type="text" v-model.trim="company_name" class="form-control" :class="{ 'is-invalid': submitted && $v.company_name.$error }" id="company-name" placeholder="Enter Company Name">
													 <div v-if="submitted && $v.company_name.$error" class="invalid-feedback">
															<span v-if="!$v.company_name.$required">Name is required</span>
													 </div>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group">
													<label for="company-address">Address</label>
													<textarea v-model.trim="company_address" class="form-control" :class="{ 'is-invalid': submitted && $v.company_address.$error }" rows="3" id="company-address"></textarea>
													 <div v-if="submitted && $v.company_address.$error " class="invalid-feedback">
															<span v-if="!$v.company_address.$required ">Address is required</span>
													 </div>
												</div>
											</div>
											<div class="col-12 mb-3">
												<div class="form-group">
													<label for="contact-number">Contact Number</label>
													<input type="number" v-model.trim="contact_number" class="form-control" :class="{ 'is-invalid': submitted && $v.contact_number.$error }" id="contact-number" placeholder="">
													 <div v-if="submitted && $v.contact_number.$error " class="invalid-feedback">
																<span v-if="!$v.contact_number.$required ">Contact Number is required</span>
													 </div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-3 offset-md-1">
									<div class="follow-steps__wrap">
										<div class="follow-steps">
											<p>Follow the steps to build your proposal with a cover, template and what you’re selling.</p>
											<p>You can edit all of this later so just put the basics in to get started.</p>
										</div>
										<div class="img-box">
											<img src="@/assets/images/create-proposal.svg" class="img-fluid">
										</div>
									</div>
								</div>
								<div class="create-proposal__form-actions">
									<button  type="submit" class="btn btn-primary" id="next">Save</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<loading :active.sync="isLoading"></loading>
	</main>
</template>
<script>
	import ApiService from "../services/Api";
	import AuthToken from '../services/Auth';
	import Sidebar from '@/components/Sidebar.vue';
	import { required } from 'vuelidate/lib/validators'
	import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: "Company",
        data () {
            return {
                company_name:'',
                company_address:'',
								contact_number:'',
								submitted: false,
								isLoading: false,
            }
				},
				validations:{
					company_name:{ required },
					company_address:{ required },
					contact_number:{ required}
				},
        methods: {
					submitform(){
						let thisPointer = this;
						thisPointer.submitted = true;
						thisPointer.$v.$touch();
						if(!thisPointer.$v.$invalid){
							thisPointer.isLoading = true;
                  ApiService.post('companydetails',{
                      company_name : thisPointer.company_name,
                      company_address: thisPointer.company_address,
                      contact_number: thisPointer.contact_number
                  }).then(function(responce){
                      if(responce.data.status)
                      {
						  thisPointer.isLoading = false;
                          thisPointer.$noty.success("Success : Company Created")
                          thisPointer.company_name = '';
                          thisPointer.company_address = '';
                          thisPointer.contact_number = '';
                          thisPointer.submitted = false;
                          thisPointer.$router.push({ path: '/company' });
                      }
                      else{
                          thisPointer.$noty.error("Something went wrong");
                      }
                  });
						}
					},
        },
        created: function(){

            },
        components:{
					Sidebar,
					Loading
        }
    }
</script>
