<template>
    <main>
        <sidebar></sidebar>
        <!-- proposal-form -->
        <div v-if="!show_preview" class="create-proposal">
            <div
                class="create-proposal__top d-flex align-items-center justify-content-between"
            >
                <div>
                    <h2>Create Proposal</h2>
                    <p v-if="this.$store.state.totalProposalOfUser == 0">Send your 1st Proposal</p>
                </div>
                <button
                    type="submit"
                    @click="next_page(1)"
                    class="btn btn-primary"
                >
                    Save & Exit
                </button>
            </div>
            <div class="create-proposal__box">
                <div class="create-proposal__box-inner">
                    <form>
                        <div class="create-proposal__form-wrap">
                            <div class="row">
                                <div class="col-md-8">
                                    <div
                                        class="create-proposal__form cover-page-box"
                                    >
                                        <h2>Cover Page</h2>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="company-name"
                                                        >Proposal For</label
                                                    >
                                                    <input
                                                        readonly
                                                        type="text"
                                                        :value="company_name"
                                                        class="form-control"
                                                        id="company-name"
                                                        placeholder="Enter company name"
                                                        :v-model="company_name"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="written-by"
                                                        >Written By</label
                                                    >
                                                    <input v-model.trim="$v.written_by.$model" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.written_by.$error }" id="written-by" placeholder="Enter name" />
																										<div v-if="submitted && $v.written_by.$error " class="invalid-feedback">
	                                                    <span v-if="!$v.written_by.required " class="text-danger">Written By Is Requrired</span>
                          													</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="for">For</label>
                                                    <input type="text" v-model.trim="$v.written_for.$model" class="form-control" :class="{ 'is-invalid': submitted && $v.written_for.$error }" id="for" placeholder="Enter name"/>
																										<div v-if="submitted && $v.written_for.$error " class="invalid-feedback">
		                                                    <span v-if="!$v.written_for.required" class="text-danger" >Written For Is  Requrired</span>
																										</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
																									<label for="btn-text">Button Text</label>
																									<input type="text" v-model="$v.button_text.$model" class="form-control" :class="{ 'is-invalid': submitted && $v.button_text.$error }" id="btn-text" placeholder="Enter Button Text"/>
																									<div v-if="submitted && $v.button_text.$error " class="invalid-feedback">
																										<span v-if="!$v.button_text.required " class="text-danger" >Button Text Is Requrired</span>
																									</div>
																								</div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <a
                                                        @click="preview_page()"
                                                        class="preview-page-link"
                                                        >Preview Cover Page</a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 offset-md-1">
                                    <div class="form-progress">
                                        <h3>Step 3 : Cover Page</h3>
                                        <p>Next : Select Template</p>
                                    </div>
                                    <div class="follow-steps__wrap">
                                        <div class="follow-steps">
                                            <p>
                                                Follow the steps to build your
                                                proposal with a cover, template
                                                and what you’re selling.
                                            </p>
                                            <p>
                                                You can edit all of this later
                                                so just put the basics in to get
                                                started.
                                            </p>
                                        </div>
                                        <div class="img-box">
                                            <img
                                                src="@/assets/images/create-proposal.svg"
                                                class="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="create-proposal__form-actions">
                                    <a href="javascript:void(0)" @click="click_on_proposal_step2" class="btn btn-outline-primary" id="prev">Back</a>
                                    <a
                                        href="javascript:void(0)"
                                        @click="next_page(0)"
                                        class="btn btn-primary"
                                        id="next"
                                        >Next</a
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="show_preview" class="new-cover">

			<div class="container">
				<div class="new-cover__box">
					<div class="new-cover__top">
						<a href="javascript:void(0)" @click="HidePreviewPage" class="back-link">&#8672; Back to Proposal</a>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="cover-preview text-center">
								<img src="@/assets/images/neosoft.svg" class="img-fluid">
								<div class="preview-info text-white mt-5 mb-5">
									<h4>Proposal for <span>{{ company_name }}</span></h4>
									<h5>Written by <span>{{ written_by }}</span> for <span>{{ written_for }}</span></h5>
								</div>
								<button type="button" class="btn btn-primary">{{ button_text }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </main>
</template>
<script>
import ApiService from "../../services/Api";
import Sidebar from "@/components/Sidebar.vue";
import { required } from "vuelidate/lib/validators";
import PreviewPage from "@/views/proposal/previewcover.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    name: "Step3",
    data() {
        return {
            written_for: "",
            written_by: "",
            button_text: "",
            company_name: "",
            submitted: false,
            show_preview: false,
            isLoading: false,
            cover_id:0,
            company_id:0,
            proposal_id:0,
        };
    },
    validations: {
        written_by: { required },
        written_for: { required },
        button_text: { required }
    },
    methods: {
        preview_page() {
            let thisPointer = this;
            thisPointer.show_preview = true;
            thisPointer.company_name = thisPointer.$store.state.company_name;
        },
        click_on_proposal_step2(){
            	this.$router.push({ path: '/proposal-step-2' });
        },
        next_page(value) {
            let thisPointer = this;
			thisPointer.submitted = true;
			thisPointer.$v.$touch();
            if (!thisPointer.$v.$invalid) {
                if (this.$store.state.step3_is_working == 1) {
                    var cover_id = this.$store.state.cover_id;
                    var proposal_id = this.proposal_id;
                    var company_id = this.company_id;
                }
                else if(this.$store.state.updating_basic_detail == 1){
                    var cover_id = this.cover_id;
                    var company_id = this.$store.state.cover_detail[0].company_id;
                    var proposal_id = this.$store.state.cover_detail[0].proposal_id;

                }
                else {
                    var cover_id = 0;
                    var company_id = this.$store.state.company_id ;
                    var proposal_id = this.$store.state.proposal_id;
                }
                var Cover = {
                    cover_id: cover_id,
                    company: company_id,
                    proposal_id: proposal_id,
                    written_by: thisPointer.written_by,
                    written_for: thisPointer.written_for,
                    button_text: thisPointer.button_text
                };
                thisPointer.isLoading = true;
                thisPointer.$store
                    .dispatch("store_coverpage", Cover)
                    .then(function(data) {
                        if (data.status) {
                            if(value == 1){
                                thisPointer.$router.push({ path: '/proposal' });
                            }else{
                                if(thisPointer.$store.state.updating_basic_detail == 1 && thisPointer.$store.state.template_id != 0){
                                thisPointer.$router.push({ path: '/proposal-step-5' });
                                }else{
                                thisPointer.$router.push({ path: '/proposal-step-4' });
                                }
                            }
                            thisPointer.isLoading = false;
                        }
                    });
            }
        },

        on_press_oF_back() {
            var id = this.$store.state.cover_id;
            ApiService.post("get_data_of_cover_on_back_step3/" + id).then(
                response => {
                    if (response.status) {
                        this.proposal_id = response.data.cover_data[0].proposal_id;
                        this.company_id = response.data.cover_data[0].company_id;
                        this.written_by = response.data.cover_data[0].written_by;
                        this.written_for = response.data.cover_data[0].written_for;
                        this.button_text = response.data.cover_data[0].button_text;
                    }
                }
            );
        },
        get_data_updateing_step2(){
           var proposal_id = this.$store.state.update_proposal_id;
           this.isLoading = true;
           this.$store.dispatch('get_data_of_cover',proposal_id)
             .then((response)=>{
				if(response.status == 'success'){
                    this.company_name = this.$store.state.cover_detail[0].cover_with_company.company_name;
					this.cover_id = this.$store.state.cover_detail[0].id;
                    this.written_by = this.$store.state.cover_detail[0].written_by;
                    this.written_for = this.$store.state.cover_detail[0].written_for;
                    this.button_text = this.$store.state.cover_detail[0].button_text;
                    this.isLoading = false;
				}
			})
        },
        edit_cover_detail(){
            this.company_name = this.$store.state.cover_detail[0].cover_with_company.company_name;
            this.cover_id = this.$store.state.cover_detail[0].id;
            this.written_by = this.$store.state.cover_detail[0].written_by;
            this.written_for = this.$store.state.cover_detail[0].written_for;
            this.button_text = this.$store.state.cover_detail[0].button_text;
        },
        HidePreviewPage() {
            this.show_preview = false;
        }
    },
    mounted() {
        this.company_name = this.$store.state.company_name;
        if (this.$store.state.step3_is_working == 1) {
            this.on_press_oF_back();
        }
        if(this.$store.state.updating_basic_detail == 1){
            this.get_data_updateing_step2();
        }
    },
    components: {
        Sidebar,
        PreviewPage,
        Loading
    }
};
</script>
