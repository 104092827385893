<template>
    <div class="login">
		<div class="container">
			<div class="row">
				<div class="col-md-6">
					<div class="d-flex align-items-center h-100">
						<img src="@/assets/images/ProposalLogo.svg" class="img-fluid login-logo">
					</div>
				</div>
				<div class="col-md-6">
					<div class="login-form">
						<form @submit.prevent="handleSubmit">
							<h2>Let’s create <br>Amazing Proposals</h2>
							<div class="input-group mb-3">
								<label class="w-100">Email Address</label>
								<input v-model="email" type="text" style='border-color:white !important' class="form-control" aria-label="Text input with dropdown button" :class="{ 'is-invalid': submitted && $v.email.$error }">
								<div class="input-group-append">
									<select class="form-control" v-model="subdomain">
										<option selected>@wwindia.com</option>
										<option>@neosoftmail.com</option>
										<option>@webwerks.com</option>
										<option>@neosofttech.com</option>
									</select>
								</div>
                                <div v-if="submitted" class="invalid-feedback">
                                    <span v-if="!$v.email.required">Username is required</span>
                                </div>
							</div>
							<div class="form-group mt-3">
                                <label for="login_password">MPIN</label>
                                <v-otp-input
                                class="form-control otp-form"
                                ref="otpInput"
                                input-classes="otp-input"
                                separator=" "
                                :num-inputs="6"
                                :should-auto-focus="true"
                                @on-complete="handleOnComplete"
                                :is-input-num="true"
                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                                />
                                <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                                    <span v-if="!$v.password.required">Mpin is required</span>
                                </div>
                            </div>

							<button type="submit" class="btn btn-primary mt-3" @click="submitLoginForm">Login</button>
						</form>
					</div>
				</div>
			</div>
		</div>
        <loading :active.sync="isLoading"></loading>
	</div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import OtpInput from "@bachdgvn/vue-otp-input"
    import ApiService from '../services/Api';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "Login",
        data () {
            return {
                isLoading: false,
                email:"",
                subdomain:"@wwindia.com",
                password:"",
                selected : true,
                submitted: false,
            }
        },
        validations: {
            email: { required },
            password: { required }
        },
       methods: {
            handleOnComplete(value){
                this.password = value;
            },
            handleSubmit(e) {
                let thisPointer = this;
                thisPointer.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
            },
            created: function(){
            },
            submitLoginForm: function(event){
                let thisPointer = this;
                if(!thisPointer.$v.$invalid) {
                    thisPointer.isLoading = true;
                    var emailaddress = thisPointer.email+''+thisPointer.subdomain;
                    let loginDetails    =   {'email':emailaddress,'password':thisPointer.password};
                    thisPointer.loginerror = "";
                    thisPointer.$store.dispatch('login',loginDetails).then(function (data) {
                        if(typeof data != 'undefined' && data.status){
                            var username = data.username;
                            localStorage.setItem('username',username);
                            thisPointer.isLoading = false;
                            thisPointer.$router.go({ path: '/home' });
                        }else{
                            thisPointer.isLoading = false;
                            thisPointer.$noty.error("Invalid Credentials : Username / Mpin");
                            thisPointer.$refs.otpInput.clearInput();
                            thisPointer.password = "";
                        }
                    });
                }
            },
        },
        components : {
          "v-otp-input": OtpInput,
          Loading
        }
    }
</script>
