<template>
	<main class="inner-page">
		<sidebar></sidebar>
		<!-- proposal-form -->
		<div class="create-proposal editor">
			<div class="create-proposal__box">
				<div class="create-proposal__box-inner p-0">
					<form class="mb-0">
						<div class="create-proposal__form-wrap">
							<div class="row">
								<div class="col-md-12">
                                    <div v-if="pages.length == 0 && isLoading == false" style="min-height: calc(100vh - 30px);" class="create-proposal__form add-page write-proposal" >
                                        <div class="row h-100 align-items-center">
                                            <div class="col-md-10">
                                                <div class="add-page__box text-center">
                                                    <img src="@/assets/images/add-page.svg" class="img-fluid">
                                                    <h4>You Need To Add Your First Page To Begin</h4>
                                                    <p class="mb-4">Click the 'Add a page' button on the left under your logo. To rename it, just click the title and edit it then click off.</p>
                                                    <button type="button" @click="create_page" class="btn btn-primary">Add a Page</button>
                                                </div>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <div class="add-content h-auto">
                                                    <h3>Add Content</h3>
                                                    <p>This panel helps you to enhance your proposal with these creative, visual elements</p>
                                                    <div class="text-center mt-5">
                                                        <img src="@/assets/images/add-content.svg" class="img-fluid">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="pages.length > 0" class="create-proposal__form add-page write-proposal">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="pages-section d-flex align-items-center p-0">
                                                    <div class="page-list">
                                                        <ul class="list-unstyled mb-0" style="overflow-y: hidden;">
                                                            <li v-for="(page,index) in pages" :class="page_id == page.id ? 'active' : '' " :style="page.parent_id == null?'':'border-left:1px solid red;height: 25px;margin-top: 8px;border-radius:0'">
                                                                <a href="javascript:void(0)" @click="editor_data(page.id)" >{{ page.name }} <img v-if="page_id == page.id && isUpdating" src="@/assets/images/202.gif" width="15px" class="img-fluid ml-1"> </a>
                                                                <img @click="update_name(page.id,page.name,page.parent_id,page.children.length),editor_data(page.id)" style="cursor:pointer;" src="@/assets/images/edit.png" width="10px" class=" mr-1">
                                                                <img @click="delete_page(page.id)" style="cursor:pointer;" src="@/assets/images/close.png" width="8px">
                                                            </li>
                                                        </ul>
                                                        <div class="add-page">
                                                            <a title="Add Page" @click="create_page" href="javascript:void(0);">+</a>
                                                            <a v-if="pages.length > 1" style="margin-left:6px; font-size: 12px;" title="Rearrange Page" @click="rearrange_page" href="javascript:void(0);"><i class="fa fa-bars" aria-hidden="true"></i></a>
                                                            <a title="Preview" :href="api_url+'Proposal-pages/'+template_id" target="_blank" class="ml-2"><img src="@/assets/images/icons/preview.svg" class="img-fluid" style="width:15px;"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-for="(page,index) in pages" class="col-md-12 page-content" :class="page.id == page_id ? '' : 'editor_div'">
                                                <div :id="'gjs'+page.id" >
                                                </div>
                                            </div>
                                            <div class="save-btn">
                                                <button @click="savecurrentpage" class="btn btn-success"><img src="@/assets/images/icons/save.svg"> Save</button>
                                            </div>
                                        </div>
                                        <div v-if="startSaveLoader == true" class="saved-box">
                                            <img src="@/assets/images/check.gif">
                                            <span>Saved</span>
                                        </div>
                                        <div v-if="startUpdateLoader == true" style="width: 216px;" class="saved-box">
                                            <img src="@/assets/images/202.gif">
                                            <span>Plz Wait Saving Data</span>
                                        </div>
                                    </div>
								</div>
                                <div class="col-md-12 action-hover">
                                    <span></span>
                                    <div class="create-proposal__form-actions">
                                        <div>
                                            <a v-if="$store.state.updating_basic_detail == 1 || $store.state.updating_cover_detail == 1" href="javascript:void(0)" @click="click_on_proposal_step3" class="btn btn-outline-primary mr-2" id="prev">Back</a>
											<a v-else href="javascript:void(0)" @click="onBack" class="btn btn-outline-primary mr-2" id="prev">Back</a>
                                            <a href="javascript:void(0)" @click="click_on_proposal_step6" class="btn btn-primary" id="next">Next</a>
                                        </div>
                                        <button type="button" class="btn btn-outline-primary" @click="save" >Save & Exit</button>
                                    </div>
                                </div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
        <loading :active.sync="isLoading"></loading>
        <modal :height="450" id="addPageModal" name="addPageModal" data-backdrop='static' data-keyboard='false'>
            <div class="p-4">
                <div class="form-group">
                    <h2 style="text-align: center;"> {{ modaltitle }} </h2>
                    <hr/>
                    <div class="mb-2">
                        <label> Page Name</label>
                        <input v-model="page_name"  type="text" class="form-control"  :class="{ 'is-invalid': submitted && $v.page_name.$error }" placeholder="Enter Page Name">
                        <div v-if="submitted && $v.page_name.$error" class="invalid-feedback">
                                <span v-if="!($v.page_name.$required )">Page Name is required</span>
                        </div>
                    </div>
                    <div v-if="show_parent" class="mb-2 select-box">
                        <label> Select Parent</label>
                        <select v-model="selected_parent" class="form-control">
                            <option value="">Select Parent</option>
                            <option v-for="(page) in rearrange_pages" :value="page.id">{{page.name}} </option>
                        </select>
                    </div>
                     <div v-if="!edit_name" class="mb-2 select-box">
                        <label> Select Template</label>
                        <select v-model="selected_template" class="form-control">
                            <option value="">Select Template</option>
                            <option v-for="(ct_template) in const_template" :value="ct_template.id">{{ct_template.name}} Template</option>
                        </select>
                    </div>
                </div>
                <div class="text-center">
                    <a @click="save_page" v-if="!edit_name" class="btn btn-primary" id="next">Save</a>
                    <a @click="update_page" v-if="edit_name" class="btn btn-primary" id="next">Update</a>
                    <a class="btn btn-primary ml-2" @click="close">Cancel </a>
                </div>
            </div>
        </modal>
        <modal :height="450" name="PageRearrange">
            <div class="p-4">
                <div class="form-group">
                    <h2 style="text-align: center;"> Rearrange Pages</h2>
                    <hr/>
                    
                    <nested-draggable v-on:send-result-values='Rearragetest' :rearrange_pages="rearrange_pages" />
                    <!-- {{rearrange_pages}} -->
                </div>
                 <div class="text-center">
                    <a @click="save_page_order" class="btn btn-primary" id="next">Save</a>
                 </div>
            </div>
        </modal>
        <modal :height="450" name="SelectTheme" id="myModal">
            <div class="p-4">
                <div class="form-group">
                    <h2 style="text-align: center;">Select Theme</h2>
                    <hr/>
                    <label> Themes </label>
                    <div class="form-group select-box select-box--small mb-0">
                        <select class="form-control" v-model="selected_theme" >
                            <option selected> Select</option>
                            <option value="red">Red Theme</option>
                            <option value="dark_blue">Dark Blue Theme</option>
                            <option value="blue"> Blue Theme </option>
                            <option value="violet"> Violet Theme</option>
                            <option value="purple"> Purple Theme</option>
                            <option value="tangerine"> Tangerine Theme</option>
                        </select>
                    </div>
                </div>
                 <div class="text-center">
                    <a @click="set_theme" class="btn btn-primary" id="next">Save</a>
                 </div>
            </div>
        </modal>
	</main>
</template>

<script>
	import Sidebar from '@/components/Sidebar.vue';
    import ApiService from '@/services/Api';
    import AuthToken from '@/services/Auth';
	import Loading from 'vue-loading-overlay';
    import { required,requiredIf } from 'vuelidate/lib/validators';
    import draggable from 'vuedraggable';
    import nestedDraggable from "./nested_test";

    export default {
        name: "Step5",
        data () {
            return {
            	editor : null,
                const_template : [],
        		showeditor : false,
        		show_parent : true,
        		page_id : null,
        		page_name : '',
        		edit_name : false,
                pages : [],
                rearrange_pages : [],
                selected_template:'',
                selected_parent:'',
                color :{
                    white:{
                        'header':{
                                    'img':'comp_img/head1.png',
                                    'color':'#333',
                                    'backcolor':'#f6f5f1'
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/content1.png',
                                            'c2':'comp_img/content4.png',
                                            'c3':'comp_img/content8.png',
                                            'c4':'comp_img/content10.png',
                                            'c5':'comp_img/content12.png',
                                            'c6':'comp_img/content14.png',
                                        },
                                    'color1':'#666',
                                    'color2':'#666',
                                    'backcolor':'#f6f5f1'
                                },
                        'footer':{
                                    'img':'comp_img/whitefoot.png',
                                    'color':'#333',
                                    'backcolor':'#f6f5f1'
                                },
                        'thankyou':{
                                    'img':'comp_img/whitethank.png',
                                    'color':'#333',
                                    'backcolor':'#f6f5f1'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/white_feature1.png',
                                    'f2':'comp_img/white_feature2.png',

                                },
                            'color':'#333',
                            'color1':'#333',
                            'color2':'#666',
                            'backcolor':'#f6f5f1'
                        }
                    },
                    red:{
                        'header':{
                                    'img':'comp_img/red_head.png',
                                    'color':'#fff',
                                    'backcolor':'#F0454D',
                                    'backimg' :'comp_img/red_backgraound.png'
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/red_content1.png',
                                            'c2':'comp_img/red_content2.png',
                                            'c3':'comp_img/red_content3.png',
                                            'c4':'comp_img/red_content4.png',
                                            'c5':'comp_img/red_content5.png',
                                            'c6':'comp_img/red_content6.png',
                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#F0454D',
                                    'backimg':'comp_img/red_backgraound.png'
                                },
                        'footer':{
                                    'img':'comp_img/red_footer.png',
                                    'color':'#fff',
                                    'backcolor':'#F0454D',
                                    'backimg':'comp_img/red_backgraound.png'
                                },
                        'table':{
                                    'img':'comp_img/red_table.png',
                                    'color':'#fff',
                                    'backcolor':'#F0454D',
                                    'backimg':'comp_img/red_backgraound.png'
                                },
                        'thankyou':{
                                    'img':'comp_img/red_thank.png',
                                    'color':'#fff',
                                    'backcolor':'#F0454D',
                                    'backimg':'comp_img/red_backgraound.png'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/red_feat.png',
                                    'f2':'comp_img/red_feature2.png',

                                },
                            'color':'#fff',
                            'color1':'#333',
                            'color2':'#666',
                            'backcolor':'#F0454D',
                            'backimg':'comp_img/red_backgraound.png'
                        }
                    },
                    green:{
                        'header':{
                                    'img':'comp_img/head3.png',
                                    'color':'#fff',
                                    'backcolor':'#379683'
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/greencon1.png',
                                            'c2':'comp_img/greencon2.png',
                                            'c3':'comp_img/greencon3.png',
                                            'c4':'comp_img/greencon4.png',
                                            'c5':'comp_img/greencon5.png',
                                            'c6':'comp_img/greencon6.png',

                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#379683'
                                },
                        'footer':{
                                    'img':'comp_img/greenfoot.png',
                                    'color':'#fff',
                                    'backcolor':'#379683'
                                },
                        'thankyou':{
                                    'img':'comp_img/greenthank.png',
                                    'color':'#fff',
                                    'backcolor':'#379683'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/green_feature1.png',
                                    'f2':'comp_img/green_feature2.png',

                                },
                            'color':'#fff',
                            'backcolor':'#379683'
                        }

                    },
                    violet:{
                        'header':{
                                    'img':'comp_img/violet_head.png',
                                    'color':'#fff',
                                    'backcolor':'#0F0943'
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/violet_content1.png',
                                            'c2':'comp_img/violet_content2.png',
                                            'c3':'comp_img/violet_content3.png',
                                            'c4':'comp_img/violet_content4.png',
                                            'c5':'comp_img/violet_content5.png',
                                            'c6':'comp_img/violet_content6.png',

                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#0F0943'
                                },
                        'footer':{
                                    'img':'comp_img/violet_footer.png',
                                    'color':'#fff',
                                    'backcolor':'#0F0943'
                                },
                        'table':{
                                    'img':'comp_img/violet_table.png',
                                    'color':'#fff',
                                    'backcolor':'#0F0943'
                                },
                        'thankyou':{
                                    'img':'comp_img/violet_thank.png',
                                    'color':'#fff',
                                    'backcolor':'#0F0943'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/violet_feat.png',
                                    'f2':'comp_img/violet_feature2.png',

                                },
                            'color':'#fff',
                            'backcolor':'#0F0943'
                        }

                    },
                    purple:{
                        'header':{
                                    'img':'comp_img/purple_head.png',
                                    'color':'#fff',
                                    'backcolor':'#834495'
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/purple_content1.png',
                                            'c2':'comp_img/purple_content2.png',
                                            'c3':'comp_img/purple_content3.png',
                                            'c4':'comp_img/purple_content4.png',
                                            'c5':'comp_img/purple_content5.png',
                                            'c6':'comp_img/purple_content6.png',

                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#834495'
                                },
                        'footer':{
                                    'img':'comp_img/purple_footer.png',
                                    'color':'#fff',
                                    'backcolor':'#834495'
                                },
                        'table':{
                                    'img':'comp_img/purple_table.png',
                                    'color':'#fff',
                                    'backcolor':'#834495'
                                },
                        'thankyou':{
                                    'img':'comp_img/purple_thank.png',
                                    'color':'#fff',
                                    'backcolor':'#834495'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/purple_feat.png',
                                    'f2':'comp_img/purple_feature2.png',

                                },
                            'color':'#fff',
                            'backcolor':'#834495'
                        }

                    },
                    dark_blue:{ 
                        'header':{
                                    'img':'comp_img/dkblue_head.png',
                                    'color':'#fff',
                                    'backcolor':'#4657F0'
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/dkblue_content1.png',
                                            'c2':'comp_img/dkblue_content2.png',
                                            'c3':'comp_img/dkblue_content3.png',
                                            'c4':'comp_img/dkblue_content4.png',
                                            'c5':'comp_img/dkblue_content5.png',
                                            'c6':'comp_img/dkblue_content6.png',

                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#4657F0'
                                },
                        'footer':{
                                    'img':'comp_img/dkblue_footer.png',
                                    'color':'#fff',
                                    'backcolor':'#4657F0'
                                },
                        'table':{
                                    'img':'comp_img/darkblue_table.png',
                                    'color':'#fff',
                                    'backcolor':'#4657F0'
                                },
                        'thankyou':{
                                    'img':'comp_img/dkblue_thank.png',
                                    'color':'#fff',
                                    'backcolor':'#4657F0'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/darkblue_feat.png',
                                    'f2':'comp_img/dkblue_feature2.png',

                                },
                            'color':'#fff',
                            'backcolor':'#4657F0'
                        }

                    },
                    tangerine:{
                        'header':{
                                    'img':'comp_img/tangerine_head.png',
                                    'color':'#fff',
                                    'backcolor':'#FFAA19'
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/tangerine_content1.png',
                                            'c2':'comp_img/tangerine_content2.png',
                                            'c3':'comp_img/tangerine_content3.png',
                                            'c4':'comp_img/tangerine_content4.png',
                                            'c5':'comp_img/tangerine_content6.png',
                                            'c6':'comp_img/tangerine_content5.png',

                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#FFAA19'
                                },
                        'footer':{
                                    'img':'comp_img/tangerine_footer.png',
                                    'color':'#fff',
                                    'backcolor':'#FFAA19'
                                },
                        'table':{
                                    'img':'comp_img/tangerine_table.png',
                                    'color':'#fff',
                                    'backcolor':'#FFAA19'
                                },        
                        'thankyou':{
                                    'img':'comp_img/tangerine_thank.png',
                                    'color':'#fff',
                                    'backcolor':'#FFAA19'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/tangerine_feat.png',
                                    'f2':'comp_img/tangerine_feature2.png',

                                },
                            'color':'#fff',
                            'backcolor':'#FFAA19'
                        }

                    },
                    blue:{
                        'header':{
                                    'img':'comp_img/head2.png',
                                    'color':'#fff',
                                    'backimg':'',
                                    'backcolor':'#0094ca',
                                },
                        'content':{
                                    'img':{
                                            'c1':'comp_img/bluecontent1.png',
                                            'c2':'comp_img/bluecontent2.png',
                                            'c3':'comp_img/bluecontent3.png',
                                            'c4':'comp_img/bluecontent4.png',
                                            'c5':'comp_img/bluecontent5.png',
                                            'c6':'comp_img/bluecontent6.png',

                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#0094ca',
                                    'backimg':'comp_img/bg-1.png'
                                },
                        'footer':{
                                    'img':'comp_img/blfoot.png',
                                    'color':'#fff',
                                    'backcolor':'#0094ca'
                                },
                        'table':{
                                    'img':'comp_img/blue_table.png',
                                    'color':'#fff',
                                    'backcolor':'#0094ca'
                                },        
                        'thankyou':{
                                    'img':'comp_img/bluthank.png',
                                    'color':'#fff',
                                    'backcolor':'#0094ca'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/blue_feat.png',
                                    'f2':'comp_img/blue_feature2.png',

                                },
                            'color':'#fff',
                            'color1':'#333',
                            'color2':'#666',
                            'backcolor':'#0094ca',
                            'backimg':'comp_img/bg-1.png'
                        }

                    },
                    black:{
                        'header':{
                                    'img':'comp_img/blachead.png',
                                    'color':'#fff',
                                    'backcolor':'#343a40'
                                },
                         'content':{
                                    'img':{
                                            'c1':'comp_img/content2.png',
                                            'c2':'comp_img/content5.png',
                                            'c3':'comp_img/content9.png',
                                            'c4':'comp_img/content11.png',
                                            'c5':'comp_img/content13.png',
                                            'c6':'comp_img/content15.png',

                                        },
                                    'color1':'#fff',
                                    'color2':'#fff',
                                    'backcolor':'#343a40'
                                },
                        'footer':{
                                    'img':'comp_img/blacfoot.png',
                                    'color':'#fff',
                                    'backcolor':'#343a40'
                                },
                        'footer':{
                            'img':'comp_img/blacfoot.png',
                            'color':'#fff',
                            'backcolor':'#343a40'
                        },
                        'thankyou':{
                                    'img':'comp_img/blacthank.png',
                                    'color':'#fff',
                                    'backcolor':'#343a40'
                                },
                        'feature':{
                            'img':{
                                    'f1':'comp_img/black_feature1.png',
                                    'f2':'comp_img/black_feature2.png',

                                },
                            'color':'#fff',
                            'color1':'#333',
                            'color2':'#666',
                            'backcolor':'#343a40'
                        }

                    }

                },
                isLoading:false,
                isUpdating:false,
                submitted:false,
                show_select:true,
                selected_page:'',
                startSaveLoader : false,
                startUpdateLoader : false,
                modaltitle : 'Create Page',
                proposalId : '',
                theme      : '',
                selected_theme : '',
                api_url:this.$api_url,
                template_id:'',
                modal_width:0
            }
        },
        validations: {
            page_name: { required },
        },
        computed: {
            draggingInfo() {
            }
        },
        mounted(){
            console.log(this.api_url);
            this.get_pages();
        },

        methods: {
            create_page(){
                let thisPointer = this;
                thisPointer.show_parent = true;
                thisPointer.page_name = '';
                thisPointer.edit_name = false;
                thisPointer.show_select=true;
                thisPointer.modal_width = 450;
                thisPointer.modaltitle = 'Create Page';
                thisPointer.$modal.show('addPageModal');
        	},
            close(){
                this.$modal.hide('addPageModal');
            },
            Rearragetest(evt){
                console.log(this.rearrange_pages,'rahul',evt);
            },
            click_on_proposal_step6(){
                let thisPointer = this;
                thisPointer.isLoading = true;
                ApiService.get('check_html/'+thisPointer.template_id)
                    .then(response=>{
                            if(response.data.status){
                                thisPointer.isLoading = false;
                                this.$router.push({ path: '/proposal-step-6' });
                            }
                    })
                	
            },
            click_on_proposal_step3(){
                    this.$router.push({ path: '/proposal-step-3' });
            },
            rearrange_page(){
                let thisPointer = this;
                thisPointer.$modal.show('PageRearrange');
        	},

            update_name(id,name,parent_id,children){
                let thisPointer = this;
                if(children==0){
                    thisPointer.show_parent = true;
                }
                else{
                    thisPointer.show_parent = false;
                }
                thisPointer.page_name = name;
                thisPointer.modal_width = 350;
                thisPointer.page_id = id;
                thisPointer.selected_parent = parent_id;
                thisPointer.edit_name = true;
                thisPointer.show_select=false;
                thisPointer.modaltitle = 'Update Page';
                 $('#addPageModal').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                thisPointer.$modal.show('addPageModal');
        	},
            update_page(){
                let thisPointer = this;
                thisPointer.submitted = true;
                thisPointer.$v.$touch();
                if(!thisPointer.$v.$invalid) {
                    thisPointer.isLoading = true;
                    var Page = {'template':thisPointer.$store.state.template_id,'page_id':thisPointer.page_id,'page_name':thisPointer.page_name,'selected_parent':thisPointer.selected_parent,'rearrange_pages':thisPointer.rearrange_pages};
                    thisPointer.$store.dispatch('update_page',Page).then(function (data) {
                        if(data.status){
                            thisPointer.pages = data.page;
                            setTimeout(function(){
                                thisPointer.editor_data(data.page_id);
                            }, 1000);
                            thisPointer.$modal.hide('addPageModal');
                            thisPointer.submitted = false;
                            thisPointer.edit_name = false;
                        }
                    });
                }
            },
            save_page_order(){
                let thisPointer = this;
                let data={'page':thisPointer.rearrange_pages};
                var check = true;
                $.each(thisPointer.rearrange_pages, function( index, getData ) {
                    if(getData.children.length>0){
                        $.each(getData.children, function( index, child ) {
                                if(child.children.length>0){
                                    thisPointer.$noty.error("Multiple Hierarchy Not Allowed. Only Parent Child Relation Allowed");
                                    check = false;
                                }
                        });
                    }
                });
                if(check){
                    thisPointer.isLoading = true;
                    ApiService.post('rearrange_page', data)
                    .then(response=>{
                            if(response.status){
                                thisPointer.$router.go();
                            }
                    })
                }
                
               
            },
            save_page(){
                let thisPointer = this;
                thisPointer.submitted = true;
                thisPointer.$v.$touch();
                if(!thisPointer.$v.$invalid) {
                    // thisPointer.isLoading = true;
                    var Page = {'template':thisPointer.$store.state.template_id,'page_name':thisPointer.page_name,'selected_template':thisPointer.selected_template,'selected_parent':thisPointer.selected_parent,'rearrange_pages':thisPointer.rearrange_pages};
                    thisPointer.$store.dispatch('store_page',Page).then(function (data) {
                        if(data.status){
                            thisPointer.pages = data.page;
                            setTimeout(function(){
                                thisPointer.editor_data(data.page_id);
                            }, 1000);
                            thisPointer.$modal.hide('addPageModal');
                            thisPointer.submitted = false;
                        }
                    });
                }
            },
            delete_page(page_id){
                let thisPointer = this;
                thisPointer.$swal({
                        icon: 'warning',
                        text: 'Are you sure you want to delete this page ?',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                }).then((result) => {
                    if(result.value) {
                        thisPointer.isLoading = true;
                        var Page = {'template':thisPointer.$store.state.template_id,'page_id':page_id};
                        thisPointer.isLoading = true;
                        thisPointer.$store.dispatch('delete_page',Page)
                        .then(function (data) {
                            if(data.status){
                                thisPointer.$router.go()
                                thisPointer.isLoading = false;
                            }
                        });
                    }
                })
        	},
            editor_data(page_id){
                let thisPointer = this;

               var url = this.$api_url;
               console.log(url);
                thisPointer.page_id = page_id;

                thisPointer.editor = grapesjs.init({
                    container: "#gjs"+page_id,
                    plugins: ["grapesjs-blocks-basic","grapesjs-rte-extensions"],
                        pluginsOpts: {
                        "grapesjs-rte-extensions": {
                        base: {
                            bold: true,
                            italic: true,
                            underline: true,
                            strikethrough: true,
                            link: true,
                        },
                        fonts: {
                            fontColor: true,
                            hilite: true,
                        },
                        format: {
                            heading1: true,
                            heading2: true,
                            heading3: true,
                            paragraph: true,
                            quote: true,
                            clearFormatting: true,
                        },
                        subscriptSuperscript: true,//|true
                        indentOutdent: false,//|true
                        list: true,//|true
                        align: true,//|true
                        actions: true,//|true
                        undoredo: true,//|true
                        extra: false,//|true
                        darkColorPicker: true,//|false
                        }
                    },
                     styleManager: {
                     sectors: [

                        {
                            name: 'Font-Properties',
                            buildProps: ['font-family','font-weight','font-size','color','text-align']
                        },
                        {
                            name: 'Background Properties',
                            buildProps: ['background-color', 'background-image']
                        },

                        ]
                    },
                    storageManager: {
                        type: 'remote',
                        stepsBeforeSave:1,
                        autosave: true,         // Store data automatically
                        autoload: true,
                        urlStore: url+'updatepage',
                        urlLoad: url+'getpagecontent/'+page_id,
                        params: { page_id: page_id },
                        storeComponents: true,
                        storeStyles: true,
                        storeHtml: true,
                        storeCss: true,
                        headers: {
                        'Content-Type': 'application/json',
                        'Authorization':AuthToken.getToken(),
                        'Access-Control-Allow-Credentials':true
                        }
                    },
                    canvas: {
                        styles:['https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/style.css',
                                'https://fonts.googleapis.com/css2?family=Sanchez&display=swap',
                                'https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap',
                                'https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap',
                                'https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap']
                    }
                });
                // header
                    var theme = thisPointer.theme;
                    var data = '';
                    if(theme == 'all'){
                        var data = thisPointer.color;
                    }else{
                        var data = _.pick(thisPointer.color, theme);
                    }
                     $.each(data, function( index, getData ) {
                              var temp = getData;
                              if(thisPointer.theme)
                              {
                                    thisPointer.editor.BlockManager.add('section-header1' , {
                                    // label: '<img src='+url+'/'+temp.header.img+'>',
                                    label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.header.img+');"></div>',
                                    category: 'Header',
                                    content: '<div style="background-color:'+temp.header.backcolor+'; padding: 50px 50px; font-family: Proxima Nova Rg, sans-serif;">'+
                                                '<div style="color: '+temp.header.color+'; font-family: Proxima Nova Rg, sans-serif; font-size: 40px;">ABC Accounting is looking to increase its online leads with a NEW website</div>'+
                                                '</div>'

                                    });
                              }
                          });


                // content
                $.each(data, function( index, getData ) {
                    var temp = getData;
                    if(thisPointer.theme != 'blue' ){

                        thisPointer.editor.BlockManager.add('section-content1', {
                            // label: '<img src='+url+'/'+temp.content.img.c1+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c1+')"></div>',
                            category: 'Content',
                            style: {
                                width: '200px',
                                height: '200px',
                            },
                            attributes: {
                                title: 'Insert header_block'
                            },
                            content: '<div style="background-color: '+temp.content.backcolor+'; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif; text-align: center;">'+
                                        '<div style="color: '+temp.content.color1+'; font-size: 45px; margin-bottom:20px; font-family: Proxima Nova Rg, sans-serif;">Content Design</div>'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 22px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content4', {
                        // label: '<img src='+url+'/'+temp.content.img.c2+'>',
                        label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c2+')"></div>',
                        category: 'Content',
                        content: '<div style="background-color: '+temp.content.backcolor+'; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif; text-align: center;">'+
                                    '<div style="color: '+temp.content.color1+'; font-size: 45px; font-family: Proxima Nova Rg, sans-serif;">Content Design</div>'+
                                    '<div style="display: flex;">'+
                                        '<div style="width: 50%; padding: 10px 15px;">'+
                                            '<div style="color: '+temp.content.color1+'; font-size: 22px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                        '</div>'+
                                        '<div style="width: 50%; padding: 10px 15px;">'+
                                            '<div style="color: '+temp.content.color1+'; font-size: 22px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                        '</div>'+
                                    '</div>'+
                                '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content8', {
                            // label: '<img src='+url+'/'+temp.content.img.c3+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c3+');"></div>',
                            category: 'Content',
                            content: '<div style="background-color: '+temp.content.backcolor+'; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content10', {
                            // label: '<img src='+url+'/'+temp.content.img.c4+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c4+')"></div>',
                            category: 'Content',
                            content: '<div style="background-color: '+temp.content.backcolor+'; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content12', {
                            // label: '<img src='+url+'/'+temp.content.img.c5+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c5+')"></div>',
                            category: 'Content',
                            content: '<div style="background-color: '+temp.content.backcolor+'; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<ul style="padding-left: 20px;">'+
                                                    '<li style="color: '+temp.content.color1+'">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                    '</li>'+
                                                '</ul>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content14', {
                            // label: '<img src='+url+'/'+temp.content.img.c6+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c6+')"></div>',
                            category: 'Content',
                            content: '<div style="background-color: '+temp.content.backcolor+'; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<ul style="padding-left: 20px; display: flex; flex-wrap: wrap;">'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                '</ul>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'
                        });
                    }
                    console.log(temp,thisPointer.theme );
                    
                    if(thisPointer.theme == 'blue' ){
                        thisPointer.editor.BlockManager.add('section-content1', {
                            // label: '<img src='+url+'/'+temp.content.img.c1+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c1+')"></div>',
                            category: 'Content',
                            style: {
                                width: '200px',
                                height: '200px',
                            },
                            attributes: {
                                title: 'Insert header_block'
                            },
                            content: '<div style="background-image: url('+url+'/'+temp.content.backimg+'); padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif; text-align: center;">'+
                                        '<div style="color: '+temp.content.color1+'; font-size: 45px; margin-bottom:20px; font-family: Proxima Nova Rg, sans-serif;">Content Design</div>'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 22px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content4', {
                        // label: '<img src='+url+'/'+temp.content.img.c2+'>',
                        label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c2+')"></div>',
                        category: 'Content',
                        content: '<div style="background-image: url('+url+'/'+temp.content.backimg+'); padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif; text-align: center;">'+
                                    '<div style="color: '+temp.content.color1+'; font-size: 45px; font-family: Proxima Nova Rg, sans-serif;">Content Design</div>'+
                                    '<div style="display: flex;">'+
                                        '<div style="width: 50%; padding: 10px 15px;">'+
                                            '<div style="color: '+temp.content.color1+'; font-size: 22px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                        '</div>'+
                                        '<div style="width: 50%; padding: 10px 15px;">'+
                                            '<div style="color: '+temp.content.color1+'; font-size: 22px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                        '</div>'+
                                    '</div>'+
                                '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content8', {
                            // label: '<img src='+url+'/'+temp.content.img.c3+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c3+')"></div>',
                            category: 'Content',
                            content: '<div style="background-image: url('+url+'/'+temp.content.backimg+'); padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content10', {
                            // label: '<img src='+url+'/'+temp.content.img.c4+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c4+')"></div>',
                            category: 'Content',
                            content: '<div style="background-image: url('+url+'/'+temp.content.backimg+'); padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content12', {
                            // label: '<img src='+url+'/'+temp.content.img.c5+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c5+')"></div>',
                            category: 'Content',
                            content: '<div style="background-image: url('+url+'/'+temp.content.backimg+'); padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<ul style="padding-left: 20px;">'+
                                                    '<li style="color: '+temp.content.color1+'">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                                    '</li>'+
                                                '</ul>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'

                        });

                        thisPointer.editor.BlockManager.add('section-content14', {
                            // label: '<img src='+url+'/'+temp.content.img.c6+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.content.img.c6+')"></div>',
                            category: 'Content',
                            content: '<div style="background-image: url('+url+'/'+temp.content.backimg+'); padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex;">'+
                                            '<div style="width: 100%; padding: 10px 15px;">'+
                                                '<div style="color: '+temp.content.color1+'; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Content Design</div>'+
                                                '<ul style="padding-left: 20px; display: flex; flex-wrap: wrap;">'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                    '<li style="color: '+temp.content.color1+'; width: 25%; padding: 5px;">'+
                                                        '<div style="color: '+temp.content.color1+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing</div>'+
                                                    '</li>'+
                                                '</ul>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'
                        });
                    }


                });


                // feature
                // thisPointer.editor.BlockManager.add('section-feature1', {
                //     label: '<img src='+url+'comp_img/feature1.png>',
                //     category: 'Feature',
                //     content: '<div style="background-color: #f6f5f1; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                //                 '<div style="display: flex; justify-content: space-between;">'+
                //                     '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; border-top: solid 5px #329ef7; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2);">'+
                //                         '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 1</div>'+
                //                         '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                //                     '</div>'+
                //                     '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; margin-left: 30px; border-top: solid 5px #329ef7; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2)">'+
                //                         '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 2</div>'+
                //                         '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                //                     '</div>'+
                //                     '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; margin-left: 30px; border-top: solid 5px #329ef7; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2)">'+
                //                         '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 3</div>'+
                //                         '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                //                     '</div>'+
                //                 '</div>'+
                //             '</div>'
                // });
                

                thisPointer.editor.BlockManager.add('section-feature3', {
                    // label: '<img src='+url+'comp_img/feature3.png>',
                    label: '<div class="grapes_label" style="background-image: url('+url+'/comp_img/feature3.png);height:200px"></div>',
                    category: 'Feature',
                    content: '<div style="background-color: #f6f5f1; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                '<div style="color: #333; font-size: 42px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; text-align: center; margin-top: 0; margin-bottom: 20px;">Features</div>'+
                                '<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 1</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 2</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 3</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 4</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                '</div>'+
                             '</div>'
                });



                thisPointer.editor.BlockManager.add('section-feature5', {
                    // label: '<img src='+url+'comp_img/feature4.png>',
                    label: '<div class="grapes_label" style="background-image: url('+url+'/comp_img/feature4.png);height:200px"></div>',
                    category: 'Feature',
                    content: '<div style="background-color: #f6f5f1; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                '<div style="color: #333; font-size: 42px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0; text-align: center; margin-bottom: 20px;">Features</div>'+
                                '<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 1</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<img src='+url+'comp_img/designer.svg alt="" style="max-width: 100%; height: auto;">'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 2</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<img src='+url+'comp_img/design-community.svg alt="" style="max-width: 100%; height: auto;">'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 3</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<img src='+url+'comp_img/design-life.svg alt="" style="max-width: 100%; height: auto;">'+
                                    '</div>'+
                                '</div>'+
                            '</div>'
                });

                thisPointer.editor.BlockManager.add('section-feature7', {
                    // label: '<img src='+url+'comp_img/feature7.png>',
                    label: '<div class="grapes_label" style="background-image: url('+url+'/comp_img/feature7.png);height:200px"></div>',
                    category: 'Feature',
                    content: '<div style="background-color: #f6f5f1; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                '<div style="color: #333; font-size: 42px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; text-align: center; margin-top: 0;">Features</div>'+
                                '<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 1</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px; ">'+
                                        '<img src='+url+'comp_img/designer.svg alt="" style="max-width: 100%; height: auto;">'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<img src='+url+'comp_img/design-community.svg alt="" style="max-width: 100%; height: auto;">'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 2</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<div style="color: #333; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 3</div>'+
                                        '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                    '</div>'+
                                    '<div style="width: 50%; padding: 50px 40px;">'+
                                        '<img src='+url+'comp_img/design-life.svg alt="" style="max-width: 100%; height: auto;">'+
                                    '</div>'+
                                '</div>'+
                             '</div>'
                });

                $.each(data, function( index, getData ) {
                    var temp = getData;
                    if(thisPointer.theme != 'blue' ){
                        thisPointer.editor.BlockManager.add('section-feature8', {
                            // label: '<img src='+url+'/'+temp.feature.img.f1+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.feature.img.f1+');height:75px"></div>',
                            category: 'Feature',
                            content: '<div style="background-color: #f6f5f1; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex; justify-content: space-between;">'+
                                            '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; border-top: solid 5px '+temp.feature.backcolor+'; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2);">'+
                                                '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 1</div>'+
                                                '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                                            '</div>'+
                                            '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; margin-left: 30px; border-top: solid 5px '+temp.feature.backcolor+'; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2)">'+
                                                '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 2</div>'+
                                                '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                                            '</div>'+
                                            '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; margin-left: 30px; border-top: solid 5px '+temp.feature.backcolor+'; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2)">'+
                                                '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 3</div>'+
                                                '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'
                        });

                        thisPointer.editor.BlockManager.add('section-feature9', {
                            // label: '<img src='+url+'/'+temp.feature.img.f2+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.feature.img.f2+');height:200px"></div>',
                            category: 'Feature',
                            content: '<div style="background-color: '+temp.feature.backcolor+'; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="color: '+temp.feature.color+'; font-size: 42px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; text-align: center; margin-bottom: 20px; margin-top: 0;">Features</div>'+
                                        '<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 1</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 2</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 3</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 4</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'
                        });
                    }
                    else{
                         thisPointer.editor.BlockManager.add('section-feature8', {
                            // label: '<img src='+url+'/'+temp.feature.img.f1+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.feature.img.f1+');height:75px"></div>',
                            category: 'Feature',
                            content: '<div style="background-color: #f6f5f1; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="display: flex; justify-content: space-between;">'+
                                            '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; border-top: solid 5px '+temp.feature.backcolor+'; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2);">'+
                                                '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 1</div>'+
                                                '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                                            '</div>'+
                                            '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; margin-left: 30px; border-top: solid 5px '+temp.feature.backcolor+'; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2)">'+
                                                '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 2</div>'+
                                                '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                                            '</div>'+
                                            '<div style="width: 31%; padding: 30px 40px 50px 40px; background-color: #fff; margin-left: 30px; border-top: solid 5px '+temp.feature.backcolor+'; border-radius: 5px; box-shadow: 0 .3125rem .875rem 0 rgba(129,129,129,.2)">'+
                                                '<div style="color: #333; font-size: 32px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px;">Feature 3</div>'+
                                                '<div style="color: #666; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Far far away, behind the word mountains, far from the country Vokalia, there live the blind texts.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'
                        });

                        thisPointer.editor.BlockManager.add('section-feature9', {
                            // label: '<img src='+url+'/'+temp.feature.img.f2+'>',
                            label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.feature.img.f2+');height:200px"></div>',
                            category: 'Feature',
                            content: '<div style="background-image: url('+url+'/'+temp.feature.backimg+');  padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                        '<div style="color: '+temp.feature.color+'; font-size: 42px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; text-align: center; margin-bottom: 20px; margin-top: 0;">Features</div>'+
                                        '<div style="display: flex; justify-content: space-between; flex-wrap: wrap;">'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 1</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 2</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 3</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                            '<div style="width: 50%; padding: 50px 40px;">'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 28px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; margin-top: 0;">Feature 4</div>'+
                                                '<div style="color: '+temp.feature.color+'; font-size: 18px; font-family: Proxima Nova Rg, sans-serif;">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'
                        });
                    }
                });

                // table
                $.each(data, function( index, getData ) {
                    var temp = getData;
                    thisPointer.editor.BlockManager.add('section-table1', {
                    // label: '<img src='+url+'/'+temp.table.img+'>',
                    label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.table.img+');height:100px"></div>',
                    category: 'Tables',
                    content: '<div style="background-color: #f6f5f1; padding: 50px 40px 50px 40px; font-family: Proxima Nova Rg, sans-serif;">'+
                                '<div style="color: #333; font-size: 42px; font-family: Proxima Nova Rg, sans-serif; margin-bottom: 20px; text-align: center; margin-top: 0;">Table</div>'+
                                '<table style="width: 100%; background-color: #FFFFFF; border-collapse: collapse; border: 1px solid #ccc; color: #000000;" cellpadding="0" cellspacing="0" border="0">'+
                                    '<thead style="color: #fff; background-color: '+temp.footer.backcolor+';">'+
                                        '<tr>'+
                                            '<th style="border: 1px solid '+temp.footer.backcolor+'; padding: 15px 5px;"><div>header</div></th>'+
                                            '<th style="border: 1px solid '+temp.footer.backcolor+'; padding: 15px 5px;"><div>header</div></th>'+
                                            '<th style="border: 1px solid '+temp.footer.backcolor+'; padding: 15px 5px;"><div>header</div></th>'+
                                            '<th style="border: 1px solid '+temp.footer.backcolor+'; padding: 15px 5px;"><div>header</div></th>'+
                                            '<th style="border: 1px solid '+temp.footer.backcolor+'; padding: 15px 5px;"><div>header</div></th>'+
                                        '</tr>'+
                                    '</thead>'+
                                    '<tbody style="text-align: center;">'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                        '<tr>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                            '<td style="border: 1px solid #ccc; padding: 12px 5px;"><div>data</div></td>'+
                                        '</tr>'+
                                    '</tbody>'+
                                '</table>'+
                            '</div>'
                });

                
                });
                thisPointer.editor.BlockManager.add('section-break', {
                    // label: '<img style="width:25px;margin-bottom: 18px;margin-top: 12px;" src='+url+'comp_img/link-break.svg><p>Line Break</p>',
                    label: '<div class="grapes_label" style="background-image: url('+url+'/link-break.svg);"><p>Line Break</p></div>',
                    category: 'Basic',
                    content: '<div></br></div>'
                });
                // footer
                $.each(data, function( index, getData ) {
                    var temp = getData;
                    thisPointer.editor.BlockManager.add('section-footer1', {
                        // label: '<img src='+url+'/'+temp.footer.img+'>',
                        label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.footer.img+');height:50px"></div>',
                        category: 'Footer',
                        content: '<div style="background-color: '+temp.footer.backcolor+'; width: 100%; padding: 50px 50px; font-family: Proxima Nova Rg, sans-serif; text-align: center; font-size: 16px;">'+

                                    '<div style="width: 100%;">'+
                                        '<div style="font-size: 20px; color: '+temp.footer.color+'; font-family:Proxima Nova Rg, sans-serif;">Company Name</div>'+
                                        '<address style="font-family:Proxima Nova Rg, sans-serif;color: '+temp.footer.color+';">70 Bowman St. <br>'+
                                        'South Windsor, CT 06074 USA <br>+13 827 312 5002</address>'+
                                        '<div style="color: '+temp.footer.color+';">&copy; Copyright 2021</div>'+
                                    '</div>'+
                                '</div>'
                    });
                });
                // thank you
                $.each(data, function( index, getData ) {
                    var temp = getData;
                    thisPointer.editor.BlockManager.add('section-thank1', {
                        // label: '<img src='+url+'/'+temp.thankyou.img+'>',
                        label: '<div class="grapes_label" style="background-image: url('+url+'/'+temp.thankyou.img+');height:100px"></div>',
                        category: 'ThankYou',
                        content: '<div style="min-height: 100vh; background-color: '+temp.thankyou.backcolor+'; padding: 50px 50px; font-family: Proxima Nova Rg, sans-serif; text-align: center; display: flex; align-items: center; justify-content: center;">'+
                                    '<div style="color: '+temp.thankyou.color+';font-size: 80px; font-family: Proxima Nova Rg, sans-serif;">Thank You</div>'+
                                '</div>'
                    });
                });

                // thisPointer.editor.BlockManager.add('section-thank2', {
                //     label: '<img src='+url+'comp_img/thank1.png>',
                //     category: 'ThankYou',
                //     content: '<div style="min-height: 100vh; background-color: #379683; padding: 50px 50px; font-family: Proxima Nova Rg, sans-serif; text-align: center; display: flex; align-items: center; justify-content: center;">'+
                //                 '<div style="color: #fff;font-size: 80px; font-family: Proxima Nova Rg, sans-serif;">Thank You</div>'+
                //              '</div>'
                // });

                thisPointer.editor.on('component:toggled', () => {
                    thisPointer.editor.store();
                });
                thisPointer.editor.on('load', () => {
                    thisPointer.isLoading = true;
                    thisPointer.editor.Panels.getButton('views', 'open-blocks').set('active', true);
                    const prop = thisPointer.editor.StyleManager.getProperty('font-properties', 'font-family');
                    prop.set('options', [
                        {value: "'Arial', sans-serif", name: 'Arial'},
                        {value: "'Proxima Nova Rg', sans-serif", name: 'Proxima Nova Rg'},
                        {value: "'Proxima Nova Lt', sans-serif", name: 'Proxima Nova Lt'},
                        {value: "'Sanchez', sans-serif", name: 'Sanchez'},
                        {value: "'Arimo', sans-serif", name: 'Arimo'},
                        {value: "'Nunito', sans-serif", name: 'Nunito'},
                        {value: "'Work Sans', sans-serif", name: 'Work Sans'},
                    ]);
                    thisPointer.editor.StyleManager.render();
                });

                thisPointer.editor.on('storage:end:load', () => {
                    thisPointer.isLoading = false;
                });

                thisPointer.editor.on('storage:start:store', () => {
                    thisPointer.isUpdating = true;
                    thisPointer.startUpdateLoader = true;
                });

                thisPointer.editor.on('storage:end', () => {
                    thisPointer.isUpdating = false;
                    thisPointer.startUpdateLoader = false;
                    thisPointer.startSaveLoader = true;
                    setTimeout(function(){
                        thisPointer.startSaveLoader = false;
                    },3000);
                });
            },
            savecurrentpage(){
                this.editor.store();
            },
            set_theme(){
                let thisPointer = this;
                if(thisPointer.selected_theme != '')
                thisPointer.isLoading = true;
                    ApiService.get('set_theme/' + thisPointer.selected_theme +'/'+thisPointer.template_id)
                        .then(response=>{
                            console.log(response)
                                if(response.data.status){
                                    thisPointer.isLoading = false;
                                    thisPointer.theme = response.data.theme.theme;
                                    thisPointer.const_template = response.data.const_temp_pages
                                    thisPointer.$modal.hide('SelectTheme');
                                }
                        })
            },
            save(){
                let thisPointer = this;
                thisPointer.isLoading = true;
                ApiService.get('check_html/'+thisPointer.template_id)
                    .then(response=>{
                            if(response.data.status){
                                thisPointer.isLoading = false;
                                thisPointer.$router.push({ name: 'Proposal' });
                            }
                    })
                
			},

            get_pages(){
                console.log(this.api_url);
                let thisPointer = this;
                thisPointer.isLoading = true;
                thisPointer.proposalId = thisPointer.$store.state.proposal_id;
                thisPointer.template_id =  thisPointer.$store.state.template_id
                var Page = { 'template' : thisPointer.$store.state.template_id };
                thisPointer.$store.dispatch('getpages',Page).then(function (data) {
                    if(data.status){
                        thisPointer.pages = data.pages;
                        thisPointer.theme = data.theme.theme;
                        thisPointer.const_template = data.const_temp_pages;

                        if(data.theme.theme != 'all' && data.theme.theme == null){
                            $("#myModal").modal({
                                backdrop: 'static',
                                keyboard: false
                            });
                            thisPointer.$modal.show('SelectTheme');
                        }
                        thisPointer.rearrange_pages = data.rearrange_array;
                        if(thisPointer.pages.length > 0){
                            thisPointer.page_id = thisPointer.pages[0].id;
                            setTimeout(function(){
						        thisPointer.editor_data(thisPointer.page_id);
                            }, 1000);
                        }else{
                            thisPointer.isLoading = false;
                        }
                    }
                });
            },
            onBack() {
                this.$swal({
                        icon: 'info',
                        text: 'Are you sure you want to go back? All the pages created so far will be Deleted.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                        if(result.value) {
                            this.isLoading = true;
                            ApiService.get('on_back_delete_template/' + this.$store.state.template_id)
                            .then(response=>{
                                    if(response.status){
                                        this.$router.push({ path: '/proposal-step-4' });
                                        this.isLoading = false;
                                    }
                            })
                        }
                })
			},
        },
        components:{
        	Sidebar,
            Loading,
            draggable,
            nestedDraggable
        }
    }
</script>
<style scoped>
    .editor_div {
        display:none;

    }
   
</style>