<template>
	<main>
		<sidebar></sidebar>
		<!-- reports -->
		<div class="reports inner-page-pd">
			<h2 class="main-title mb-0">
				Reports of <span>{{ user_firstname }}!</span>
			</h2>
			<div class="d-flex align-items-center">
				<span
					>{{ from_date | formatDate }} to
					{{ to_date | formatDate }}</span
				>
				<a href="javascript:void(0)" @click="click_on_report" class="diff-date">Choose Different Dates</a>
			</div>
			<div class="row mt-4">
				<div class="col-md-8 pr-0">
					<!-- Proposal Statistics -->
					<div class="inner-page__content-box mb-4">
						<div class="top">
							<h3 class="main-subtitle">Proposal Statistics</h3>
							<p>
								A quick overview of how many and how fast your
								proposals are being signed. Run different
								searches and compare your conversion rate over
								different periods.
							</p>
						</div>
						<div class="statistics">
							<div class="statistics__content">
								<span>{{ proposal_sent_count }}</span>
								<label>proposals <br />Sent</label>
							</div>
							<div class="statistics__content">
								<span>{{ proposal_signed_count }}</span>
								<label>Proposals <br />SIGNED</label>
							</div>
							<div class="statistics__content">
								<span>{{ conversion_rate }}%</span>
								<label>Conversion <br />Rate</label>
							</div>
						</div>
					</div>
						<!-- One-off Sales -->
						<div class="inner-page__content-box mb-4">
							<div class="top">
								<h3 class="main-subtitle">One-off Sales</h3>
								<p>The totals of all your signed proposals. Run this report for different dates and compare your sales report and see if you need to improve.</p>
							</div>

                             <!-- No Data -->
                            <div class="inner-page__content-box" v-if="report_data.data.length == 0">
                                    <div class="top">
                                    </div>
                                    <div class="no-reports-data">
                                        <img src="@/assets/images/no-sales.svg" class="img-fluid">
                                        <h3>No Sales For This Period</h3>
                                    </div>
						    </div>
                            <!-- Data Present -->

							<div class="proposal-table" v-if="report_data.data.length > 0">
								<div class="table-responsive">
									<table class="table table-striped">
										<thead>
											<tr>
												<th>Name</th>
												<th>Total Sales</th>
												<th>Props Sent</th>
												<th>Props Signed</th>
												<th>Conversion Rate</th>
												<th>Revenue Generated</th>
											</tr>
										</thead>
										<tbody>
											 	<!-- <tr>
											<td colspan="6" class="text-center" v-if="report_data.data.length == 0"> Data Not Found</td>
										</tr> -->
										<tr
											v-for="company_report in report_data.data"
											:key="company_report.id"
										>
											<td>
												 <span class="d-flex">
													{{
														company_report
															.company_name
													}} 
													<span v-if="company_report.original_id !=null">( Version No {{ company_report.version_key }}) </span>
													<span v-if="company_report.child_count >0 && company_report.original_id ==null"> ( Version No 1) </span>
												</span>
											</td>
											<td>{{Math.abs(company_report.total_sales) > 999 ? Math.sign(company_report.total_sales)*((Math.abs(company_report.total_sales)/1000).toFixed(0)) + 'k' : Math.sign(company_report.total_sales)*Math.abs(company_report.total_sales)}}</td>

											<td>
												{{
													company_report.proposals_sent
												}}
											</td>
											<td>
												{{
													company_report.proposalssigned
												}}
											</td>
											<td>
												{{ company_report.proposals_sent != 0 ?
													(
														(company_report.proposalssigned /
															company_report.proposals_sent) *
														100
													).toFixed(0) : "0"
												}}%
											</td>
											<td>{{Math.abs(company_report.revenue) > 999 ? Math.sign(company_report.revenue)*((Math.abs(company_report.revenue)/1000).toFixed(0)) + 'k' : Math.sign(company_report.revenue)*Math.abs(company_report.revenue)}}</td>
											</tr>
										</tbody>
									</table>
								</div>
								 <div class="proposal-table__pagination" id="report_data" v-show="pagination">
                                    <nav>
                                        <ul class="pagination">
                                            <li class="page-item">
                                                <!-- <a class="page-link" href="#">Previous</a> -->
                                                    <a class="page-link" ref="#"  @click.prevent="getSaleOff(currentPage-1, from_date, to_date)"
                                                v-bind:style= "[ report_data.total<11  || report_data.current_page == 1 ? { 'pointer-events': 'none' } : null ]" >Previous</a>
                                            </li>
                                                <li
                                                class="page-item"
                                                v-for="item in recordLoop"
                                                :key="item" v-bind:class="{'page-item':true, 'active':(item === currentPage -1)}">
                                                <a class="page-link" href="#" @click.prevent="getSaleOff(item+1, from_date, to_date)">{{item+1}}</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#"  @click.prevent="getSaleOff(currentPage+1, from_date, to_date)"
                                                v-bind:style= "[ report_data.total<11 || report_data.last_page == report_data.current_page  ? { 'pointer-events': 'none' } : null ]"

                                                >Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
							</div>
						</div>
						<!-- Monthly Recurring Revenue -->
                        <div class="inner-page__content-box mb-4">
							<div class="top">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="main-subtitle">Monthly Revenue</h3>
                                    <div class="form-group select-box select-box--small mb-0">
                                       <select @change="onChange_monthly($event)"  v-model="current_month" class="form-control" >
                                           <option v-for="month in months" :value="month">{{ month }}</option>
                                        </select>
                                    </div>
                                </div>
								<p>The monthly totals of all your signed proposals.</p>
							</div>
                            <!-- No Data -->
                            <div class="inner-page__content-box" v-if="monthly_recurring_revenue.data.length == 0">
                                    <div class="top">
                                    </div>
                                    <div class="no-reports-data">
                                        <img src="@/assets/images/no-sales.svg" class="img-fluid">
                                        <h3>No Sales For This Period</h3>
                                    </div>
						    </div>
                            <!-- Data Present -->
							<div class="proposal-table" v-if="monthly_recurring_revenue.data.length > 0">
								<div class="table-responsive">
									<table class="table table-striped">
										<thead>
											<tr>
												<th>Name</th>
												<th>Total Sales</th>
												<th>Proposals Sent</th>
												<th>Proposals Signed</th>
												<th>Conversion Rate</th>
												<th>Revenue Generated</th>
											</tr>
										</thead>
										<tbody>
                                        <tr
                                            v-for="revenue in monthly_recurring_revenue.data"
                                            :key="
                                                revenue
                                                    .company_id
                                            "
                                        >
                                            <td>
												<span class="d-flex">
													{{
														revenue
															.company_name
													}} 
													<span v-if="revenue.original_id !=null">( Version No {{ revenue.version_key }}) </span>
													<span v-if="revenue.child_count >0 && revenue.original_id ==null"> ( Version No 1) </span>
												</span>
                                            </td>
                                             <td>{{Math.abs(revenue.total_sales) > 999 ? Math.sign(revenue.total_sales)*((Math.abs(revenue.total_sales)/1000).toFixed(0)) + 'k' : Math.sign(revenue.total_sales)*Math.abs(revenue.total_sales)}}</td>
                                            <td>
                                                {{ revenue.proposals_sent }}
                                            </td>
                                            <td>
                                                {{ revenue.proposalssigned }}
                                            </td>
                                            <td>
                                                {{ revenue.proposals_sent != 0 ?
													(
														(revenue.proposalssigned /
															revenue.proposals_sent) *
														100
													).toFixed(0) : "0"
												}}%
                                            </td>
                                            <td>{{Math.abs(revenue.revenue) > 999 ? Math.sign(revenue.revenue)*((Math.abs(revenue.revenue)/1000).toFixed(0)) + 'k' : Math.sign(revenue.revenue)*Math.abs(revenue.revenue)}}</td>
                                        </tr>
										</tbody>
									</table>
								</div>
								<div class="proposal-table__pagination" id="report_data" v-show="monthlyPagination">
									<nav>
										<ul class="pagination">
											<li class="page-item">
												<a class="page-link" ref="#"  @click.prevent="getMonthlyRev(monthlyCurrentPage-1)"
												v-bind:style= "[ monthly_recurring_revenue.total < 11 || monthly_recurring_revenue.current_page == 1  ? { 'pointer-events': 'none' } : null ]" >Previous</a>

											</li>
												<li
												class="page-item"
												v-for="item in monthlyRecordLoop"
												:key="item" v-bind:class="{'page-item':true, 'active':(item === monthlyCurrentPage -1)}">
												<a class="page-link" href="#" @click.prevent="getMonthlyRev(item+1)">{{item+1}}</a>
											</li>
											<li class="page-item">
												<a class="page-link" href="#"  @click.prevent="getMonthlyRev(monthlyCurrentPage+1)"
												v-bind:style= "[ monthly_recurring_revenue.total<11 || monthly_recurring_revenue.last_page == monthly_recurring_revenue.current_page ? { 'pointer-events': 'none' } : null ]"

												>Next</a>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
						<!-- Quarterly Recurring Revenue -->
						<div class="inner-page__content-box mb-4">
							<div class="top">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="main-subtitle">Quarterly Recurring Revenue</h3>
                                    <div class="form-group select-box select-box--small mb-0">
                                        <select @change="onChange($event)" v-model="selected_quater"  class="form-control" >
                                            <option value="1">Quarterly 1</option>
                                            <option value="2">Quarterly 2</option>
                                            <option value="3">Quarterly 3</option>
                                            <option value="4">Quarterly 4</option>
                                        </select>
                                    </div>
                                </div>
								<p>The quarterly totals of all your signed proposals.</p>
							</div>

                             <!-- No Data -->
                            <div class="inner-page__content-box" v-if="quarterly_recurring_revenue.data.length == 0">
                                    <div class="top">
                                    </div>
                                    <div class="no-reports-data">
                                        <img src="@/assets/images/no-sales.svg" class="img-fluid">
                                        <h3>No Sales For This Period</h3>
                                    </div>
						    </div>
                            <!-- Data Present -->
							<div class="proposal-table" v-if="quarterly_recurring_revenue.data.length > 0">
								<div class="table-responsive">
									<table class="table table-striped">
										<thead>
											<tr>
												<th>Name</th>
												<th>Total Sales</th>
												<th>Proposals Sent</th>
												<th>Proposals Signed</th>
												<th>Conversion Rate</th>
												<th>Revenue Generated</th>
											</tr>
										</thead>
										<tbody>
										 <tr>
											<td colspan="6" class="text-center" v-if="quarterly_recurring_revenue.data.length == 0"> No Data Found</td>
										</tr>
                                        <tr
                                            v-for="revenue in quarterly_recurring_revenue.data"
                                            :key="revenue "
                                        >
                                            <td>
												{{
														revenue
															.company_name
													}} 
													<span v-if="revenue.original_id !=null">( Version No {{ revenue.version_key }}) </span>
													<span v-if="revenue.child_count >0 && revenue.original_id ==null"> ( Version No 1) </span>
                                            </td>
                                             <td>{{Math.abs(revenue.total_sales) > 999 ? Math.sign(revenue.total_sales)*((Math.abs(revenue.total_sales)/1000).toFixed(0)) + 'k' : Math.sign(revenue.total_sales)*Math.abs(revenue.total_sales)}}</td>
                                            <td>
                                                {{ revenue.proposals_sent }}
                                            </td>
                                            <td>
                                                {{ revenue.proposalssigned }}
                                            </td>
                                            <td>
                                                {{ revenue.proposals_sent != 0 ?
													(
														(revenue.proposalssigned /
															revenue.proposals_sent) *
														100
													).toFixed(0) : "0"
												}}%
                                            </td>
                                            <td>{{Math.abs(revenue.revenue) > 999 ? Math.sign(revenue.revenue)*((Math.abs(revenue.revenue)/1000).toFixed(0)) + 'k' : Math.sign(revenue.revenue)*Math.abs(revenue.revenue)}}</td>
                                        </tr>
										</tbody>
									</table>
								</div>
								<div class="proposal-table__pagination" id="report_data" v-show="quaterlyPagination">
                                                <nav>
                                                    <ul class="pagination">
                                                        <li class="page-item">
                                                            <a class="page-link" ref="#"  @click.prevent="getQuaterlyRev(quaterlyCurrentPage-1)"
                                                            v-bind:style= "[ quarterly_recurring_revenue.total<11 || quarterly_recurring_revenue.current_page == 1 ? { 'pointer-events': 'none' } : null ]" >Previous</a>

                                                        </li>
                                                         <li
                                                            class="page-item"
                                                            v-for="item in quaterlyRecordLoop"
                                                            :key="item" v-bind:class="{'page-item':true, 'active':(item === quaterlyCurrentPage - 1)}">
                                                            <a class="page-link" href="#" @click.prevent="getQuaterlyRev(item+1)">{{item+1}}</a>
                                                        </li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"  @click.prevent="getQuaterlyRev(quaterlyCurrentPage+1)"
                                                            v-bind:style= "[ quarterly_recurring_revenue.total<11 || quarterly_recurring_revenue.last_page == quarterly_recurring_revenue.current_page  ? { 'pointer-events': 'none' } : null ]"

                                                            >Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
							</div>
						</div>
						<!-- Annual Recurring Revenue -->
						<div class="inner-page__content-box mb-4">
							<div class="top">
                                <div class="d-flex align-items-center justify-content-between">
								<h3 class="main-subtitle">Annual  Revenue</h3>
                                <div class="form-group select-box select-box--small mb-0">
                                        <select @change="onChange_annualy($event)"   class="form-control" >
                                           <option v-for="year in years" :value="year">{{ year }}</option>
                                        </select>
                                    </div>
                                </div>
								<p>The annual totals of all your signed proposals.</p>
							</div>

                             <!-- No Data -->
                            <div class="inner-page__content-box" v-if="annual_recurring_revenue.data.length == 0">
                                    <div class="top">
                                    </div>
                                    <div class="no-reports-data">
                                        <img src="@/assets/images/no-sales.svg" class="img-fluid">
                                        <h3>No Sales For This Period</h3>
                                    </div>
						    </div>
                            <!-- Data Present -->

							<div class="proposal-table" v-if="annual_recurring_revenue.data.length > 0">
								<div class="table-responsive">
									<table class="table table-striped">
										<thead>
											<tr>
												<th>Name</th>
												<th>Total Sales</th>
												<th>Proposals Sent</th>
												<th>Proposals Signed</th>
												<th>Conversion Rate</th>
												<th>Revenue Generated</th>
											</tr>
										</thead>
										<tbody>
										<tr
											v-for="revenue in annual_recurring_revenue.data"
											:key="
												revenue
													.company_id
											"
										>
											<td>
												<span class="d-flex">
													{{
														revenue
															.company_name
													}} 
													<span v-if="revenue.original_id !=null">( Version No {{ revenue.version_key }}) </span>
													<span v-if="revenue.child_count >0 && revenue.original_id ==null"> ( Version No 1) </span>
												</span>
											</td>
											 <td>{{Math.abs(revenue.total_sales) > 999 ? Math.sign(revenue.total_sales)*((Math.abs(revenue.total_sales)/1000).toFixed(0)) + 'k' : Math.sign(revenue.total_sales)*Math.abs(revenue.total_sales)}}</td>
											<td>
												{{ revenue.proposals_sent }}
											</td>
											<td>
												{{ revenue.proposalssigned }}
											</td>
											<td>
												{{ revenue.proposals_sent != 0 ?
													(
														(revenue.proposalssigned /
															revenue.proposals_sent) *
														100
													).toFixed(0) : "0"
												}}%
											</td>
											<td>{{Math.abs(revenue.revenue) > 999 ? Math.sign(revenue.revenue)*((Math.abs(revenue.revenue)/1000).toFixed(0)) + 'k' : Math.sign(revenue.revenue)*Math.abs(revenue.revenue)}}</td>
										</tr>
										</tbody>
									</table>
								</div>
								 <div class="proposal-table__pagination" id="report_data" v-show="annualPagination">
												<nav>
													<ul class="pagination">
														<li class="page-item">
															<a class="page-link" ref="#"  @click.prevent="getAnnualRev(annualCurrentPage-1)"
															v-bind:style= "[ annual_recurring_revenue.total < 11 || annual_recurring_revenue.current_page == 1 ? { 'pointer-events': 'none' } : null ]" >Previous</a>

														</li>
														 <li
															class="page-item"
															v-for="item in annualRecordLoop"
															:key="item" v-bind:class="{'page-item':true, 'active':(item === annualCurrentPage - 1)}">
															<a class="page-link" href="#" @click.prevent="getAnnualRev(item+1)">{{item+1}}</a>
														</li>
														<li class="page-item">
															<a class="page-link" href="#"  @click.prevent="getAnnualRev(annualCurrentPage+1)"
															v-bind:style= "[ annual_recurring_revenue.total<11 || annual_recurring_revenue.last_page == annual_recurring_revenue.current_page ? { 'pointer-events': 'none' } : null ]"

															>Next</a>
														</li>
													</ul>
												</nav>
											</div>
							</div>
						</div>

						<!-- Individual Performance -->
						<div class="inner-page__content-box mb-4">
							<div class="top">
                                <div class="d-flex align-items-center justify-content-between">
								<h3 class="main-subtitle">Individual Performance</h3>
                                <div class="form-group select-box select-box--small mb-0">
                                        <select @change="onChange_individualy($event)"   class="form-control" >
                                           <option v-for="year in years" :value="year">{{ year }}</option>
                                        </select>
                                    </div>
                                </div>
								<p>A quick summary of how the different members of your team are getting on. See who's slacking and who needs praise.</p>
							</div>

                             <!-- No Data -->
                            <div class="inner-page__content-box" v-if="individual_performance_revenue.data.length == 0">
                                    <div class="top">
                                    </div>
                                    <div class="no-reports-data">
                                        <img src="@/assets/images/no-sales.svg" class="img-fluid">
                                        <h3>No Sales For This Period</h3>
                                    </div>
						    </div>
                            <!-- Data Present -->

							<div class="proposal-table" v-if="individual_performance_revenue.data.length > 0">
								<div class="table-responsive">
									<table class="table table-striped">
										<thead>
											<tr>
												<th>Name</th>
												<th>Total Sales</th>
												<th>Proposals Sent</th>
												<th>Proposals Signed</th>
												<th>Conversion Rate</th>
												<th>Revenue Generated</th>
											</tr>
										</thead>
										<tbody>
										<tr
											v-for="revenue in individual_performance_revenue.data"
											:key="
												revenue
													.user_id
											"
										>
											<td>
												{{
													revenue
														.user_firstname
												}}
											</td>
											 <td>{{Math.abs(revenue.total_sales) > 999 ? Math.sign(revenue.total_sales)*((Math.abs(revenue.total_sales)/1000).toFixed(0)) + 'k' : Math.sign(revenue.total_sales)*Math.abs(revenue.total_sales)}}</td>
											<td>
												{{ revenue.proposals_sent }}
											</td>
											<td>
												{{ revenue.proposalssigned }}
											</td>
											<td>
												{{ revenue.proposals_sent != 0 ?
													(
														(revenue.proposalssigned /
															revenue.proposals_sent) *
														100
													).toFixed(0) : "0"
												}}%
											</td>
											<td>{{Math.abs(revenue.revenue) > 999 ? Math.sign(revenue.revenue)*((Math.abs(revenue.revenue)/1000).toFixed(0)) + 'k' : Math.sign(revenue.revenue)*Math.abs(revenue.revenue)}}</td>
										</tr>
										</tbody>
									</table>
								</div>
								<div class="proposal-table__pagination" id="report_data" v-show="individualPagination">
												<nav>
													<ul class="pagination">
														<li class="page-item">
															<a class="page-link" ref="#"  @click.prevent="getIndividualRev(individualCurrentPage-1)"
															v-bind:style= "[individual_performance_revenue.total < 11 ||individual_performance_revenue.current_page == 1 ? { 'pointer-events': 'none' } : null ]" >Previous</a>
														</li>
														 <li
															class="page-item"
															v-for="item in annualRecordLoop"
															:key="item" v-bind:class="{'page-item':true, 'active':(item === individualCurrentPage - 1)}">
															<a class="page-link" href="#" @click.prevent="getIndividualRev(item+1)">{{item+1}}</a>
														</li>
														<li class="page-item">
															<a class="page-link" href="#"  @click.prevent="getIndividualRev(individualCurrentPage+1)"
															v-bind:style= "[individual_performance_revenue.total<11 || individual_performance_revenue.last_page == individual_performance_revenue.current_page ? { 'pointer-events': 'none' } : null ]"
															>Next</a>
														</li>
													</ul>
												</nav>
											</div>
							</div>
						</div>

					</div>
					<div class="col-md-3 offset-md-1">
						<div class="follow-steps__wrap position-fixed">
							<div class="follow-steps">
								<p>Here you see your reports. You can view your report for specific date. Simply select date range you want to see.</p>
								<p>If you have any query. please contact admin.</p>
							</div>
							<div class="img-box">
							 <img
								src="@/assets/images/reports-right.svg"
								class="img-fluid"
							/>
							</div>
						</div>
					</div>
				</div>
			</div>
	</main>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
	import ApiService from '@/services/Api';
 import {mapState} from 'vuex';
export default {
	name: "ReportView",
	data() {
		return {
			report_data: [],
			user_firstname: this.$store.state.user_firstname,
			proposal_sent_count: 0,
			proposal_signed_count: 0,
			conversion_rate: 0,
			report_data: this.$store.state.company_report,
			monthly_recurring_revenue: this.$store.state.monthly_recurring_revenue,
			annual_recurring_revenue: this.$store.state.annual_recurring_revenue,
			quarterly_recurring_revenue: this.$store.state.quarterly_recurring_revenue,
			individual_performance_revenue: this.$store.state.individual_performance_revenue,
			pagination: false,
			monthlyPagination: false,
			annualPagination: false,
			quaterlyPagination: false,
			individualPagination: false,
			currentPage:1,
			monthlyCurrentPage:1,
			annualCurrentPage:1,
			quaterlyCurrentPage:1,
			individualCurrentPage:1,
			selected_quater:1,
            current_month:this.$store.state.current_month,
		};
	},
	computed:{
		recordLoop(){
			return  [...Array(Math.ceil(this.$store.state.company_report.total/10))].map((_, i) => i * 1);
		},
		monthlyRecordLoop(){
			return  [...Array(Math.ceil(this.$store.state.monthly_recurring_revenue.total/10))].map((_, i) => i * 1);
		},
		annualRecordLoop(){
			return  [...Array(Math.ceil(this.$store.state.annual_recurring_revenue.total/10))].map((_, i) => i * 1);
		},
		quaterlyRecordLoop(){
            return  [...Array(Math.ceil(this.$store.state.quarterly_recurring_revenue.total/10))].map((_, i) => i * 1);
        },
		individualRecordLoop(){
			return  [...Array(Math.ceil(this.$store.state.individual_performance_revenue.total/10))].map((_, i) => i * 1);
		},
		from_date(){
			return this.$store.state.from_date;
		},
		to_date(){
			return this.$store.state.to_date;
		},
		months(){
			return this.$store.state.months;
		},
        years(){
			return this.$store.state.years;
		},

	},
	methods: {
		kFormatter(num) {
			return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(0)) + 'k' : Math.sign(num)*Math.abs(num)
		},
        onChange_monthly(event){
            let thisPointer = this;
            this.isLoading = true;
            this.selected = event.target.value;
            ApiService.get('get_monthly/' + this.selected)
            .then(response=>{
                    if(response.data.status){
                        thisPointer.monthly_recurring_revenue = response.data.monthly_recurring_revenue;
                        this.isLoading = false;
						// response.data.monthly_recurring_revenue.total = 11;
						 if(response.data.monthly_recurring_revenue.total < 10 ){
							thisPointer.monthlyPagination = false;
						}
                    }
            })
        },
        onChange_annualy(event){
            let thisPointer = this;
            this.isLoading = true;
            this.annualSelected = event.target.value;
            ApiService.get('get_annualy/' + this.annualSelected)
            .then(response=>{
                    if(response.data.status){
                        thisPointer.annual_recurring_revenue = response.data.annual_recurring_revenue;
                        this.isLoading = false;
						 if( response.data.annual_recurring_revenue.total > 10 ){
                   			 thisPointer.annualPagination = false;
                		}
                    }
            })
        },
        onChange_individualy(event){
            let thisPointer = this;
            this.isLoading = true;
            this.individualSelected = event.target.value;
            ApiService.get('get_individualy/' + this.individualSelected)
            .then(response=>{
                    if(response.data.status){
                        thisPointer.individual_performance_revenue = response.data.individual_performance_revenue;
                        this.isLoading = false;

						if(response.data.individual_performance_revenue.total > 10 ){
							thisPointer.individualPagination = false;
						}
                    }
            })
        },
        onChange(event){
            let thisPointer = this;
            this.isLoading = true;
            this.quatSelected = event.target.value;
            ApiService.get('get_quaterly/' + this.selected_quater)
            .then(response=>{
                    if(response.data.status){
                        thisPointer.quarterly_recurring_revenue = response.data.quarterly_recurring_revenue;
                        this.isLoading = false;
						if(response.data.quarterly_recurring_revenue.total > 10  ){
							thisPointer.quaterlyPagination = false;
						}
                    }
            })
        },
		getSaleOff(page,from_date,to_date ){
			let thisPointer = this;
			thisPointer.isLoading = true;
			var generateReportDetails = {'page':page,'from_date':from_date,'to_date':to_date};
					thisPointer.$store.dispatch("get_sale_off",generateReportDetails)
					.then(response => {
				if (response.status == "success") {
					thisPointer.report_data = thisPointer.$store.state.company_report;
					thisPointer.currentPage =  page;
					thisPointer.isLoading = false;
				}
			});

		},
		getMonthlyRev(page){
			let thisPointer = this;
			thisPointer.isLoading = true;
			let selected = this.selected;
			var generateReportDetails = {'page':page,'selected':selected,'from_date':thisPointer.from_date,'to_date':thisPointer.to_date};
					thisPointer.$store.dispatch("get_monthly_rev",generateReportDetails)
					.then(response => {
				if (response.status == "success") {
					thisPointer.monthly_recurring_revenue = thisPointer.$store.state.monthly_recurring_revenue;
					thisPointer.monthlyCurrentPage =  page;
					thisPointer.isLoading = false;
				}
			});

		},
		getQuaterlyRev(page){
            let thisPointer = this;
            thisPointer.isLoading = true;
             let selected = this.quatSelected;
			var generateReportDetails = {'page':page,'selected':selected,'from_date':thisPointer.from_date,'to_date':thisPointer.to_date};
                    thisPointer.$store.dispatch("get_quaterly_rev",generateReportDetails)
                    .then(response => {
                if (response.status == "success") {
                    thisPointer.quarterly_recurring_revenue = thisPointer.$store.state.quarterly_recurring_revenue;
                    thisPointer.quaterlyCurrentPage =  page;
                    thisPointer.isLoading = false;
                }
            });
        },
		getAnnualRev(page){
            this.isLoading = true;
            let thisPointer = this;
            let selected = this.annualSelected;
			var generateReportDetails = {'page':page,'from_date':thisPointer.from_date,'to_date':thisPointer.to_date};
					thisPointer.$store.dispatch("get_annual_rev",generateReportDetails)
					.then(response => {
				if (response.status == "success") {
					thisPointer.annual_recurring_revenue = thisPointer.$store.state.annual_recurring_revenue;
					thisPointer.annualCurrentPage =  page;
					thisPointer.isLoading = false;
				}
			});

		},
		getIndividualRev(page){
            this.isLoading = true;
            let thisPointer = this;
            let selected = this.individualSelected;
			var generateReportDetails = {'page':page,'selected':selected,'from_date':thisPointer.from_date,'to_date':thisPointer.to_date};
                    thisPointer.$store.dispatch("get_individual_rev",generateReportDetails)
                    .then(response => {
                if (response.status == "success") {
                    thisPointer.individual_performance_revenue = thisPointer.$store.state.individual_performance_revenue;
                    thisPointer.individualCurrentPage =  page;
                    thisPointer.isLoading = false;
                }
            });

		},
		proposal_signed_count_total() {
			var total = 0;
			var total_count = 0;
			let thisPointer = this;
			for (let i = 0; i < thisPointer.$store.state.company_report.data.length; i++) {
				total =
					total +
					parseInt(
						thisPointer.$store.state.company_report.data[i].proposalssigned
					);
			}
			thisPointer.proposal_signed_count = total;
		},

        proposal_sent_count_total() {
            var total_signed = 0;
            var total_count = 0;
            let thisPointer = this;

            for (let i = 0; i < thisPointer.$store.state.company_report.data.length; i++) {
                total_signed =
                    total_signed +
                    parseInt(
                        thisPointer.$store.state.company_report.data[i].proposals_sent
                    );
                total_count =
                    total_count +
                    parseInt(
                        thisPointer.$store.state.company_report.data[i]
                            .proposalssigned_count
                    );
            }
            thisPointer.proposal_sent_count = total_signed;
            if(thisPointer.proposal_signed_count !=0 && thisPointer.proposal_sent_count !=0){
            thisPointer.conversion_rate = ((thisPointer.proposal_signed_count / thisPointer.proposal_sent_count) * 100).toFixed(0);
            }
            else{
              thisPointer.conversion_rate = 0;
            }
        },
		click_on_report(){
                    this.$router.push({ path: '/report' });
        },
        setPagination(){
            let thisPointer = this;
                if(thisPointer.$store.state.company_report.total > 10  ){
                    thisPointer.pagination = true;
                }
                if(thisPointer.$store.state.monthly_recurring_revenue.total > 10 ){
                    thisPointer.monthlyPagination = true;
                }
                if(thisPointer.$store.state.annual_recurring_revenue.total > 10 ){
                    thisPointer.annualPagination = true;
                }
                if(thisPointer.$store.state.quarterly_recurring_revenue.total > 10  ){
                    thisPointer.quaterlyPagination = true;
                }

				if(thisPointer.$store.state.individual_performance_revenue.total > 10 ){
                    thisPointer.individualPagination = true;
                }



        }
    },

	mounted() {
        this.proposal_signed_count_total();
        this.proposal_sent_count_total();
        this.setPagination();


    },
    components: {
        Sidebar
    }
};
</script>
