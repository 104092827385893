<template>
	<!-- sidebar -->
	<div class="sidebar">
		<div class="sidebar_top">
			<div class="logo-section d-flex align-items-center justify-content-between">
				<img src="@/assets/images/ProposalLogo.svg" alt="logo" class="img-fluid">
				<div class="hamburger" @click="tooglesidebar()">
					<span class="line"></span>
					<span class="line"></span>
					<span class="line"></span>
				</div>
			</div>
			<div class="sidebar-menu">
				<nav class="navbar navbar-light bg-white p-0">
					<ul class="navbar-nav">
						<li :class="$route.name == 'Home' ? 'nav-item active' : 'nav-item'">
							<a class="nav-link" href="javascript:void(0)" @click="click_on_home"><img src="@/assets/images/icons/dashboard.svg" alt="" class="img-fluid"> Dashboard</a>
						</li>
						<li :class="($route.name == 'Proposal' || $route.name == 'ProposalDetail' || $route.name == 'Step1' || $route.name == 'Step2' || $route.name == 'Step3' || $route.name == 'Step4' || $route.name == 'Step5' || $route.name == 'Step6') ? 'nav-item active' : 'nav-item'">
							<a class="nav-link" href="javascript:void(0)" @click="click_on_proposal"><img src="@/assets/images/icons/proposal.svg" alt="" class="img-fluid"> Proposals</a>
						</li>
						<li :class="$route.name == 'Template' ? 'nav-item active' : 'nav-item'">
							<a class="nav-link" href="javascript:void(0)" @click="click_on_template"><img src="@/assets/images/icons/templates.svg" alt="" class="img-fluid"> Templates</a>
						</li>
						<li :class="($route.name == 'Report' || $route.name == 'ReportView') ? 'nav-item active' : 'nav-item'">
							<a class="nav-link" href="javascript:void(0)" @click="click_on_report"><img src="@/assets/images/icons/reports.svg" alt="" class="img-fluid"> Reports</a>
						</li>
						<li :class="$route.name == 'Company' ? 'nav-item active' : 'nav-item'">
							<a class="nav-link"  href="javascript:void(0)" @click="click_on_company"><img src="@/assets/images/icons/company.svg" alt="" class="img-fluid"> Company</a>
						</li>
						<li :class="$route.name == 'Profile' ? 'nav-item active' : 'nav-item'">
							<a class="nav-link" href="javascript:void(0)" @click="click_on_profile"><img src="@/assets/images/icons/profile.svg" alt="" class="img-fluid"> My Profile</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
		<div class="sidebar_bottom">
			<div class="user-details d-flex align-items-center">
				<div class="user-content mr-3">
					<div class="user-content__inner">
						<h5 class="mb-1">{{ $store.state.user_firstname }} {{ $store.state.user_lastname }} </h5>
						 <span>{{$store.state.department}}</span>
					</div>
				</div>
				<div class="logout">
					<a href="javascript:void(0);" @click="logout" class="logout"><img src="@/assets/images/icons/logout.svg" alt="" class="img-fluid"></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
        name: "Sidebar",
        data () {
            return {

            }
				},
				mounted() {

				},
        methods: {
            logout:function(){
            let thisPointer =   this;
            thisPointer.$store.dispatch('logout').then(function(){
                thisPointer.$router.go('/login');
            });
            },
        	tooglesidebar(){
        		$("main").toggleClass("toggle-left");
		        $(".sidebar").toggleClass("active");

		        if($("main").hasClass("toggle-left"))
		        {
		            $(".create-proposal__form-actions.center").addClass("center-left");
		        }
		        else {
		            $(".create-proposal__form-actions.center").removeClass("center-left");
		        }
				},
                click_on_proposal(){
                    this.$store.commit('toStartNewProposal');
                    this.$router.push({ path: '/proposal' });
                },
				 click_on_template(){
                    // this.$store.commit('toStartNewProposal');
                    this.$router.push({ path: '/template' });
                },
				 click_on_report(){
                    // this.$store.commit('toStartNewProposal');
                    this.$router.push({ path: '/report' });
                },
				 click_on_company(){
                    // this.$store.commit('toStartNewProposal');
                    this.$router.push({ path: '/company' });
                },
				 click_on_profile(){
                    // this.$store.commit('toStartNewProposal');
                    this.$router.push({ path: '/profile' });
                },
				click_on_home(){
					this.$router.push({ path: '/home' });
				}
        }
    }
</script>
